// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import {
    FormField,
    Grid,
    Input,
    Select,
    SpaceBetween,
    Toggle,
} from '@cloudscape-design/components';
import React from 'react';

import { DestinationForm } from '../../AddEvent';

import { sanitizeInput } from 'utils/common';
import { GRID_DEFINTION_COLSPAN_6 } from 'utils/constants';
import { checkBackupEnabled, setErrorText } from '../../helperFunctions';
import { PassphraseForm } from '../PassphraseForm';

export const DestinationOutputSRTAdditionalFields: React.FC<DestinationForm> = ({
    didSubmit,
    localForm,
    setLocalForm,
}) => {
    return (
        <>
            <SpaceBetween direction="vertical" size="m">
                <FormField
                    description="SRT Mode"
                    errorText={setErrorText(didSubmit, localForm, {
                        attribute: 'destinations',
                        attribute2: 'OutputSRT',
                        attribute3: 'srtMode',
                    })}>
                    <Select
                        selectedOption={{
                            value: localForm.destinations.OutputSRT.srtMode,
                        }}
                        options={[{ value: 'Caller' }, { value: 'Listener' }]}
                        onChange={({ detail }) => {
                            setLocalForm({
                                ...localForm,
                                ['destinations']: {
                                    ...localForm.destinations,
                                    OutputSRT: {
                                        ...localForm.destinations['OutputSRT'],
                                        ['srtMode']: detail.selectedOption.value,
                                    },
                                },
                            });
                        }}
                    />
                </FormField>

                <Grid gridDefinition={GRID_DEFINTION_COLSPAN_6}>
                    {localForm.destinations['OutputSRT'].srtMode === 'Listener' ? (
                        <FormField
                            key={'primaryAllowlist'}
                            description={'Primary Allowlist CIDR'}
                            errorText={setErrorText(didSubmit, localForm, {
                                attribute: 'destinations',
                                attribute2: 'OutputSRT',
                                attribute3: 'primaryAllowlist',
                            })}>
                            <Input
                                placeholder="Ex: 0.0.0.0/0"
                                onChange={({ detail }) => {
                                    const newestChange = {
                                        ...localForm,
                                        ['destinations']: {
                                            ...localForm.destinations,
                                            ['OutputSRT']: {
                                                ...localForm.destinations['OutputSRT'],
                                                ['primaryAllowlist']: sanitizeInput(detail.value),
                                            },
                                        },
                                    };
                                    setLocalForm(newestChange);
                                }}
                                value={localForm.destinations['OutputSRT']['primaryAllowlist']}
                            />
                        </FormField>
                    ) : localForm.destinations.OutputSRT.srtMode === 'Caller' ? (
                        <FormField
                            key={'primaryAddress'}
                            description={'Primary SRT Address'}
                            errorText={setErrorText(didSubmit, localForm, {
                                attribute: 'destinations',
                                attribute2: 'OutputSRT',
                                attribute3: 'primaryAddress',
                            })}>
                            <Input
                                onChange={({ detail }) => {
                                    const newestChange = {
                                        ...localForm,
                                        ['destinations']: {
                                            ...localForm.destinations,
                                            ['OutputSRT']: {
                                                ...localForm.destinations['OutputSRT'],
                                                ['primaryAddress']: sanitizeInput(detail.value),
                                            },
                                        },
                                    };
                                    setLocalForm(newestChange);
                                }}
                                value={localForm.destinations['OutputSRT']['primaryAddress']}
                            />
                        </FormField>
                    ) : (
                        <></>
                    )}
                    {localForm.destinations.OutputSRT.srtMode && (
                        <FormField
                            description="Primary Port"
                            errorText={setErrorText(didSubmit, localForm, {
                                attribute: 'destinations',
                                attribute2: 'OutputSRT',
                                attribute3: 'primaryPort',
                            })}>
                            <Input
                                type="number"
                                onChange={({ detail }) => {
                                    setLocalForm({
                                        ...localForm,
                                        ['destinations']: {
                                            ...localForm.destinations,
                                            ['OutputSRT']: {
                                                ...localForm.destinations['OutputSRT'],
                                                ['primaryPort']: sanitizeInput(detail.value),
                                            },
                                        },
                                    });
                                }}
                                value={
                                    (localForm.destinations.OutputSRT.primaryPort &&
                                        localForm.destinations.OutputSRT.primaryPort.toString()) ||
                                    ''
                                }
                            />
                        </FormField>
                    )}
                </Grid>
                <FormField
                    key={'passphrase'}
                    description={
                        'Passphrase (Optional and interchangeable. Leave the field empty for no encryption.)'
                    }>
                    <PassphraseForm
                        localForm={localForm}
                        setLocalForm={setLocalForm}
                        localPassphrasePath={localForm.destinations['OutputSRT']['passphrase']}
                        passphraseType={'OutputSRT'}
                        shouldGenerateRandom={false}
                    />
                </FormField>
                <FormField
                    description="Latency (ms)"
                    errorText={setErrorText(didSubmit, localForm, {
                        attribute: 'destinations',
                        attribute2: 'OutputSRT',
                        attribute3: 'latency',
                    })}>
                    <Input
                        type="number"
                        onChange={({ detail }) => {
                            setLocalForm({
                                ...localForm,
                                ['destinations']: {
                                    ...localForm.destinations,
                                    ['OutputSRT']: {
                                        ...localForm.destinations['OutputSRT'],
                                        ['latency']: sanitizeInput(detail.value),
                                    },
                                },
                            });
                        }}
                        value={
                            (localForm.destinations.OutputSRT.latency &&
                                localForm.destinations.OutputSRT.latency.toString()) ||
                            ''
                        }
                    />
                </FormField>

                {checkBackupEnabled(localForm) && (
                    <>
                        <Grid gridDefinition={GRID_DEFINTION_COLSPAN_6}>
                            {localForm.destinations.OutputSRT.srtMode === 'Listener' ? (
                                <FormField
                                    description="Backup Allowlist CIDR Block"
                                    errorText={setErrorText(didSubmit, localForm, {
                                        attribute: 'destinations',
                                        attribute2: 'OutputSRT',
                                        attribute3: 'backupAllowList',
                                    })}>
                                    <Input
                                        onChange={({ detail }) => {
                                            setLocalForm({
                                                ...localForm,
                                                ['destinations']: {
                                                    ...localForm.destinations,
                                                    ['OutputSRT']: {
                                                        ...localForm.destinations['OutputSRT'],
                                                        ['backupAllowList']: detail.value,
                                                    },
                                                },
                                            });
                                        }}
                                        value={
                                            localForm.destinations.OutputSRT.backupAllowList || ''
                                        }
                                        placeholder="Ex: 0.0.0.0/0"
                                    />
                                </FormField>
                            ) : (
                                <FormField
                                    description="Backup Source IP"
                                    errorText={setErrorText(didSubmit, localForm, {
                                        attribute: 'destinations',
                                        attribute2: 'OutputSRT',
                                        attribute3: 'backupIp',
                                    })}>
                                    <Input
                                        onChange={({ detail }) => {
                                            setLocalForm({
                                                ...localForm,
                                                ['destinations']: {
                                                    ...localForm.destinations,
                                                    ['OutputSRT']: {
                                                        ...localForm.destinations['OutputSRT'],
                                                        ['backupIp']: detail.value,
                                                    },
                                                },
                                            });
                                        }}
                                        value={localForm.destinations.OutputSRT.backupIp || ''}
                                    />
                                </FormField>
                            )}

                            <FormField
                                description="Backup Port"
                                errorText={setErrorText(didSubmit, localForm, {
                                    attribute: 'destinations',
                                    attribute2: 'OutputSRT',
                                    attribute3: 'backupPort',
                                })}>
                                <Input
                                    type="number"
                                    onChange={({ detail }) => {
                                        setLocalForm({
                                            ...localForm,
                                            ['destinations']: {
                                                ...localForm.destinations,
                                                ['OutputSRT']: {
                                                    ...localForm.destinations['OutputSRT'],
                                                    ['backupPort']: detail.value,
                                                },
                                            },
                                        });
                                    }}
                                    value={
                                        (localForm.destinations.OutputSRT.backupPort &&
                                            localForm.destinations.OutputSRT.backupPort) ||
                                        ''
                                    }
                                />
                            </FormField>
                        </Grid>
                        <FormField description="Optional Backup Passphrase, Leave empty for no encryption.">
                            <PassphraseForm
                                localForm={localForm}
                                setLocalForm={setLocalForm}
                                localPassphrasePath={
                                    localForm.destinations.OutputSRT.backupPassphrase
                                }
                                shouldGenerateRandom={false}
                                passphraseType={'BackupOutputSRT'}
                            />
                        </FormField>
                    </>
                )}
            </SpaceBetween>
        </>
    );
};
