// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { SBSMedia } from '_types';
import { API } from 'aws-amplify';

export async function getEncoderMetric(param: SBSMedia.EncoderMetricProps) {
    const dimensionKeys = Object.keys(param.dimensions);

    const path = 'metrics/get-encoder-metric';

    const config = {
        headers: {},
        body: {
            'EncoderId': param.EncoderId, // might need to change
            'MetricName': param.metricName,
            'Dimensions': dimensionKeys.map((attribute) => {
                return { 'Name': attribute, 'Value': param.dimensions[attribute] };
            }),
            'Period': 3600, // param.period
        },
        response: false,
    };

    return API.post('SBS', path, config)
        .then((res) => {
            console.log('metrics response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function getEventMetric(param: SBSMedia.EventMetricProps) {
    const dimensionKeys = Object.keys(param.dimensions);

    const path = 'metrics/get-channel-metric';

    const config = {
        headers: {},
        body: {
            'eventid': param.eventid, // might need to change
            'MetricName': param.metricName,
            'Dimensions': dimensionKeys.map((attribute) => {
                return { 'Name': attribute, 'Value': param.dimensions[attribute] };
            }),
            'Period': 3600, // param.period
        },
        response: false,
    };

    return API.post('SBS', path, config)
        .then((res) => {
            console.log('metrics response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}
