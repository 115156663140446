// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
    Autosuggest,
    AutosuggestProps,
    Button,
    Form,
    FormField,
    Modal,
    SpaceBetween,
} from '@cloudscape-design/components';
import { SBSMedia } from '_types';
import { useAppDispatch } from 'app/hooks';
import {
    getEncoders,
    submitEncoder,
    updateEncoderAsync,
} from 'features/EncoderManagement/EncoderManagementSlice';

import { REGIONS } from 'utils/constants';

import EncoderAttribute from './EncoderAttribute';
import ElementalLiveFields from './ElementalLiveFields';

interface EditEncoderProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    encoderInfo: SBSMedia.EncoderProps;
    isEdit?: boolean;
}
const EditEncoder: React.FC<EditEncoderProps> = ({ visible, setVisible, encoderInfo, isEdit }) => {
    const [localForm, setLocalForm] = useState<SBSMedia.EncoderProps>({} as SBSMedia.EncoderProps);
    const [didSubmit, setSubmit] = useState(false);
    const dispatch = useAppDispatch();
    useEffect(() => {
        console.log(encoderInfo);
        setLocalForm({} as SBSMedia.EncoderProps);
    }, [encoderInfo]);

    function checkFormValid(localForm) {
        return (
            localForm.name && localForm.region && localForm.ip && localForm.user && localForm.apikey
        );
    }

    function setErrorText(attribute) {
        return didSubmit && !localForm[attribute] ? 'This field is required' : '';
    }

    const encoderPropPack = {
        encoderInfo: encoderInfo,
        localForm: localForm,
        setLocalForm: setLocalForm,
    };

    function resetForm() {
        setVisible(false);
        setSubmit(false);
        setLocalForm({} as SBSMedia.EncoderProps);
    }

    return (
        <Modal
            onDismiss={(e) => {
                if (e.detail.reason !== 'overlay') {
                    resetForm();
                }
            }}
            visible={visible}
            closeAriaLabel="Close modal"
            header={isEdit ? 'Edit Encoder' : 'Add Elemental Live Encoder'}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link" onClick={() => resetForm()}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                isEdit
                                    ? dispatch(
                                          updateEncoderAsync({
                                              ...localForm,
                                              EncoderId: encoderInfo.EncoderId,
                                          })
                                      )
                                          .then(() => {
                                              dispatch(getEncoders());
                                          })
                                          .finally(() => {
                                              setVisible(false);
                                          })
                                    : checkFormValid(localForm)
                                    ? dispatch(
                                          submitEncoder({
                                              ...localForm,
                                          })
                                      )
                                          .then(() => {
                                              dispatch(getEncoders());
                                          })
                                          .finally(() => setVisible(false))
                                    : setSubmit(true);
                            }}>
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                    </SpaceBetween>
                }>
                <SpaceBetween direction="vertical" size="l">
                    <EncoderAttribute
                        errorText={setErrorText('name')}
                        {...encoderPropPack}
                        label={'Encoder Name'}
                        description={'Required'}
                        attributeName={'name'}
                    />

                    <FormField
                        label="Encoder Region"
                        description="Required"
                        errorText={setErrorText('region')}>
                        <Autosuggest
                            disabled={
                                encoderInfo?.['enc_type'] === 'uhd' ||
                                encoderInfo?.['enc_type'] === 'hd'
                            }
                            value={
                                (localForm.region == undefined
                                    ? encoderInfo.region
                                    : localForm.region) || ''
                            }
                            onChange={({ detail }) => {
                                setLocalForm({ ...localForm, ['region']: detail.value });
                            }}
                            options={
                                REGIONS.map((region) => {
                                    return { value: region };
                                }) as AutosuggestProps.Options
                            }
                            enteredTextLabel={(value) => `Use ${value}`}
                            ariaLabel="Autosuggest example with suggestions"
                            empty="No matches found"
                        />
                    </FormField>
                    {encoderInfo?.['enc_type'] !== 'uhd' && encoderInfo?.['enc_type'] !== 'hd' ? (
                        <ElementalLiveFields
                            encoderInfo={encoderInfo}
                            localForm={localForm}
                            setLocalForm={setLocalForm}
                            didSubmit={false}
                        />
                    ) : null}
                </SpaceBetween>
            </Form>
        </Modal>
    );
};

export default EditEncoder;
