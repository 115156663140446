// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { SBSMedia } from '_types';

import AddEventModule from 'components/Events/AddEvent/AddEvent';
import EventDashboard from 'components/Events/EventDashboard/EventDashboard';
import ScheduleModal from 'components/Events/ScheduleModal/ScheduleModal';
import React, {  useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import {IconButton, Alert as MuiAlert} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { clearSlateNotification, getEventsAsync, selectAlerts } from 'features/EventManagement/EventManagementSlice';
import { Alert, Button, Icon } from '@cloudscape-design/components';

const Events: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const [scheduleVisible, setScheduleVisible] = useState(false);
    const [currentDetails, setCurrentDetails] = useState({});
    const [alertText, setAlertText] = useState<string>(null);
    const actionAlerts = useAppSelector(selectAlerts);

    
    const dispatch = useAppDispatch()
    const handleClose = (event, reason, id) => {
        if (reason === 'clickaway') {
            return;
        } else if (reason === 'timeout' || reason === 'click') {
            return dispatch(clearSlateNotification(id))
            .then(()=>dispatch(getEventsAsync()))
        }
    }


    return (
        <>
            <Snackbar
                open={alertText !== null}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={() => {
                    setAlertText(null);
                }}>
                <div>
                    <Alert
                        statusIconAriaLabel="Info"
                        type={
                            alertText === 'Something went wrong saving your event.'
                                ? 'error'
                                : 'success'
                        }
                        header={
                            alertText === 'Something went wrong saving your event.'
                                ? 'Error saving event'
                                : 'Successfully saved event'
                        }>
                        {alertText}
                    </Alert>
                </div>
            </Snackbar>
           
            {actionAlerts.map((alert)=>{
            return  <Snackbar
            anchorOrigin={{ vertical:'top', horizontal: 'center' }}
            open={true}
            autoHideDuration={6000}
            onClose={(event, reason)=>handleClose(event, reason, alert.id)}
            key={alert.id}
            >
                <MuiAlert severity={"warning"} variant='standard' onClose={(event)=>handleClose(event,'click', alert.id)}>{alert.content}</MuiAlert>
            </Snackbar>
            })}
                 
            <br />
            <EventDashboard
                setModalVisible={setVisible}
                setCurrentDetails={setCurrentDetails}
                setScheduleModalVisible={setScheduleVisible}
            />
            <AddEventModule
                setVisible={setVisible}
                visible={visible}
                currentDetails={currentDetails as SBSMedia.Event}
                setAlertText={setAlertText}
            />
            {/* <ScheduleModal
                setVisible={setScheduleVisible}
                visible={scheduleVisible}
                currentDetails={currentDetails as SBSMedia.Event}
            /> */}
        </>
    );
};

export default Events;
