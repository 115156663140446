// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { API } from 'aws-amplify';
import { SBSMedia } from '_types';

export async function fetchEncoderData() {
    const path = 'encoders';
    const config = {
        headers: {},
        response: false,
    };

    return API.get('SBS', path, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function fetchProfileData() {
    const path = 'profiles';
    const config = {
        headers: {},
        response: false,
    };

    return API.get('SBS', path, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function updateEncoder(localForm: SBSMedia.EncoderProps) {
    const path = 'encoders/' + localForm.EncoderId;

    delete localForm.EncoderId;
    const config = {
        headers: {},
        body: {
            ...localForm,
        },
        response: false,
    };

    return API.patch('SBS', path, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function postEncoder(localForm: SBSMedia.EncoderProps) {
    const path = 'encoders';

    const config = {
        headers: {},
        body: {
            ...localForm,
        },
        response: false,
    };

    return API.post('SBS', path, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function deleteEncoder(localForm: any) {
    const path = 'encoders/' + localForm.encoderId;

    const config = {
        headers: {},
        body: {
            ...localForm, // this might need to be reformatted?
        },
        response: false,
    };

    return API.del('SBS', path, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function getThumbnail(encoderId: string) {
    const path = 'encoders/' + encoderId + '/thumbnail';
    const config = {
        headers: {},
        response: false,
    };

    return API.get('SBS', path, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}
