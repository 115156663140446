// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
import { FormField, Multiselect, Select, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { setErrorText } from '../../helperFunctions';
import { DestinationForm } from '../../AddEvent';

export const DestinationProfileForm: React.FC<DestinationForm> = ({
    didSubmit,
    localForm,
    setLocalForm,
    profiles,
    destination,
}) => {
    return (
        <SpaceBetween direction="vertical" size="m">
            {destination != 'CloudFront' ? (
                <FormField
                    description="Profile"
                    errorText={setErrorText(didSubmit, localForm, {
                        attribute: 'destinations',
                        attribute2: destination,
                        attribute3: 'profile',
                    })}>
                    <Select
                        onChange={({ detail }) => {
                            setLocalForm({
                                ...localForm,
                                ['destinations']: {
                                    ...localForm.destinations,
                                    [destination]: {
                                        ...localForm.destinations[destination],
                                        ['profile']: detail.selectedOption,
                                    },
                                },
                            });
                        }}
                        placeholder="Choose a Profile"
                        selectedOption={{
                            label: localForm.destinations[destination]['profile']
                                ? localForm.destinations[destination]['profile'].label
                                : '',
                            value: localForm.destinations[destination]['profile']
                                ? localForm.destinations[destination]['profile'].value
                                : '',
                        }}
                        options={profiles.map((p) => {
                            return {
                                'label': p.name,
                                'value': p.ProfileId,
                            };
                        })}
                    />
                </FormField>
            ) : (
                <FormField
                    description="Profile"
                    errorText={setErrorText(didSubmit, localForm, {
                        attribute: 'destinations',
                        attribute2: destination,
                        attribute3: 'profiles',
                    })}>
                    <Multiselect
                        onChange={({ detail }) => {
                            setLocalForm({
                                ...localForm,
                                ['destinations']: {
                                    ...localForm.destinations,
                                    ['CloudFront']: {
                                        ...localForm.destinations['CloudFront'],
                                        ['profiles']: detail.selectedOptions as [],
                                    },
                                },
                            });
                        }}
                        placeholder="Choose a Profile"
                        selectedOptions={
                            (localForm.destinations[destination]['profiles'] &&
                                localForm.destinations[destination]['profiles']?.map((item) => {
                                    return {
                                        label: item.label,
                                        value: item.value,
                                    };
                                })) ||
                            []
                        }
                        options={profiles.map((p) => {
                            return {
                                'label': p.name,
                                'value': p.ProfileId,
                            };
                        })}
                    />
                </FormField>
            )}
        </SpaceBetween>
    );
};
