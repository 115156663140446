// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';
import {
    Button,
    Container,
    Form,
    FormField,
    Grid,
    Input,
    Popover,
    SpaceBetween,
    StatusIndicator,
} from '@cloudscape-design/components';
import { useAppDispatch } from 'app/hooks';
import { updateS3Bucket } from 'features/SettingsManagement/SettingsManagementSlice';

interface BucketProps {
    type: string;
    newBucket: string;
    s3Bucket: string;
    setNewBucket: React.Dispatch<React.SetStateAction<string>>;
}
export const DefaultBucket: React.FC<BucketProps> = ({
    type,
    newBucket,
    s3Bucket,
    setNewBucket,
}) => {
    const dispatch = useAppDispatch();

    return (
        <Container header={<h4>Default {type} S3 Bucket here</h4>}>
            <Form>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 2 }, { colspan: 1 }]}>
                    <FormField>
                        <Input
                            value={
                                newBucket ? 's3ssl://' + newBucket?.split('s3ssl://')[1] : s3Bucket
                            }
                            onChange={({ detail }) => {
                                if (detail.value.includes('s3ssl://')) {
                                    setNewBucket(detail.value);
                                }
                            }}
                        />
                    </FormField>
                    <FormField>
                        <Button
                            onClick={() => {
                                console.log(newBucket);
                                dispatch(updateS3Bucket({ string: newBucket, type: type }));
                            }}>
                            Submit
                        </Button>
                    </FormField>
                </Grid>
                <br />
                <SpaceBetween size="xxs" direction="horizontal" alignItems="center">
                    Your bucket is: {s3Bucket}{' '}
                    <FormField>
                        <Popover
                            dismissButton={false}
                            position="top"
                            size="large"
                            triggerType="custom"
                            content={<StatusIndicator type="success">Copied</StatusIndicator>}>
                            <Button
                                iconName="copy"
                                onClick={() => {
                                    navigator.clipboard.writeText(s3Bucket);
                                }}
                            />
                        </Popover>
                    </FormField>
                </SpaceBetween>
            </Form>
        </Container>
    );
};
