import { SBSMedia } from '_types';
import React, { Dispatch, SetStateAction } from 'react';

import { deleteEventsAsync } from 'features/EventManagement/EventManagementSlice';
import { deleteProfilesAsync } from 'features/SettingsManagement/SettingsManagementSlice';
import { isDeleteDisabled } from 'utils/common';
import { Table } from '@cloudscape-design/components';

/*
/ Step 0: Render if Profile has Linked Events 
/ Step 1: Render to see list of events 
/ Step 2: Render to confirm delete of events
/ Step 3: Render IF more events remain
/ Step 4: Render if Profile has NO linked Events 
*/

export function renderStepItems(
    step: number,
    localProfileForm: SBSMedia.Profile,
    setSelectedItems,
    selectedItems: SBSMedia.ProfileEvent[],
    attachedEvents: SBSMedia.ProfileEvent[]
) {
    switch (step) {
        case 0:
            return (
                <div>
                    This profile cannot be deleted until the{' '}
                    {localProfileForm?.ReferenceEvents?.length} events linked to it are deleted or
                    resassigned to other profiles. Do you want to see the linked events?
                </div>
            );
        case 1:
            return (
                <div>
                    Only events in the Configured, Exception, or Completed state may be Deleted
                    <br />
                    <br />
                    <Table
                        onSelectionChange={({ detail }) => {
                            setSelectedItems(detail.selectedItems);
                        }}
                        selectedItems={selectedItems}
                        selectionType="multi"
                        isItemDisabled={(item) => item.disabled}
                        columnDefinitions={[
                            {
                                id: 'name',
                                header: 'Event name',
                                cell: (item) => <div>{item.eventName || '-'}</div>,
                                sortingField: 'name',
                                isRowHeader: true,
                                maxWidth: 250,
                            },
                            {
                                id: 'state',
                                header: 'State',
                                cell: (item) => <div>{item.eventState}</div>,
                                sortingField: 'state',
                                isRowHeader: true,
                            },
                        ]}
                        items={attachedEvents}
                        loadingText="Loading events..."
                        sortingDisabled
                    />
                </div>
            );
        case 2:
            return (
                <div>
                    The following events will be deleted:
                    <ul>
                        {selectedItems.map((e) => {
                            return <li key={e.EventId}>{e.eventName}</li>;
                        })}
                    </ul>
                </div>
            );
        case 3:
            return (
                <div>
                    There are still events associated with this profile. Please delete all linked
                    events.
                </div>
            );
        case 4:
            return <div>Are you sure you want to delete this profile? </div>;
        case 5:
            return <div>There was an error deleting this profile. Please try again.</div>;
        default:
            return <div></div>;
    }
}

export function findText(step: number) {
    switch (step) {
        case 0:
            return 'See Linked Events';
        case 1:
            return 'Review Events';
        case 2:
            return 'Delete Events';
        case 3:
            return 'Continue';
        case 4:
            return 'Delete Profile';
    }
}

export function findIsDisabled(step: number, selectedItems: SBSMedia.ProfileEvent[]) {
    // (step > 0 && step <= 2 && selectedItems.length < 1) || step === 3
    switch (step) {
        case 0:
            return false;
        case 1:
            return selectedItems.length < 1;
        case 2:
            return false;
        case 3:
            return true;
        case 4:
            return false;
    }
}

export function setOnClick(
    step: number,
    selectedItems: SBSMedia.ProfileEvent[],
    setDeleteError: Dispatch<SetStateAction<boolean>>,
    localProfileForm: SBSMedia.Profile,
    deleteError: boolean,
    setStep: Dispatch<SetStateAction<number>>,
    dispatch,
    reset: { (): void; (): void },
    setAlertText: React.Dispatch<React.SetStateAction<string>>
) {
    switch (step) {
        case 2:
            selectedItems.forEach((item) => {
                if (!isDeleteDisabled(item.eventState)) {
                    dispatch(
                        deleteEventsAsync({
                            'EventId': item.EventId,
                            'reasonForDeletion': '',
                        })
                    ).catch(() => {
                        setDeleteError(true);
                    });
                } else {
                    setDeleteError(true);
                }
            });

            if (selectedItems.length === localProfileForm.ReferenceEvents.length && !deleteError)
                setStep(4);
            else setStep(3);

            break;
        case 3:
            reset();
            break;
        case 4:
            dispatch(deleteProfilesAsync(localProfileForm.ProfileId))
                .then(() => setAlertText('Profile deleted'))
                .catch(() => {
                    setAlertText('Something went wrong deleting your profile.');
                })
                .finally(() => {
                    reset();
                });

            break;
        case 5:
            break;
        default:
            setStep(step + 1);
    }
}

export function setHeaderName(localProfileForm: SBSMedia.Profile, step: number) {
    switch (step) {
        case 0:
            return 'Active Events for ' + localProfileForm?.name;
        case 1:
            return 'Active Events for ' + localProfileForm?.name;
        case 2:
            return 'Delete Events for ' + localProfileForm?.name;
        case 3:
            return 'Profile Deletion Incomplete for ' + localProfileForm?.name;
        case 4:
            return 'Delete Profile ' + localProfileForm?.name;
        default:
            return 'Active Events for ' + localProfileForm?.name;
    }
}
