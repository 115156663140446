// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

export namespace SBSMedia {
    export interface Event {
        EventId: string;
        inputType: string;
        description: string;
        taskNumber: string;
        closedCaptions: boolean;
        slateLocation?: SlateObject;
        slateBoolean: boolean;
        inputState: string;
        primaryEncoder?: string;
        backupEncoder?: string;
        backupSRT?: boolean;
        fileInput?: string;
        srtRegion?: string;
        srtMode?: string;
        latency?: number;
        passphrase?: string;
        passphraseBackup?: string;
        primarySRTPort?: string;
        backupSRTPort?: string;
        primaryIp?: string;
        backupIp?: string;
        eventName: string;
        startTime?: number;
        endTime?: number;
        createdAt?: number;
        eventState?: EventStateOptions;
        iFrameUrl?: string;
        rtmpCIDR?: string;
        rtmpBackup?: boolean;
        rtmpStreamkey?: string;
        creator?: string;
        PrimaryAllowlistCidr?: string;
        BackupAllowlistCidr?: string;
        destinations: {
            Facebook?: Destination;
            YouTube?: Destination;
            Workplace?: Destination;
            Twitch?: Destination;
            Instagram?: Destination;
            OutputSRT?: SrtDestination;
            CloudFront?: Destination;
        };
        destinationInformation?: any;
        MediaLiveChannelARN?: string;
        disabled?: boolean;
        srtOutputData?: SrtOutputData;
    }
    export interface ProfileEvent {
        EventId: string;
        eventName: string;
        disabled: boolean;
        eventState: EventStateOptions;
    }
    export enum EventStateOptions {
        STARTING = 'STARTING',
        STOPPING = 'STOPPING',
        READY = 'READY',
        LIVE = 'LIVE',
        OFFLINE = 'OFFLINE',
        COMPLETED = 'COMPLETED',
        PENDING = 'PENDING',
        CONFIGURED = 'CONFIGURED',
        REMOVED = 'REMOVED',
        SCHEDULED = 'SCHEDULED',
        EXCEPTION = 'EXCEPTION',
    }
    export interface SlateObject {
        Opening?: string;
        Intermission?: string;
        Closing?: string;
    }
    export enum SlateTypes {
        OPENING = 'Opening',
        INTERMISSION = 'Intermission',
        CLOSING = 'Closing',
    }
    export enum InputTypes {
        ELEMENTAL_LINK = 'Elemental Link',
        ELEMENTAL_LIVE = 'Elemental Live',
        SRT_INGEST = 'SRT Ingest',
        FILE = 'File',
        RTMP = 'RTMP',
    }

    export enum InputTypesLabel {
        ELEMENTAL_LINK = 'Elemental Link',
        ELEMENTAL_LIVE = 'Elemental Live - Coming Soon',
        SRT_INGEST = 'SRT Ingest',
        FILE = 'File Source',
        RTMP = 'RTMP Ingest',
    }

    export interface Profile {
        //aac: AudioEncoderFormat;
        Width: number;
        Height: number;
        name: string;
        VideoBitrate: number;
        ProfileId: string;
        // audioBitrate: number;
        videoFrameRate: number;

        AudioBitrate: number;

        keyframeInterval: number;
        ReferenceEvents: string[];
    }
    export interface SrtOutputData {
        primary: { endpoint: string };
        backup: { endpoint: string };
    }
    export interface AudioEncoderFormat {
        Bitrate?: number;
        SampleRate?: number;
    }
    export interface Preset {
        template: any;
        aac: AudioEncoderFormat;
        Width: number;
        Height: number;
        name: string;
        Bitrate: number;
        // audioBitrate: number;
        // videoFrameRate: number;
        // profileid: string;
    }

    export interface Destination {
        primaryStreamKey: string;
        backupStreamKey: string;
        primaryUrl: string;
        backupUrl: string;
        profile: string; //UUID?
        record: boolean;
        profiles?: OptionDefinition[];
    }

    export interface SrtDestination {
        output: string; //url
        profile: string; //UUID?
        record: boolean;
        srtMode: string;
        primaryPort?: number;
        primaryAddress?: string;
        primaryAllowlist?: string;
        passphrase?: string;
        backupAllowList?: string;
        backupIp?: string;
        backupPort?: string;
        latency?: number;
        backupEnabled?: boolean;
        backupPassphrase?: string;
    }

    // how does encoder relate to profile/event?
    export interface Encoder {
        EncoderId: string;
        input_id: string;
        region: string;
        name: string;
        ConnectionState: string;
        enc_type: string;
        arn?: string;
        ip?: string;
        deviceSettings?: EncoderDeviceSettings;
        thumbnail?: string;
    }
    export interface EncoderProps {
        EncoderId: string;
        name: string;
        region: string;
        ip: string;
        user: string;
        apikey: string;
    }

    export interface EventProps extends Event {
        EventId: string;
    }

    export interface EventDeletionProps {
        EventId: string;
        reasonForDeletion: string;
    }

    export interface EncoderMetricProps {
        metricName: string;
        dimensions: object;
        period: number;
        EncoderId: string;
    }

    export interface EventMetricProps {
        metricName: string;
        dimensions: object;
        period: number;
        eventid: string;
    }

    export interface BoardItem {
        id: string;
        rowSpan: number;
        columnSpan: number;
        data: Metric;
    }

    export interface Metric {
        metricName: string;
        encoders: EncoderData[];
    }

    export interface EncoderData {
        EncoderId: string;
        datapoints: {
            x: any;
            y: any;
        }[];
    }

    export interface EncoderDeviceSettings {
        ActiveInput: string;
        ConfiguredInput: string;
        DeviceState: string;
        Framerate: number;
        Height: number;
        LatencyMs: number;
        MaxBitrate: number;
        ScanType: string;
        Width: number;
    }
}
export enum DestinationImage {
    FACEBOOK = 'Facebook',
    YOUTUBE = 'YouTube',
    CUSTOMRTMP = 'CustomRTMP',
    LINKEDIN = 'LinkedIn',
    TWITCH = 'Twitch',
    WORKPLACE = 'Workplace',
    INSTAGRAM = 'Instagram',
    CLOUDFRONT = 'CloudFront',
    OUTPUTSRT = 'OutputSRT',
    RECORD = 'Record',
}
