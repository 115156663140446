// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction } from 'react';
import {
    Button,
    ColumnLayout,
    Popover,
    SpaceBetween,
    ButtonDropdown,
} from '@cloudscape-design/components';
import {
    getEventsAsync,
    stopEventAsync,
    startEventAsync,
    changeEventSlateAsync,
} from 'features/EventManagement/EventManagementSlice';
import {
    convertStringEpochToDate,
    convertStringEpochToDate2,
    isStartStopDisabled,
    areInteractionsDisabled,
    displayPairs,
    ValueWithLabel,
    isDeleteDisabled,
} from 'utils/common';

import { SBSMedia } from '_types';

import { useAppDispatch } from 'app/hooks';
import DestinationPopover from './DestinationPopover';
import { SLATE_OPTIONS } from './TableCommon';
import {
    renderEventIdCell,
    renderIFrameCell,
    renderLocationCell,
    renderPreviewCell,
    renderStatusCell,
    renderTaskIdCell,
    renderStateName,
} from './CommonCells';

export const EVENT_CARD_DEFINITION = (
    setCurrentDetails: Dispatch<SetStateAction<SBSMedia.Event>>,
    encoders: SBSMedia.Encoder[],
    setModalVisible: Dispatch<SetStateAction<boolean>>,
    setItemDelete: Dispatch<SetStateAction<boolean>>,
    setDeleteVisible: Dispatch<SetStateAction<boolean>>,
    setScheduleModuleVisible: Dispatch<SetStateAction<boolean>>,
    systemPendingEvents: string[],
    setSystemPendingEvents: Dispatch<SetStateAction<string[]>>,
    setTransitionMetadata: Dispatch<SetStateAction<{visible: boolean, slateName: string, event: SBSMedia.Event}>>,
    thumbnails: any
) => {
    const dispatch = useAppDispatch();

    return {
        header: (e) => (
            <div>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap' }}
                    lang="en">
                    <span
                        style={{
                            hyphens: 'auto',
                            width: '63%',
                        }}>
                        {e.eventName}
                    </span>
                    <Button
                        iconName="edit"
                        disabled={
                            areInteractionsDisabled(e.eventState) ||
                            systemPendingEvents.includes(e.EventId)
                        }
                        variant="icon"
                        onClick={() => {
                            setCurrentDetails(e);
                            setModalVisible(true);
                        }}
                    />{' '}
                    {/* Uncomment to enable schedule modal on FE */}
                    {/* <Button
                        iconName="status-pending"
                        disabled={
                            areInteractionsDisabled(e.eventState) ||
                            systemPendingEvents.includes(e.EventId)
                        }
                        variant="icon"
                        onClick={() => {
                            setCurrentDetails(e);
                            setScheduleModuleVisible(true);
                        }}
                    />{' '} */}
                    <Button
                        iconName="copy"
                        disabled={
                            areInteractionsDisabled(e.eventState) ||
                            systemPendingEvents.includes(e.EventId)
                        }
                        variant="icon"
                        onClick={() => {
                            setCurrentDetails({
                                ...e,
                                eventName: e.eventName + ' Duplicate',
                                destinations: {},
                                startTime: '',
                                endTime: '',
                                EventId: undefined,
                            });
                            setModalVisible(true);
                        }}
                    />{' '}
                    <Button
                        iconName="remove"
                        variant="icon"
                        disabled={
                            isDeleteDisabled(e.eventState) ||
                            systemPendingEvents.includes(e.EventId)
                        }
                        onClick={() => {
                            setItemDelete(e);
                            setDeleteVisible(true);
                        }}
                    />
                </div>
            </div>
        ),
        sections: [
            {
                id: 'status',
                header: 'Status',
                width: 33,
                content: (e) => renderStatusCell(e),
            },
            {
                id: 'inputState',
                header: 'State',
                width: 66,
                content: (e) => renderStateName(e.inputState),
            },
            {
                id: 'input',
                content: (e) => {
                    return displayPairs(e, encoders, false, thumbnails);
                },
                width: 100,
            },
            {
                id: 'destinations',
                header: 'Destinations',
                width: 67,
                content: (e) => <DestinationPopover e={e} />,
            },
            {
                id: 'location',
                width: 33,
                content: (e) => renderLocationCell(e),
            },

            {
                id: 'create',
                width: 45,
                content: (e) => (
                    <ValueWithLabel label="Created by">
                        {`${e.creator} on ${convertStringEpochToDate2(e.createdAt || 'DNE')}`}
                        <Popover
                            key={'test'}
                            dismissButton={false}
                            position="right"
                            triggerType="custom"
                            content={
                                <>
                                    <ColumnLayout columns={2} borders="horizontal">
                                        <ValueWithLabel label="Creator">
                                            {' '}
                                            {e.creator}
                                        </ValueWithLabel>
                                        <ValueWithLabel label="Create Date">
                                            {' '}
                                            {convertStringEpochToDate(e.createdAt || 'DNE')}
                                        </ValueWithLabel>
                                    </ColumnLayout>
                                </>
                            }>
                            <Button iconName="status-info" variant="inline-icon" />
                        </Popover>
                    </ValueWithLabel>
                ),
            },

            {
                id: 'preview',
                width: 23,

                content: (e) => renderPreviewCell(e),
            },
            {
                id: 'iFrame',
                width: 30,
                content: (e) => renderIFrameCell(e),
            },

            {
                id: 'eventId',
                header: 'Event ID',
                width: 67,
                content: (e) => renderEventIdCell(e),
            },
            {
                id: 'taskID',
                header: 'Task ID',
                width: 33,
                content: (e) => renderTaskIdCell(e),
            },
            {
                id: 'hr',
                width: 100,
                content: () => {
                    return <hr style={{ 'padding': '0', 'margin': '0' }} />;
                },
            },
            {
                id: 'actionButton',
                width: 95,
                content: (e) =>
                    e.eventState === 'LIVE' ? (
                        <SpaceBetween size={'xl'} direction="horizontal">
                            <div style={{ filter: 'hue-rotate(150deg)' }}>
                                <Button
                                    fullWidth
                                    disabled={
                                        isStartStopDisabled(e.eventState) ||
                                        systemPendingEvents.includes(e.EventId)
                                    }
                                    onClick={() => {
                                        setSystemPendingEvents([...systemPendingEvents, e.EventId]);
                                        dispatch(
                                            stopEventAsync({
                                                'EventId': e.EventId,
                                            })
                                        ).then(() => {
                                            dispatch(getEventsAsync()).then(() => {
                                                setSystemPendingEvents([
                                                    ...systemPendingEvents.filter(
                                                        (id) => id !== e.EventId
                                                    ),
                                                ]);
                                            });
                                        });
                                    }}
                                    iconSvg={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            focusable="false"
                                            key="0">
                                            <g>
                                                <rect x="2" y="2" width="12" height="12" />
                                            </g>
                                        </svg>
                                    }>
                                    {'End Stream'}
                                </Button>
                            </div>
                            {e.slateBoolean && (
                                <ButtonDropdown
                                    items={SLATE_OPTIONS(e)}
                                    onItemClick={(item) => {
                                        setTransitionMetadata({visible: true, slateName: item.detail.id, event: e})
                                       
                                    }}>
                                    Live Feed Input
                                </ButtonDropdown>
                            )}
                        </SpaceBetween>
                    ) : (
                        <SpaceBetween size={'xl'} direction="vertical">
                            <Button
                                fullWidth
                                disabled={
                                    isStartStopDisabled(e.eventState) ||
                                    systemPendingEvents.includes(e.EventId)
                                }
                                onClick={() => {
                                    setSystemPendingEvents([...systemPendingEvents, e.EventId]);
                                    dispatch(
                                        startEventAsync({
                                            'EventId': e.EventId,
                                        })
                                    ).then(() => {
                                        dispatch(getEventsAsync()).then(() => {
                                            setSystemPendingEvents([
                                                ...systemPendingEvents.filter(
                                                    (id) => id !== e.EventId
                                                ),
                                            ]);
                                        });
                                    });
                                }}
                                iconSvg={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        focusable="false"
                                        key="0">
                                        <g>
                                            <polygon points="4,1 4,14 15,8 " />
                                        </g>
                                    </svg>
                                }>
                                {'Start Stream'}
                            </Button>
                        </SpaceBetween>
                    ),
            },
        ],
    };
};

export const CARD_PROPERTIES = [
    { label: 'Name', value: 'eventName' },
    { label: 'Event Status', value: 'eventState' },
    { label: 'Input Type', value: 'inputType' },
    { label: 'Creator', value: 'creator' },
    { label: 'Created Date & Time', value: 'createdAt' },
    { label: 'Scheduled Start Date & Time', value: 'startTime' },
    { label: 'Scheduled End Date & Time', value: 'endTime' },
];
