// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import EncoderDashboard from 'components/Encoders/EncoderDashboard/EncoderDashboard';
import FileUploadModal from 'components/Encoders/FileUploadModal/FileUploadModal';

import React, { useState } from 'react';

const Encoder: React.FC = () => {
    const [uploadVisible, setUploadVisible] = useState(false);

    return (
        <>
            <EncoderDashboard setUploadVisible={setUploadVisible} />
            <FileUploadModal setVisible={setUploadVisible} visible={uploadVisible} />
        </>
    );
};

export default Encoder;
