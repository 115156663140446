// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';

import { TopNavigation } from '@cloudscape-design/components';
import { Auth } from 'aws-amplify';

interface HeaderProps {
    curUser: any;
}

const Header: React.FC<HeaderProps> = ({ curUser }) => {
    return (
        <TopNavigation
            identity={{
                href: '/dashboard',
                title: 'Meta Streaming and Distribution',
                logo: {
                    src: require('logo-Meta.png'),
                    alt: 'logoM&E',
                },
            }}
            utilities={[
                {
                    type: curUser ? 'menu-dropdown' : 'button',
                    text:
                        curUser && curUser['attributes']['given_name']
                            ? `${curUser['attributes']['given_name']} ${curUser['attributes']['family_name']}`
                            : curUser && curUser['username']
                            ? curUser['username']
                            : 'Login',
                    iconName: 'user-profile',
                    items: curUser ? [{ id: 'signout', text: 'Sign out' }] : [],
                    onItemClick: async (e) => {
                        if (e.detail.id == 'signout') {
                            await Auth.signOut({ global: true })
                                .then(() => {
                                    window.location.replace('/login');
                                })
                                .catch(async () => {
                                    await Auth.signOut();
                                    window.location.replace('/login');
                                });
                        }
                    },
                },
            ]}
        />
    );
};

export default Header;
