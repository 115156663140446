// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction } from 'react';
import { Button, Header, SpaceBetween } from '@cloudscape-design/components';
import { SBSMedia } from '_types';

// Commented out code allows hide for Add Encoder and Add Bulk Encoder

export const CUSTOM_HEADER = (
    encoders: SBSMedia.Encoder[],
    setAddModalVisible: Dispatch<SetStateAction<boolean>>,
    setBulkModalVisible: Dispatch<SetStateAction<boolean>>,
    setEncoderInfo: Dispatch<SetStateAction<SBSMedia.Encoder>>
) => {
    return (
        <Header
            variant="awsui-h1-sticky"
            counter={`(${encoders.length})`}
            actions={
                <SpaceBetween size="l" direction="horizontal">
                    {/* <Button
                        variant="primary"
                        onClick={() => {
                            setEncoderInfo({} as SBSMedia.Encoder);
                            setAddModalVisible(true);
                        }}>
                        Add Encoder
                    </Button> */}
                    {/* <Button variant="primary" onClick={() => setBulkModalVisible(true)}>
                        Add Bulk Encoder
                    </Button> */}
                </SpaceBetween>
            }>
            {'Encoders'}
        </Header>
    );
};
