// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { ContentLayout } from '@cloudscape-design/components';
import DashboardHeader from 'components/Common/DashboardHeader';
import MetricDashboard from 'components/Metrics/MetricDashboard/MetricDashboard';
import React, { useEffect } from 'react';
import data from '../data/metrics.json';
import { getEncoderMetricData } from 'features/MetricsManagement/MetricsManagementSlice';
import { useAppDispatch } from 'app/hooks';

const Home: React.FC = () => {
    const dispatch = useAppDispatch();
    const date = new Date().getTimezoneOffset();
    const timezone = '-0' + (date / 60).toLocaleString() + '00';
    return (
        <ContentLayout header={<DashboardHeader title={'Metrics Dashboard'} />}>
            <MetricDashboard />
        </ContentLayout>
    );
};

export default Home;
