// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';
import {
    Button,
    ColumnLayout,
    Link,
    Popover,
    SpaceBetween,
    StatusIndicator,
} from '@cloudscape-design/components';

import { ValueWithLabel } from 'utils/common';
import { DESTINATION_PROPERTIES } from '../AddEvent/AddEventConstants';
import { TbBucket } from 'react-icons/tb';
import { updateStatusIndicatorColor, updateStatusIndicatorType } from './TableCommon';
import ReactPlayer from 'react-player';

export function renderStatusCell(e) {
    if (e.eventState === 'LIVE') {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                <div className="live-indicator" />
                <div style={{ color: '#d91515' }}>Streaming</div>
            </div>
        );
    } else {
        return (
            <StatusIndicator
                colorOverride={updateStatusIndicatorColor(e.eventState)}
                type={updateStatusIndicatorType(e.eventState)}>
                <>
                    {e.eventState === 'EXCEPTION' ? (
                        <Popover
                            dismissButton={false}
                            position="top"
                            size="small"
                            content={e['exceptionLog']}>
                            {e.eventState.charAt(0).toUpperCase() +
                                e.eventState.slice(1).toLowerCase()}
                        </Popover>
                    ) : (
                        e.eventState.charAt(0).toUpperCase() + e.eventState.slice(1).toLowerCase()
                    )}
                </>
            </StatusIndicator>
        );
    }
}

export function renderLocationCell(e) {
    if (e.destinations && Object.keys(e.destinations).includes('Record')) {
        return (
            //<SpaceBetween size={'s'} direction="vertical" alignItems="center">
            <div style={{ 'display': 'inline-block' }}>
                <ValueWithLabel label="S3 Location">
                    <center>
                        <Popover
                            key={'record'}
                            dismissButton={false}
                            position="top"
                            size="large"
                            triggerType="custom"
                            content={
                                <ColumnLayout columns={3} borders="horizontal">
                                    {Object.keys(DESTINATION_PROPERTIES['Record'].properties).map(
                                        (attribute) => {
                                            return (
                                                <div key={attribute}>
                                                    {e.destinations['Record'][attribute] !=
                                                        undefined && attribute != 'profiles' ? (
                                                        <>
                                                            <div
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                {
                                                                    DESTINATION_PROPERTIES['Record']
                                                                        .properties[attribute]
                                                                }
                                                            </div>
                                                            <div>
                                                                {attribute == 'profile'
                                                                    ? e.destinations['Record'][
                                                                          attribute
                                                                      ].label
                                                                    : e.destinations['Record'][
                                                                          attribute
                                                                      ].toString()}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            );
                                        }
                                    )}
                                </ColumnLayout>
                            }>
                            <TbBucket size={'1.4em'} style={{ padding: '.2em' }} color="green" />
                        </Popover>
                    </center>
                </ValueWithLabel>
            </div>
            //</SpaceBetween>
        );
    } else {
        return <ValueWithLabel label="S3 Location"> --- </ValueWithLabel>;
    }
}

export function renderPreviewCell(e) {
    return (
        <div>
            <Popover
                dismissButton={false}
                position="top"
                size="large"
                triggerType="custom"
                content={
                    <ReactPlayer
                        width={'inherit'}
                        height={'inherit'}
                        url={e.MediaPackageConfidenceUrl}
                        controls={true}
                        muted={true}
                        playing={true}
                    />
                }>
                <div>&nbsp;</div>
                <Button
                    variant="inline-link"
                    iconName="external"
                    // disabled={e.eventState != 'LIVE'}
                >
                    Monitor
                </Button>
            </Popover>
        </div>
    );
}

export function renderIFrameCell(e) {
    const iFrame = e.iFrameSnippet;
    return e.destinations && Object.keys(e.destinations).includes('CloudFront') ? (
        <>
            <Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <div>
                        <StatusIndicator type="success">Copied</StatusIndicator>
                        <p></p>
                        {iFrame}
                    </div>
                }>
                <div>&nbsp;</div>
                <Button
                    variant="inline-link"
                    iconName="copy"
                    ariaLabel="Copy"
                    onClick={() => navigator.clipboard.writeText(iFrame)}>
                    iFrame
                </Button>
            </Popover>
        </>
    ) : (
        <></>
    );
}

export function renderEventIdCell(e) {
    return (
        <SpaceBetween direction="vertical" alignItems="start" size="xs">
            <Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={<StatusIndicator type="success">Copied</StatusIndicator>}>
                <div
                    onClick={() => navigator.clipboard.writeText(e.EventId)}
                    style={{ all: 'unset', cursor: 'pointer' }}>
                    <Button variant="inline-link" iconName="copy" ariaLabel="Copy" />
                    {e.EventId}
                </div>
            </Popover>
        </SpaceBetween>
    );
}

export function renderTaskIdCell(e) {
    return e.taskNumber ? (
        <SpaceBetween direction="vertical" alignItems="start" size="xxs">
            <div>
                <Button
                    variant="inline-link"
                    iconName="external"
                    // disabled={e.eventState != 'LIVE'}
                />

                <Link
                    href={`https://www.internalfb.com/tasks/?t=${e.taskNumber.substring(1)}`}
                    target="_new">
                    {e.taskNumber}
                </Link>
            </div>
        </SpaceBetween>
    ) : (
        <>{'None'}</>
    );
}

export function renderStateName(inputState: string) {
    // If theres an input state and the input state is not equal to
    // live then display the input state and append it with the word
    // slate, otherwise display the input state.
    // If there is no input state, then display two dashes

    if (!inputState) {
        return '--';
    } else if (inputState !== 'Live') {
        return inputState + ' Slate';
    } else if (inputState === 'Live') {
        return inputState;
    } else {
        return '--';
    }
}
