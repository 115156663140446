// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction } from 'react';

import { SBSMedia } from '_types';
import EncoderAttribute from './EncoderAttribute';
import { SpaceBetween } from '@cloudscape-design/components';

interface EncoderAttributeProps {
    encoderInfo: SBSMedia.EncoderProps;
    localForm: SBSMedia.EncoderProps;
    setLocalForm: Dispatch<SetStateAction<SBSMedia.EncoderProps>>;

    didSubmit: boolean;
}
const ElementalLiveFields: React.FC<EncoderAttributeProps> = ({
    encoderInfo,
    localForm,
    setLocalForm,
    didSubmit,
}) => {
    const encoderPropPack = {
        encoderInfo: encoderInfo,
        localForm: localForm,
        setLocalForm: setLocalForm,
    };

    function setErrorText(attribute) {
        return didSubmit && !localForm[attribute] ? 'This field is required' : '';
    }

    return (
        <SpaceBetween direction="vertical" size="l">
            <EncoderAttribute
                {...encoderPropPack}
                errorText={setErrorText('ip')}
                label={'Encoder IP'}
                attributeName={'ip'}
            />

            <EncoderAttribute
                errorText={setErrorText('user')}
                {...encoderPropPack}
                label={'Username'}
                attributeName={'user'}
            />

            <EncoderAttribute
                errorText={setErrorText('apikey')}
                {...encoderPropPack}
                label={'API Key'}
                attributeName={'apikey'}
            />
        </SpaceBetween>
    );
};

export default ElementalLiveFields;
