// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction } from 'react';

import { FormField, Input } from '@cloudscape-design/components';
import { SBSMedia } from '_types';

import { sanitizeInput } from 'utils/common';

interface EncoderAttributeProps {
    errorText: string;
    encoderInfo: SBSMedia.EncoderProps;
    localForm: SBSMedia.EncoderProps;
    setLocalForm: Dispatch<SetStateAction<SBSMedia.EncoderProps>>;
    label: string;
    description?: string;
    attributeName: string;
}
const EncoderAttribute: React.FC<EncoderAttributeProps> = ({
    encoderInfo,
    errorText,
    localForm,
    setLocalForm,
    label,
    description,
    attributeName,
}) => {
    return (
        <FormField label={label} description={description} errorText={errorText}>
            <Input
                onChange={({ detail }) =>
                    setLocalForm({
                        ...localForm,
                        [attributeName]: sanitizeInput(detail.value),
                    })
                }
                value={
                    localForm[attributeName] == undefined
                        ? encoderInfo[attributeName]
                        : localForm[attributeName]
                }
            />
        </FormField>
    );
};

export default EncoderAttribute;
