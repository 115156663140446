import { SBSMedia } from '_types';
import {
    ElementalLinkProperties,
    FileProperties,
    RTMPProperties,
    SRTIngestBackupProperties,
    SRTIngestCallerProperties,
    SRTIngestListenerProperties,
    SRTIngestTotalProperties,
} from './AddEventConstants';

export function checkFormValid(localForm): boolean {
    const initialValidation =
        localForm.eventName &&
        localForm.inputType &&
        localForm.destinations &&
        Object.keys(localForm.destinations)?.length > 0;

    if (!initialValidation) {
        return false;
    }

    // Returns true if there is an error in one of the fields/missing data, otherwise continues
    // When true, the form will not submit
    // Only valid for destinations that are NOT OutputSRT
    // Ignores check if the destination attr is backup as this is an optional field
    // When attribute is profles (on CloudFront destination), checks that object exists,
    // is not empty or empty string, and has at least 1 profile selected
    const checkDestinationInputsInvalid = Object.keys(localForm.destinations).some((e) => {
        if (e !== 'OutputSRT') {
            return Object.keys(localForm.destinations[e])
                .filter((attr) => {
                    return !attr.includes('backup');
                })
                .some((information) => {
                    if (information === 'profiles') {
                        return (
                            (!localForm.destinations[e][information] &&
                                localForm.destinations[e][information] === '') ||
                            (typeof localForm.destinations[e][information] === 'object' &&
                                Object.keys(localForm.destinations[e][information])?.length === 0)
                        );
                    } else if (information === 'profile') {
                        return localForm.destinations[e][information].value === undefined;
                    } else if (!information.includes('passphrase')) {
                        return localForm.destinations[e][information] === '';
                    }
                });
        }
    });

    if (checkDestinationInputsInvalid) {
        return false;
    }

    if (localForm.destinations.OutputSRT) {
        if (!localForm.destinations.OutputSRT.srtMode) {
            return false;
        } else if (
            localForm.destinations.OutputSRT.srtMode === 'Listener' &&
            !localForm.destinations.OutputSRT.primaryAllowlist
        ) {
            return false;
        } else if (
            localForm.destinations.OutputSRT.srtMode === 'Caller' &&
            !localForm.destinations.OutputSRT.primaryAddress
        ) {
            return false;
        }
        if (!localForm.destinations.OutputSRT.primaryPort) {
            return false;
        }
        if (!localForm.destinations.OutputSRT.latency) {
            return false;
        }
        if (!localForm.destinations.OutputSRT.profile) {
            return false;
        }
    }
    if (
        'Record' in localForm.destinations &&
        (!localForm.destinations.Record?.primaryUrl.includes('s3ssl://') ||
            /\s/.test(localForm.destinations.Record?.primaryUrl) ||
            localForm.destinations.Record?.primaryUrl.length <= 8)
    ) {
        return false;
    }
    if (localForm.slateBoolean && localForm.slateLocation.Opening?.length < 9) {
        return false;
    }

    switch (localForm.inputType) {
        case SBSMedia.InputTypes.ELEMENTAL_LINK:
            return initialValidation && localForm.primaryEncoder; // && localForm.backupEncoder; // this means there needs to be a backup
        case SBSMedia.InputTypes.ELEMENTAL_LIVE:
            return initialValidation && localForm.primaryEncoder; //&& localForm.backupEncoder; // this means there needs to be a backup
        case SBSMedia.InputTypes.SRT_INGEST:
            return (
                initialValidation &&
                localForm.srtRegion &&
                localForm.latency &&
                (localForm.primaryIp || localForm.PrimaryAllowlistCidr) &&
                localForm.primarySRTPort
            );
        case SBSMedia.InputTypes.FILE:
            return (
                initialValidation &&
                localForm.fileInput &&
                !/\s/.test(localForm.fileInput) &&
                localForm.fileInput.length > 8
            );
        case SBSMedia.InputTypes.RTMP:
            return initialValidation && localForm.rtmpCIDR;
    }

    return;
}
const totalProperties = [
    ...ElementalLinkProperties,
    ...SRTIngestTotalProperties,
    ...FileProperties,
    ...RTMPProperties,
];

function removeFieldsHelper(selectedPropertiesKeep, copiedForm) {
    const irrelevantProperties = totalProperties.filter(function (item) {
        return selectedPropertiesKeep.indexOf(item) < 0; // Returns true for items not found in b.
    });

    irrelevantProperties.forEach((e) => {
        delete copiedForm[e];
    });
}

function eventHasBackup(localEventForm: SBSMedia.Event): boolean {
    switch (localEventForm.inputType) {
        case SBSMedia.InputTypes.ELEMENTAL_LINK:
            return (
                Object.hasOwn(localEventForm, 'backupEncoder') &&
                localEventForm.backupEncoder.length > 0 &&
                localEventForm.backupEncoder !== 'None'
            );
        case SBSMedia.InputTypes.ELEMENTAL_LIVE:
            return false;
        case SBSMedia.InputTypes.SRT_INGEST:
            return localEventForm.backupSRT;
        case SBSMedia.InputTypes.FILE:
            return false;
        case SBSMedia.InputTypes.RTMP:
            return localEventForm.rtmpBackup;
    }
}

export function displayDestinationAttribute(
    property: string,
    localEventForm: SBSMedia.Event
): boolean {
    return (
        !property.includes('backup') ||
        (property.includes('backup') && eventHasBackup(localEventForm))
    );
}

function removeSRTProperties(propertiesToRemove, copiedForm) {
    propertiesToRemove.forEach((badProperty) => {
        delete copiedForm[badProperty];
    });
}
export function removeExtraneousFieldsForm(localForm: SBSMedia.Event) {
    let cleanForm = { ...localForm };

    if (localForm.slateBoolean) {
        Object.keys(cleanForm.slateLocation).forEach((e) => {
            if (cleanForm.slateLocation[e] === ' ') {
                delete cleanForm.slateLocation[e];
            } else if (cleanForm.slateLocation[e]) {
                cleanForm = {
                    ...cleanForm,
                    slateLocation: {
                        ...cleanForm.slateLocation,
                        [e]: cleanForm.slateLocation[e].trim(),
                    },
                };
            }
        });
    }

    switch (localForm.inputType) {
        case SBSMedia.InputTypes.ELEMENTAL_LINK:
            removeFieldsHelper(ElementalLinkProperties, cleanForm);
            break;
        case SBSMedia.InputTypes.SRT_INGEST:
            removeFieldsHelper(SRTIngestTotalProperties, cleanForm);
            if (!localForm.backupSRT) {
                removeSRTProperties(SRTIngestBackupProperties, cleanForm);
            }
            if (cleanForm.srtMode === 'Caller') {
                removeSRTProperties(SRTIngestListenerProperties, cleanForm);
            } else if (cleanForm.srtMode === 'Listener') {
                removeSRTProperties(SRTIngestCallerProperties, cleanForm);
            }
            if (!localForm.passphrase || localForm.passphrase === '') {
                delete cleanForm.passphrase;
            }
            if (!localForm.passphraseBackup || localForm.passphraseBackup === '') {
                delete cleanForm.passphraseBackup;
            }
            break;
        case SBSMedia.InputTypes.RTMP:
            removeFieldsHelper(RTMPProperties, cleanForm);
            break;
        case SBSMedia.InputTypes.FILE:
            removeFieldsHelper(FileProperties, cleanForm);
            break;
    }
    return cleanForm;
}

export function setErrorText(
    didSubmit: boolean,
    localForm: SBSMedia.Event,
    metadata: {
        attribute: string;
        attribute2?: string;
        attribute3?: string;
        isS3?: boolean;
    }
): string {
    if (didSubmit) {
        if (metadata.isS3) {
            return /\s/.test(localForm[metadata.attribute])
                ? 'Ensure there are no spaces'
                : !localForm[metadata.attribute]
                ? 'Please enter a valid bucket name'
                : '';
        }
        if (metadata.attribute3) {
            if (metadata.attribute3.includes('backup')) {
                return '';
            }

            if (metadata.attribute2 === 'Record' && metadata.attribute3 === 'primaryUrl') {
                return /\s/.test(
                    localForm[metadata.attribute][metadata.attribute2][metadata.attribute3]
                )
                    ? 'Ensure there are no spaces '
                    : localForm[metadata.attribute][metadata.attribute2][metadata.attribute3]
                          ?.length <= 8
                    ? 'Please enter a valid bucket name'
                    : '';
            }
            if (
                metadata.attribute3 === 'profiles' &&
                typeof localForm[metadata.attribute][metadata.attribute2][metadata.attribute3] ===
                    'object'
            ) {
                return localForm[metadata.attribute][metadata.attribute2][metadata.attribute3]
                    ?.length < 1
                    ? 'Please select at least 1 profile'
                    : '';
            }
            if (
                metadata.attribute3 === 'profile' &&
                localForm[metadata.attribute][metadata.attribute2][metadata.attribute3]['value'] ===
                    undefined
            ) {
                return 'Please select a profile';
            }
            return !localForm[metadata.attribute][metadata.attribute2][metadata.attribute3] ||
                localForm[metadata.attribute][metadata.attribute2][metadata.attribute3] == ''
                ? 'This field is required'
                : '';
        }
        if (metadata.attribute === 'destinations') {
            return !localForm[metadata.attribute] ||
                Object.keys(localForm[metadata.attribute])?.length === 0
                ? 'Select at least one destination'
                : '';
        }
        if (metadata.attribute === 'slate') {
            return localForm.slateLocation?.Opening?.length < 9
                ? 'Please include an Opening slate'
                : '';
        }

        return !localForm[metadata.attribute] && localForm[metadata.attribute] != ''
            ? 'This field is required'
            : '';
    }
}

export function checkBackupEnabled(localForm: SBSMedia.Event): boolean {
    switch (localForm.inputType) {
        case SBSMedia.InputTypes.ELEMENTAL_LINK:
            return (
                localForm.primaryEncoder &&
                localForm.backupEncoder &&
                localForm.backupEncoder !== 'None'
            );
        case SBSMedia.InputTypes.SRT_INGEST:
            return localForm.backupSRT;
        case SBSMedia.InputTypes.RTMP:
            return localForm.rtmpBackup;
        default:
            return false;
    }
}

export function generateRandomPassphrase(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function updatePassphrase(passphraseType, localForm, setLocalForm, phrase) {
    switch (passphraseType) {
        case 'InputSRT':
            setLocalForm({
                ...localForm,
                ['passphrase']: phrase,
            });
            break;
        case 'BackupInputSRT':
            setLocalForm({
                ...localForm,
                ['passphraseBackup']: phrase,
            });
            break;
        case 'OutputSRT':
            setLocalForm({
                ...localForm,
                ['destinations']: {
                    ...localForm.destinations,
                    ['OutputSRT']: {
                        ...localForm.destinations['OutputSRT'],
                        ['passphrase']: phrase,
                    },
                },
            });
            break;
        case 'BackupOutputSRT':
            setLocalForm({
                ...localForm,
                ['destinations']: {
                    ...localForm.destinations,
                    ['OutputSRT']: {
                        ...localForm.destinations['OutputSRT'],
                        ['backupPassphrase']: phrase,
                    },
                },
            });
    }
}
