// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';

import './eventDashboard.scss';
import {
    Cards,
    PropertyFilter,
    CollectionPreferences,
    Pagination,
    SpaceBetween,
    Table,
    RadioGroup,
    FormField,
    Select,
    ColumnLayout,
    Multiselect,
    Toggle,
    
} from '@cloudscape-design/components';
import { PAGE_SIZE_OPTIONS, DEFAULT_PREFERENCES, useLocalStorage } from './TableCommon';
import { selectEvents } from 'features/EventManagement/EventManagementSlice';
import { convertStringEpochToDate } from 'utils/common';
import { useAppSelector } from 'app/hooks';
import { useCollection } from '@cloudscape-design/collection-hooks';

import DeleteModal from 'components/Common/DeleteModal/DeleteModal';

import { SBSMedia } from '_types';
import {
    selectEncoders,
    selectThumbnails,
} from 'features/EncoderManagement/EncoderManagementSlice';
import { EVENT_FILTERING_PROPERTIES } from 'utils/TableProperties/TableFiltering';
import { DEFAULT_I18N_STRINGS, I18N_STRINGS } from 'utils/I18NStrings/TableStrings';

import { COLUMN_DEFINITIONS } from './TableColumns';
import { EVENT_CARD_DEFINITION, CARD_PROPERTIES } from './Cards';
import { CUSTOM_HEADER } from './Header';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { Snackbar, Alert } from '@mui/material';
import SlateTransitionModal from 'components/Common/SlateTransitionModal';

const EventDashboard: React.FC<EventDashboardProps> = ({
    setModalVisible,
    setCurrentDetails,
    setScheduleModalVisible,
}) => {
    const events = useAppSelector(selectEvents);
    const [sortedEvents, setSortedEvents] = useState([...events]);
    const encoders = useAppSelector(selectEncoders);
    const [viewType, setViewType] = useState<string>('');
    const [itemDelete, setItemDelete] = useState();
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [transitionMetadata, setTransitionMetadata] = useState({visible: false, slateName: '', event: undefined});
    const [statusFilterOverride, setStatusFilterOverride] = useState<Array<OptionDefinition>>([]);
    const [loading, setLoading] = useState(true);
    const [sortingField, setSortingField] = useState(undefined);
    const [sortDescending, setSortDescending] = useState<boolean>(undefined);
    const [preferences, setPreferences] = useLocalStorage(
        'React-Cards-Preferences',
        DEFAULT_PREFERENCES
    );
    const [tableSelectedEvent, setTableSelectedEvent] = useState<SBSMedia.Event[]>();

    const [systemPendingEvents, setSystemPendingEvents] = useState<string[]>([]);

    const thumbnails = useAppSelector(selectThumbnails);

    const safeEvents = () => {
        if (statusFilterOverride.length > 0) {
            const availableStatuses = statusFilterOverride.map((selectedOption) => {
                return selectedOption.value;
            });
            return sortedEvents.filter((event) => {
                return availableStatuses.includes(event.eventState);
            });
        } else {
            if (events > sortedEvents) {
                return events;
            } else {
                return sortedEvents;
            }
        }
    };

    const sortEvents = (sortingField) => {
        let sortingProperty = 'createdAt';
        if (sortingField) {
            sortingProperty = sortingField.value;
        }
        let descendSort = true;
        if (sortDescending !== undefined) {
            descendSort = sortDescending;
        }
        const actuallySortedEvents = [...events].sort((eventA, eventB) => {
            if (eventA[sortingProperty] > eventB[sortingProperty]) {
                if (descendSort) {
                    return -1;
                } else {
                    return 1;
                }
            } else if (eventA[sortingProperty] < eventB[sortingProperty]) {
                if (descendSort) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                return 0;
            }
        });
        setSortedEvents(actuallySortedEvents);
    };

    const readableEvents = safeEvents().map((event) => {
        const primaryEncoder = encoders.filter((encoder) => {
            return encoder.EncoderId == event.primaryEncoder;
        })[0];
        const backupEncoder = encoders.filter((encoder) => {
            return encoder.EncoderId == event.backupEncoder;
        })[0];
        const readableEvent = {
            ...event,
            primaryEncoderName: primaryEncoder ? primaryEncoder.name : 'Encoder Deleted',
            primaryEncoderStatus: primaryEncoder ? primaryEncoder.ConnectionState : 'disconnected',
            backupEncoderName: backupEncoder ? backupEncoder.name : 'Encoder Deleted',
            backupEncoderStatus: backupEncoder ? backupEncoder.ConnectionState : 'disconnected',
            readableCreatedTime: convertStringEpochToDate(event.createdAt || 'DNE'),
            destinationList: Object.keys(event.destinations),
        };

        return readableEvent as SBSMedia.Event;
    });

    const { items, collectionProps, paginationProps, propertyFilterProps } = useCollection(
        [...readableEvents],

        {
            propertyFiltering: {
                filteringProperties: EVENT_FILTERING_PROPERTIES,
                empty: <></>,
                noMatch: <p>No match identified</p>,
                defaultQuery: preferences.custom.defaultFilter,
            },
            pagination: { pageSize: preferences.pageSize },
            selection: {},
            sorting: {},
        }
    );

    useEffect(() => {
        setLoading(false);
        setViewType(preferences.custom.defaultView);
    }, []);

    useEffect(() => {
        sortEvents(sortingField);
    }, [sortDescending, events, sortingField]);

    const customPreferences = (
        <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            disabled={loading}
            preferences={preferences}
            onConfirm={({ detail }) => {
                setPreferences(detail);
            }}
            pageSizePreference={{
                title: 'Page size',
                options: PAGE_SIZE_OPTIONS,
            }}
            customPreference={(customPreferences, setCustomValue) => {
                return (
                    <SpaceBetween size="m">
                        <FormField label="Default view">
                            <RadioGroup
                                value={customPreferences?.defaultView}
                                onChange={({ detail }) => {
                                    setCustomValue({
                                        ...customPreferences,
                                        defaultView: detail.value,
                                    });
                                }}
                                items={[
                                    { label: 'Card view', value: 'Card view' },
                                    { label: 'Table view', value: 'Table view' },
                                ]}
                            />
                        </FormField>
                        <FormField label="Data settings">
                            <Toggle
                                onChange={({ detail }) => {
                                    setCustomValue({
                                        ...customPreferences,
                                        showRemovedEvents: detail.checked,
                                    });
                                }}
                                checked={customPreferences?.showRemovedEvents}>
                                Show removed events
                            </Toggle>
                        </FormField>
                        <FormField label="Default filters">
                            {defaultFilter((detail) => {
                                setCustomValue({ ...customPreferences, defaultFilter: detail });
                            })}
                        </FormField>
                    </SpaceBetween>
                );
            }}
        />
    );
    const customFilter = (
        <ColumnLayout columns={2}>
            <PropertyFilter
                {...propertyFilterProps}
                i18nStrings={I18N_STRINGS}
                disabled={loading}
            />
            <ColumnLayout columns={2}>
                <ColumnLayout columns={1}>
                    <Select
                        disabled={viewType === 'Table view'}
                        selectedOption={sortingField}
                        placeholder="Sort by property"
                        onChange={({ detail }) => {
                            if (sortDescending === undefined) {
                                setSortDescending(false);
                            }
                            setSortingField(detail.selectedOption);
                        }}
                        options={CARD_PROPERTIES}
                    />
                    {sortingField === undefined ? (
                        <></>
                    ) : (
                        <Toggle
                            disabled={viewType === 'Table view'}
                            checked={sortDescending}
                            onChange={({ detail }) => {
                                setSortDescending(detail.checked);
                            }}>
                            Descending
                        </Toggle>
                    )}
                </ColumnLayout>

                <Multiselect
                    selectedOptions={statusFilterOverride}
                    onChange={({ detail }) => setStatusFilterOverride([...detail.selectedOptions])}
                    options={[
                        {
                            label: 'Streaming',
                            value: 'LIVE',
                            description: 'Event streaming',
                        },
                        // {
                        //     label: 'Scheduled',
                        //     value: 'SCHEDULED',
                        //     description: 'Event calandered',
                        // },
                        {
                            label: 'Configured',
                            value: 'CONDIGURED',
                            description: 'Event data entered',
                        },
                        {
                            label: 'Exception',
                            value: 'EXCEPTION',
                            description: 'Event data invalid',
                        },
                        {
                            label: 'Ready',
                            value: 'READY',
                            description: 'System ready to stream',
                        },
                        {
                            label: 'Offline',
                            value: 'OFFLINE',
                            description: 'Encoder is not connected',
                        },
                        {
                            label: 'Completed',
                            value: 'COMPLETED',
                            description: 'Event stream completed',
                        },
                        {
                            label: 'Pending',
                            value: 'PENDING',
                            description: 'System being configured',
                        },
                        {
                            label: 'Starting',
                            value: 'STARTING',
                            description: 'Event stream starting',
                        },
                        {
                            label: 'Stopping',
                            value: 'STOPPING',
                            description: 'Event stream stopping',
                        },
                    ]}
                    placeholder="Filter event status"
                />
            </ColumnLayout>
        </ColumnLayout>
    );

    const defaultFilter = (changeTempState) => {
        return (
            <PropertyFilter
                {...propertyFilterProps}
                i18nStrings={DEFAULT_I18N_STRINGS}
                onChange={(event) => {
                    propertyFilterProps.onChange(event);
                    changeTempState(event.detail);
                }}
            />
        );
    };

    return (
        <div>
            {viewType === 'Card view' ? (
                <>
                    <Cards
                        {...collectionProps}
                        cardDefinition={EVENT_CARD_DEFINITION(
                            setCurrentDetails,
                            encoders,
                            setModalVisible,
                            setItemDelete,
                            setDeleteVisible,
                            setScheduleModalVisible,
                            systemPendingEvents,
                            setSystemPendingEvents,
                            setTransitionMetadata,
                            thumbnails
                        )}
                        loading={loading}
                        loadingText="Loading events"
                        items={items} // this was set to readableEvents which it should not have been
                        variant="full-page"
                        header={CUSTOM_HEADER(
                            events,
                            setItemDelete,
                            setDeleteVisible,
                            setViewType,
                            viewType,
                            tableSelectedEvent,
                            setTableSelectedEvent,
                            setCurrentDetails,
                            setModalVisible,
                            systemPendingEvents,
                            setSystemPendingEvents,
                            setTransitionMetadata
                        )}
                        filter={customFilter}
                        pagination={<Pagination {...paginationProps} disabled={loading} />}
                        preferences={customPreferences}
                        trackBy={'EventId'}
                        cardsPerRow={[
                            { cards: 1 },
                            { minWidth: 900, cards: 2 },
                            { minWidth: 1300, cards: 3 },
                        ]}
                    />
                </>
            ) : (
                <></>
            )}
            {viewType === 'Table view' ? (
                <Table
                    {...collectionProps}
                    trackBy={'EventId'}
                    selectionType="single"
                    onSelectionChange={({ detail }) => {
                        setTableSelectedEvent(detail.selectedItems);
                    }}
                    selectedItems={tableSelectedEvent}
                    columnDefinitions={COLUMN_DEFINITIONS(encoders, thumbnails) as []}
                    items={items}
                    variant="full-page"
                    stickyHeader={true}
                    header={CUSTOM_HEADER(
                        events,
                        setItemDelete,
                        setDeleteVisible,
                        setViewType,
                        viewType,
                        tableSelectedEvent,
                        setTableSelectedEvent,
                        setCurrentDetails,
                        setModalVisible,
                        systemPendingEvents,
                        setSystemPendingEvents,
                        setTransitionMetadata,
                    )}
                    filter={customFilter}
                    pagination={<Pagination {...paginationProps} />}
                    preferences={customPreferences}
                />
            ) : (
                <></>
            )}
            {deleteVisible && (
                <DeleteModal
                    itemDelete={itemDelete}
                    deleteVisible={deleteVisible}
                    setDeleteVisible={setDeleteVisible}
                    deleteType="event"
                />
            )}
            {transitionMetadata.visible && (
                <SlateTransitionModal
                    
                    transitionMetadata={transitionMetadata}
                    setTransitionMetadata={setTransitionMetadata}
                    
                />
            )}
            {/* <Snackbar
                open={true}
                autoHideDuration={6000}
                anchorOrigin={{horizontal: "center", vertical: "top"}}
                message={"Unable to switch"}
            /> */}
        </div>
    );
};

export default EventDashboard;

interface EventDashboardProps {
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    setScheduleModalVisible: Dispatch<SetStateAction<boolean>>;
    setCurrentDetails: Dispatch<SetStateAction<SBSMedia.Event>>;
}
