export function onBlurSubmitValues(
    localEventForm,
    setLocalEventForm,
    destination,
    localProfileFormInline
) {
    if (localEventForm) {
        setLocalEventForm({
            ...localEventForm,
            ['destinations']: {
                ...localEventForm.destinations,
                [destination]: {
                    ...localEventForm.destinations[destination],
                    ['profile']: {
                        ...localEventForm.destinations[destination]['profile'],
                        ['override']: {
                            ...localEventForm.destinations[destination]['profile'].override,
                            ...localProfileFormInline,
                        },
                    },
                },
            },
        });
    }
}

export function onChangeSubmitValue(
    localProfileForm,
    setLocalProfileForm,
    e,
    detail,
    setLocalProfileFormInline,
    localProfileFormInline
) {
    if (localProfileForm) {
        setLocalProfileForm({
            ...localProfileForm,
            [e.attribute]: detail.value.toString() || ' ',
        });
    } else {
        setLocalProfileFormInline({
            ...localProfileFormInline,
            [e.attribute]: detail.value.toString() || ' ',
        });
    }
}
