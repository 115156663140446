// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';

import './dashboardHeader.scss';
import { Header } from '@cloudscape-design/components';

interface DashboardHeaderProps {
    title: string;
    actions?: React.ReactNode;
}
const DashboardHeader: React.FC<DashboardHeaderProps> = ({ actions, title }) => (
    <Header variant="h1" actions={actions}>
        {title}
    </Header>
);

export default DashboardHeader;
