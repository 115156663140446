// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { useEffect, useState } from 'react';
import { Amplify } from '@aws-amplify/core';

import ProtectedRoute from './ProtectedRoute';
import Home from './routes/Home';
import Login from './routes/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@cloudscape-design/global-styles/index.css';

import './App.css';
import { AppLayout } from '@cloudscape-design/components';
import NavigationBar from 'components/Common/NavigatorBar';
import Header from 'components/Header';
import Encoder from 'routes/Encoders';
import Events from 'routes/Events';

import Settings from 'routes/Settings';
import { useAppDispatch } from 'app/hooks';
import { getEventsAsync } from 'features/EventManagement/EventManagementSlice';
import {
    getEncoderThumbnail,
    getEncoders,
    getProfiles,
} from 'features/EncoderManagement/EncoderManagementSlice';
import { Auth } from 'aws-amplify';

const API_DATA_CALL_INTERVAL_MS = 10000;

// TODO: Replace with customer auth flow for delivery
Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            redirectSignIn: process.env.REACT_APP_SIGNIN_URL,
            scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            responseType: 'code',
            redirectSignOut: process.env.REACT_APP_SIGNOUT_URL,
        },
    },
    API: {
        endpoints: [
            {
                name: 'SBS',
                endpoint: process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT,
            },
        ],
    },
});

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<ProtectedRoute />}>
                <Route path="/" element={<Events />} />
                <Route path="/dashboard" element={<Home />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/encoder" element={<Encoder />} />
                <Route path="/events" element={<Events />} />
                <Route path="*" element={<Home />}></Route>
            </Route>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/player/:id" element={<FullPlayer />} /> */}
        </Routes>
    );
}

function App() {
    const dispatch = useAppDispatch();
    const [curUser, setCurUser] = useState(undefined);
    useEffect(() => {
        //know issue caused by adding the Modal cancell: https://cloudscape.aws.dev/versions/from-v21-to-v30/known-migration-issues/
        const errorHandler = (e) => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                e.stopPropagation();
                e.preventDefault();
            }
        };

        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    });
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                setCurUser(user);
                console.log(user);
                localStorage.setItem('idToken', user.signInUserSession.idToken.jwtToken);
                Auth.currentCredentials()
                    .then(() => {
                        dispatch(getEventsAsync());
                        dispatch(getEncoders());
                        dispatch(getProfiles());
                        setInterval(() => {
                            dispatch(getEventsAsync() as any);
                            dispatch(getEncoders() as any).then((e) => {
                                e.payload?.forEach((encoder) => {
                                    if (encoder.enc_type !== 'live') {
                                        // we cant get it on lives anyways so dont bother
                                        dispatch(getEncoderThumbnail(encoder.EncoderId));
                                    }
                                });
                            });
                        }, API_DATA_CALL_INTERVAL_MS);
                    })
                    .catch((error) => {
                        console.log('Error retrieving credentials:', error);
                    });
            })
            .catch(() => {
                setCurUser(undefined);
            });
    }, []);

    return (
        <div className="App">
            <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1001 }}>
                <Header curUser={curUser} />
            </div>

            <Router>
                <AppLayout
                    content={<AppRoutes />}
                    navigation={<NavigationBar />}
                    headerSelector={'#h'}
                    toolsHide
                />
            </Router>
        </div>
    );
}

export default App;
