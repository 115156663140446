// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction, useState } from 'react';

import './encoderCard.scss';
import {
    Box,
    Button,
    Cards,
    PropertyFilter,
    StatusIndicator,
    Popover,
} from '@cloudscape-design/components';

import { useAppSelector } from 'app/hooks';
import {
    selectEncoders,
    selectThumbnails,
} from 'features/EncoderManagement/EncoderManagementSlice';
import DeleteModal from 'components/Common/DeleteModal/DeleteModal';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { I18N_STRINGS } from 'utils/I18NStrings/TableStrings';
import { ENCODER_FILTERING_PROPERTIES } from 'utils/TableProperties/EncoderFiltering';
import { CUSTOM_HEADER } from './Header';
import { SBSMedia } from '_types';

interface EncoderCardProps {
    setVisible: Dispatch<SetStateAction<boolean>>;
    setEncoderInfo: Dispatch<SetStateAction<SBSMedia.Encoder>>;
    setUploadVisible: Dispatch<SetStateAction<boolean>>;
}
const EncoderCard: React.FC<EncoderCardProps> = ({
    setVisible,
    setEncoderInfo,
    setUploadVisible,
}) => {
    const encoderCardList = useAppSelector(selectEncoders);
    const [itemDelete, setItemDelete] = useState({});
    const [deleteVisible, setDeleteVisible] = useState(false);

    const thumbnails = useAppSelector(selectThumbnails);

    const { items, propertyFilterProps } = useCollection(
        [...encoderCardList].sort((encoderA, encoderB) => {
            const connectionA = encoderA.ConnectionState;
            const connectionB = encoderB.ConnectionState;

            if (connectionA < connectionB) {
                return -1;
            }
            if (connectionA > connectionB) {
                return 1;
            }
            return 0;
        }),
        {
            propertyFiltering: {
                filteringProperties: ENCODER_FILTERING_PROPERTIES,
                empty: <p>empty</p>,
                noMatch: <></>,
            },
        }
    );

    const customFilter = <PropertyFilter {...propertyFilterProps} i18nStrings={I18N_STRINGS} />;

    return (
        <>
            <Cards
                cardDefinition={{
                    header: (e) => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                            }}
                            lang="en">
                            <span
                                style={{
                                    hyphens: 'auto',
                                    width: '63%',
                                }}>
                                {e.name}
                            </span>
                            <Button
                                iconName="edit"
                                variant="icon"
                                onClick={() => {
                                    setEncoderInfo(e);
                                    setVisible(true);
                                }}
                            />{' '}
                            {e.enc_type == 'live' ? (
                                <Button
                                    iconName="remove"
                                    variant="icon"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setItemDelete(e);
                                        setDeleteVisible(true);
                                    }}
                                />
                            ) : (
                                <></>
                            )}{' '}
                        </div>
                    ),

                    sections: [
                        { id: 'hr', width: 100, content: () => <hr></hr> },
                        {
                            id: 'region',
                            header: 'Location',
                            content: (e) => e.region,
                            width: 50,
                        },
                        {
                            id: 'encoding_type',
                            header: 'Encoding Type',
                            content: (e) => e.enc_type,
                            width: 50,
                        },
                        {
                            id: 'state',
                            header: 'Connection State',
                            content: (e) => (
                                <StatusIndicator
                                    type={
                                        e.ConnectionState == 'disconnected' ? 'error' : 'success'
                                    }>
                                    {e.ConnectionState}
                                </StatusIndicator>
                            ),
                            width: 60,
                        },
                        {
                            id: 'thumbnail',
                            width: 40,

                            content: (e) => {
                                if (e.enc_type !== 'live') {
                                    return (
                                        <div>
                                            <Popover
                                                dismissButton={false}
                                                position="top"
                                                size="large"
                                                triggerType="custom"
                                                content={
                                                    thumbnails[e.EncoderId] ? (
                                                        <img
                                                            src={`data:image/jpeg;base64,${
                                                                thumbnails[e.EncoderId]
                                                            }`}
                                                            alt="Image"
                                                        />
                                                    ) : (
                                                        <div>Loading...</div>
                                                    )
                                                }>
                                                <div>&nbsp;</div>
                                                <Button variant="inline-link" iconName="external">
                                                    Input Preview
                                                </Button>
                                            </Popover>
                                        </div>
                                    );
                                }
                            },
                        },
                        {
                            id: 'activeInput',
                            header: 'Active Input',
                            content: (e) =>
                                e.deviceSettings && e.deviceSettings.ActiveInput
                                    ? e.deviceSettings.ActiveInput
                                    : '-',
                            width: 50,
                        },
                        {
                            id: 'framerate',
                            header: 'Framerate',
                            content: (e) =>
                                e.deviceSettings && e.deviceSettings.Framerate
                                    ? e.deviceSettings.Framerate
                                    : '-',
                            width: 50,
                        },
                        {
                            id: 'arn',

                            content: (e) => {
                                return (
                                    <>
                                        <h4 style={{ margin: '0px' }}> {e.arn ? 'ARN' : 'IP'}</h4>{' '}
                                        {e.arn || e.ip}{' '}
                                    </>
                                );
                            },
                            width: 100,
                        },
                    ],
                }}
                cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 600, cards: 2 },
                    { minWidth: 900, cards: 3 },
                    { minWidth: 1200, cards: 4 },
                ]}
                items={items}
                filter={customFilter}
                variant="full-page"
                header={CUSTOM_HEADER([...items], setVisible, setUploadVisible, setEncoderInfo)}
                stickyHeader={true}
                loadingText="Loading resources"
                //trackBy="name"
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No resources</b>
                        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                            No resources to display.
                        </Box>
                        <Button>Create resource</Button>
                    </Box>
                }
            />
            <DeleteModal
                itemDelete={itemDelete}
                deleteVisible={deleteVisible}
                setDeleteVisible={setDeleteVisible}
                deleteType="encoder"
            />
        </>
    );
};

export default EncoderCard;
