// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';
import { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import SideNavigation from '@cloudscape-design/components/side-navigation';
import { useNavigate } from 'react-router-dom';

const NavigationBar: React.FC = () => {
    const [activeHref, setActiveHref] = React.useState(window.location.pathname);
    const navigate = useNavigate();
    const navItems = [
        { type: 'link', text: 'Events', href: '/events' },
        { type: 'link', text: 'Encoders', href: '/encoder' },
        { type: 'link', text: 'Dashboard', href: '/dashboard' },
        { type: 'link', text: 'Settings', href: '/settings' },
    ];

    return (
        <SideNavigation
            activeHref={activeHref}
            onFollow={(event) => {
                if (!event.detail.external) {
                    event.preventDefault();
                    setActiveHref(event.detail.href);
                    navigate(event.detail.href);
                }
            }}
            items={navItems as SideNavigationProps.Item[]}
            // header={{ href: '/dashboard', text: 'Home' }}
        />
    );
};

export default NavigationBar;
