// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Button, Modal, SpaceBetween } from '@cloudscape-design/components';

import { useAppDispatch } from 'app/hooks';

import { SBSMedia } from '_types';
import EditProfileInline from 'components/Events/EditProfileInline/EditProfileInline';
import { postProfileAsync } from 'features/SettingsManagement/SettingsManagementSlice';
import { getProfiles } from 'features/EncoderManagement/EncoderManagementSlice';

interface ModuleProps {
    setVisible: Dispatch<SetStateAction<boolean>>;
    visible: boolean;
    currentDetails: SBSMedia.Profile;
    isNew: boolean;
}

const EditProfile: React.FC<ModuleProps> = ({ setVisible, visible, currentDetails, isNew }) => {
    const [localProfileForm, setLocalProfileForm] = useState<SBSMedia.Profile>(
        {} as SBSMedia.Profile
    );

    const dispatch = useAppDispatch();
    useEffect(() => {
        setLocalProfileForm(currentDetails);
    }, [currentDetails]);
    return (
        <Modal
            onDismiss={(e) => {
                if (e.detail.reason !== 'overlay') {
                    setVisible(false);
                    setLocalProfileForm({} as SBSMedia.Profile);
                }
            }}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => {
                                setLocalProfileForm({ name: '' } as SBSMedia.Profile);
                                setVisible(false);
                            }}>
                            Cancel
                        </Button>
                        <Button
                            disabled={!localProfileForm['name'] || localProfileForm['name'] === ''}
                            variant="primary"
                            onClick={() => {
                                const profileObject = localProfileForm;
                                if (isNew && 'ProfileId' in profileObject) {
                                    delete profileObject['ProfileId'];
                                }
                                dispatch(postProfileAsync(localProfileForm)).then(() => {
                                    dispatch(getProfiles()).then(() => {
                                        setLocalProfileForm({} as SBSMedia.Profile);
                                        setVisible(false);
                                    });
                                });
                            }}>
                            {isNew ? 'Add' : 'Update'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={isNew ? 'Add Profile' : 'Edit Profile'}>
            <br />
            <EditProfileInline
                currentDetails={currentDetails}
                localEventForm={undefined}
                setLocalEventForm={undefined}
                setLocalProfileForm={setLocalProfileForm}
                localProfileForm={localProfileForm}
                destination={undefined}
            />
        </Modal>
    );
};

export default EditProfile;
