// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction, useState } from 'react';

import {
    Box,
    Button,
    Modal,
    SpaceBetween,
    FormField,
    Input,
    Select,
    SelectProps,
} from '@cloudscape-design/components';

import { useAppDispatch } from 'app/hooks';
import { deleteEncoderAsync, getEncoders } from 'features/EncoderManagement/EncoderManagementSlice';
import { deleteEventsAsync, getEventsAsync } from 'features/EventManagement/EventManagementSlice';
import { sanitizeInput } from 'utils/common';

interface ModuleProps {
    itemDelete: any;
    deleteVisible: boolean;
    setDeleteVisible: Dispatch<SetStateAction<boolean>>;
    deleteType: string;
}

const DeleteModal: React.FC<ModuleProps> = ({
    itemDelete,
    deleteVisible,
    setDeleteVisible,
    deleteType,
}) => {
    const dispatch = useAppDispatch();
    const [inputDeleteConf, setInputDeleteConf] = useState<string>();
    const [reason, setReason] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [removalItem, setRemovalItem] = useState<SelectProps.Option>({ value: '', label: '' });
    const safeClose = () => {
        setDeleteVisible(!deleteVisible);
        setInputDeleteConf('');
        setReason('');
        setIsError(false);
    };

    return (
        <Modal
            onDismiss={(e) => {
                if (e.detail.reason !== 'overlay') {
                    setDeleteVisible(false);
                    setInputDeleteConf('');
                    setIsError(false);
                }
            }}
            visible={deleteVisible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => {
                                safeClose();
                            }}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                {
                                    if (deleteType == 'encoder') {
                                        if (itemDelete.name == inputDeleteConf) {
                                            dispatch(
                                                deleteEncoderAsync({
                                                    'encoderId': itemDelete.EncoderId,
                                                })
                                            ).then(() => {
                                                dispatch(getEncoders());
                                                safeClose();
                                            });
                                        } else {
                                            setIsError(true);
                                        }
                                    } else if (deleteType == 'event') {
                                        dispatch(
                                            deleteEventsAsync({
                                                'EventId': itemDelete.EventId,
                                                'reasonForDeletion': '',
                                            })
                                        ).then(() => {
                                            dispatch(getEventsAsync());
                                            safeClose();
                                        });
                                    }
                                }
                            }}>
                            Remove
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Remove`}>
            {deleteType == 'encoder'
                ? 'Typing ' + itemDelete.name + ' will delete the encoder'
                : 'Are you sure you want to remove the following event: ' +
                  itemDelete.eventName +
                  '?'}

            <br />
            {deleteType == 'Encoder' ? (
                <FormField errorText={isError ? 'Input does not match item title!' : null}>
                    <Input
                        onChange={({ detail }) => {
                            setInputDeleteConf(detail.value);
                            setIsError(false);
                        }}
                        value={inputDeleteConf}
                        placeholder="Enter Encoder Name Here"
                    />
                </FormField>
            ) : null}
            <br />
            {/* Allows for Reason for Removal  */}
            {/* {deleteType == 'event' && (
                <>
                    <FormField label="Reason for Removal">
                        <Select
                            onChange={({ detail }) => setRemovalItem(detail.selectedOption)}
                            selectedOption={removalItem}
                            options={[
                                { value: '1', label: 'Event Completed' },
                                { value: '2', label: 'Error Creating Event' },
                                { value: '3', label: 'Event Cancelled' },
                                { value: '4', label: 'Other' },
                            ]}
                        />
                    </FormField>
                    <br />
                    {removalItem.value == '4' && (
                        <FormField description="Please add for other">
                            <Input
                                onChange={({ detail }) => {
                                    setReason(sanitizeInput(detail.value));
                                    setIsError(false);
                                }}
                                value={reason}
                                placeholder="Enter reason here"
                            />
                        </FormField>
                    )}
                </>
            )} */}
        </Modal>
    );
};

export default DeleteModal;
