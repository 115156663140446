// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';

const ProtectedRoute: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => {
                setIsAuthenticated(true);
                return userData;
            })
            .catch(() => {
                console.log('Not signed in; redirecting');
                return Auth.federatedSignIn();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser().then((userData) => {
                        setUser(userData);
                        setIsAuthenticated(true);
                    });

                    break;
                case 'signOut':
                    setUser(null);
                    // localStorage.removeItem('curEstimate');
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    setIsAuthenticated(false);
                    console.log('Sign in failure', data);
                    break;
            }
        });

        getUser().then((userData) => setUser(userData));
    }, []);

    if (loading) {
        return <div>Loading...</div>; // or your custom loading component
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/dashboard" />;
};

export default ProtectedRoute;
