// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';

import { displayPairs } from 'utils/common';
import DestinationPopover from './DestinationPopover';

import {
    renderStatusCell,
    renderLocationCell,
    renderPreviewCell,
    renderIFrameCell,
    renderEventIdCell,
    renderTaskIdCell,
    renderStateName,
} from './CommonCells';

export const COLUMN_DEFINITIONS = (encoders, thumbnails) => {
    return [
        {
            id: 'name',
            header: 'Name',
            cell: (e) => e.eventName,
            sortingField: 'eventName',
            isRowHeader: true,
        },
        {
            id: 'status',
            header: 'Status',
            cell: (e) => renderStatusCell(e),
            sortingField: 'eventState',
        },
        {
            id: 'inputState',
            header: 'State',
            cell: (e) => renderStateName(e.inputState),
            sortingField: 'inputState',
        },
        // Uncomment when Schedule functionality is re-implemented
        // {
        //     id: 'date',
        //     header: 'Start Date and Time',
        //     cell: (e) => convertStringEpochToDate(e.startTime || 'DNE') || '',
        //     sortingField: 'startTime',
        // },

        {
            id: 'creator',
            header: 'Creator',
            cell: (e) => e.creator,
            sortingField: 'creator',
        },
        {
            id: 'inputType',
            header: 'Input Type and Information',
            cell: (e) => displayPairs(e, encoders, true, thumbnails),
            sortingField: 'inputType',
        },
        {
            id: 'location',
            header: 'S3 Location',
            cell: (e) => renderLocationCell(e),
        },
        {
            id: 'preview',
            header: 'Preview',
            minWidth: 120,
            cell: (e) => renderPreviewCell(e),
        },
        {
            id: 'iFrame',
            header: 'iFrame',
            minWidth: 120,
            cell: (e) => renderIFrameCell(e),
        },
        {
            id: 'eventId',
            header: 'Event ID',
            cell: (e) => renderEventIdCell(e),
            sortingField: 'EventId',
        },
        {
            id: 'taskID',
            header: 'Task ID',
            cell: (e) => renderTaskIdCell(e),
            sortingField: 'taskNumber',
        },

        {
            id: 'destinations',
            header: 'Destinations',
            cell: (e) => <DestinationPopover e={e} />,
        },
    ];
};
