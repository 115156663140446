// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
import { FormField, Input, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { setErrorText } from '../../helperFunctions';

import { CustomSubFormProps } from '../../AddEvent';
import { useAppSelector } from 'app/hooks';
import { selectS3Input } from 'features/SettingsManagement/SettingsManagementSlice';

export const FileSourceForm: React.FC<CustomSubFormProps> = ({
    didSubmit,
    localForm,
    setLocalForm,
}) => {
    const defaultSource = useAppSelector(selectS3Input);
    useEffect(() => {
        if (!localForm.fileInput) {
            setLocalForm({ ...localForm, fileInput: defaultSource });
        }
    }, []);
    return (
        <SpaceBetween direction="vertical" size="m">
            <FormField
                label="S3 Location URL"
                description="Required"
                errorText={setErrorText(didSubmit, localForm, {
                    attribute: 'fileInput',
                    attribute2: undefined,
                    attribute3: undefined,
                    isS3: true,
                })}>
                <Input
                    onChange={({ detail }) => {
                        if (detail.value.includes('s3ssl://')) {
                            setLocalForm({
                                ...localForm,
                                fileInput: detail.value,
                            });
                        }
                    }}
                    value={
                        localForm.fileInput
                            ? 's3ssl://' + localForm.fileInput.split('s3ssl://')?.[1]
                            : defaultSource
                    }
                />
            </FormField>
        </SpaceBetween>
    );
};
