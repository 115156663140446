// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
import {
    Autosuggest,
    AutosuggestProps,
    FormField,
    Grid,
    Input,
    Select,
    SpaceBetween,
    Toggle,
} from '@cloudscape-design/components';
import React from 'react';
import { setErrorText } from '../../helperFunctions';

import { sanitizeInput } from 'utils/common';
import { CustomSubFormProps } from '../../AddEvent';
import { GRID_DEFINTION_COLSPAN_6, SRT_REGIONS } from 'utils/constants';
import { BackupFieldsSrtIngestForm } from './BackupFieldsSrtIngestForm';
import { PassphraseForm } from '../PassphraseForm';

export const SrtIngestForm: React.FC<CustomSubFormProps> = ({
    didSubmit,
    localForm,
    setLocalForm,
}) => {
    return (
        <SpaceBetween direction="vertical" size="m">
            <FormField
                label="Region"
                description="Required"
                errorText={
                    localForm.inputType == 'SRT Ingest' &&
                    setErrorText(didSubmit, localForm, { attribute: 'srtRegion' })
                }>
                <Autosuggest
                    value={localForm.srtRegion || ''}
                    onChange={({ detail }) => {
                        setLocalForm({ ...localForm, ['srtRegion']: detail.value });
                    }}
                    options={
                        SRT_REGIONS.map((region) => {
                            return { value: region };
                        }) as AutosuggestProps.Options
                    }
                    enteredTextLabel={(value) => `Use ${value}`}
                    ariaLabel="Autosuggest example with suggestions"
                    empty="No matches found"
                />
            </FormField>
            <FormField
                label="SRT Mode"
                description="Required"
                errorText={setErrorText(didSubmit, localForm, {
                    attribute: 'srtMode',
                })}>
                <Select
                    selectedOption={{ value: localForm.srtMode }}
                    options={[{ value: 'Caller' }, { value: 'Listener' }]}
                    onChange={({ detail }) => {
                        setLocalForm({
                            ...localForm,
                            srtMode: detail.selectedOption.value,
                        });
                    }}
                />
            </FormField>

            <Grid gridDefinition={GRID_DEFINTION_COLSPAN_6}>
                {localForm.srtMode === 'Listener' ? (
                    <FormField
                        label="Primary Allowlist CIDR Block"
                        description="Required"
                        errorText={setErrorText(didSubmit, localForm, {
                            attribute: 'PrimaryAllowlistCidr',
                        })}>
                        <Input
                            onChange={({ detail }) => {
                                setLocalForm({
                                    ...localForm,
                                    PrimaryAllowlistCidr: sanitizeInput(detail.value),
                                });
                            }}
                            value={localForm.PrimaryAllowlistCidr || ''}
                            placeholder="Ex: 0.0.0.0/0"
                        />
                    </FormField>
                ) : localForm.srtMode === 'Caller' ? (
                    <FormField
                        label="Primary Source IP"
                        description="Required"
                        errorText={setErrorText(didSubmit, localForm, {
                            attribute: 'primaryIp',
                        })}>
                        <Input
                            onChange={({ detail }) => {
                                setLocalForm({
                                    ...localForm,
                                    primaryIp: sanitizeInput(detail.value),
                                });
                            }}
                            value={localForm.primaryIp || ''}
                        />
                    </FormField>
                ) : (
                    <></>
                )}
                {localForm.srtMode && (
                    <FormField
                        label="Primary Port"
                        description="Required"
                        errorText={setErrorText(didSubmit, localForm, {
                            attribute: 'primarySRTPort',
                        })}>
                        <Input
                            type="number"
                            onChange={({ detail }) => {
                                setLocalForm({
                                    ...localForm,
                                    primarySRTPort: sanitizeInput(detail.value),
                                });
                            }}
                            value={
                                (localForm.primarySRTPort && localForm.primarySRTPort.toString()) ||
                                ''
                            }
                        />
                    </FormField>
                )}
            </Grid>

            {localForm.srtMode && (
                <>
                    <FormField
                        label="Latency (ms)"
                        description="Required"
                        errorText={setErrorText(didSubmit, localForm, {
                            attribute: 'latency',
                        })}>
                        <Input
                            type="number"
                            onChange={({ detail }) => {
                                setLocalForm({
                                    ...localForm,
                                    latency: parseInt(sanitizeInput(detail.value)),
                                });
                            }}
                            value={(localForm.latency && localForm.latency.toString()) || ''}
                        />
                    </FormField>
                    <FormField
                        label="Passphrase"
                        description="Optional and interchangeable. Leave the field empty for no encryption.">
                        <PassphraseForm
                            localForm={localForm}
                            shouldGenerateRandom={true}
                            setLocalForm={setLocalForm}
                            localPassphrasePath={localForm.passphrase}
                            passphraseType={'InputSRT'}
                        />
                    </FormField>

                    <FormField label="Backup Enabled">
                        <Toggle
                            onChange={({ detail }) =>
                                setLocalForm({ ...localForm, backupSRT: detail.checked })
                            }
                            checked={localForm.backupSRT}
                        />
                    </FormField>
                </>
            )}
            {localForm.backupSRT && (
                <BackupFieldsSrtIngestForm
                    didSubmit={didSubmit}
                    localForm={localForm}
                    setLocalForm={setLocalForm}
                />
            )}
        </SpaceBetween>
    );
};
