// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import './addEvent.scss';

import {
    SpaceBetween,
    Form,
    Button,
    FormField,
    Modal,
    Multiselect,
    ExpandableSection,
    Spinner,
    Toggle,
    Grid,
    Input,
} from '@cloudscape-design/components';
import { SBSMedia } from '_types';
import { useSelector } from 'react-redux';
import { selectEncoders, selectProfiles } from 'features/EncoderManagement/EncoderManagementSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Auth } from 'aws-amplify';

import { setErrorText, checkFormValid, removeExtraneousFieldsForm } from './helperFunctions';

import {
    addEventAsync,
    editEventAsync,
    getEventsAsync,
} from 'features/EventManagement/EventManagementSlice';

import { DESTINATION_PROPERTIES } from './AddEventConstants';

import EditProfileInline from 'components/Events/EditProfileInline/EditProfileInline';

import {
    selectS3Closing,
    selectS3Intermission,
    selectS3Opening,
    selectS3Output,
} from 'features/SettingsManagement/SettingsManagementSlice';
import { switchIcon } from 'utils/common';
import { MetadataForm } from './Subcomponents/MetadataForm';
import { FileSourceForm } from './Subcomponents/InputTypeForms/FileSourceForm';
import { RtmpIngestForm } from './Subcomponents/InputTypeForms/RtmpIngestForm';
import { ElementalLinkForm } from './Subcomponents/InputTypeForms/ElementalLinkForm';
import { ElementalLiveForm } from './Subcomponents/InputTypeForms/ElementalLiveForm';
import { SrtIngestForm } from './Subcomponents/InputTypeForms/SrtIngestForm';
import { DestinationProfileForm } from './Subcomponents/DestinationForms/DestinationProfiles';
import { DestinationPropertiesForm } from './Subcomponents/DestinationForms/DestinationProperties';
import { DestinationOutputSRTAdditionalFields } from './Subcomponents/DestinationForms/DestinationOutputSRTAdditionalFields';
import { DESTINATION_OPTIONS, GRID_DEFINTION_COLSPAN_6 } from 'utils/constants';

export interface CustomSubFormProps {
    didSubmit: boolean;
    localForm: SBSMedia.Event;
    setLocalForm: Dispatch<SetStateAction<SBSMedia.Event>>;
    encoders?: SBSMedia.Encoder[];
}

export interface DestinationForm extends CustomSubFormProps {
    destination: string;
    profiles?: any;
}

interface EventProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    currentDetails: SBSMedia.Event;
    setAlertText: Dispatch<SetStateAction<string>>;
}
const AddEventModule: React.FC<EventProps> = ({
    visible,
    setVisible,
    currentDetails,
    setAlertText,
}) => {
    const [localForm, setLocalForm] = useState<SBSMedia.Event>({} as SBSMedia.Event);
    const [didSubmit, setSubmit] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const dispatch = useAppDispatch();
    const encoders = useSelector(selectEncoders);
    const profiles = useSelector(selectProfiles);
    const [user, setUser] = useState<any>();
    const s3BucketOutput = useAppSelector(selectS3Output);
    const [defaultSlates, setDefaultSlate] = useState({
        Opening: useAppSelector(selectS3Opening),
        Closing: useAppSelector(selectS3Closing),
        Intermission: useAppSelector(selectS3Intermission),
    });

    useEffect(() => {
        if (Object.keys(currentDetails).length == 0) {
            setLocalForm({ ...localForm, inputType: SBSMedia.InputTypes.ELEMENTAL_LINK });
        } else {
            setLocalForm(currentDetails);
        }
        Auth.currentAuthenticatedUser()
            .then((e) => {
                setUser(e.attributes.email);
            })

            .catch((err) => {
                console.log(err);
            });
    }, [currentDetails]);

    function resetForm() {
        setVisible(false);
        setSubmit(false);
        setSubmitting(false);
        setLocalForm({} as SBSMedia.Event);
    }

    const propPack = {
        didSubmit: didSubmit,
        localForm: localForm,
        setLocalForm: setLocalForm,
    };

    return (
        <Modal
            onDismiss={(e) => {
                if (e.detail.reason !== 'overlay') {
                    resetForm();
                }
            }}
            visible={visible}
            closeAriaLabel="Close modal"
            header={
                currentDetails.eventName !== undefined
                    ? currentDetails.EventId !== undefined
                        ? 'Edit Event'
                        : 'Duplicate Event'
                    : 'Create New Event'
            }>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            formAction="none"
                            variant="link"
                            onClick={() => {
                                resetForm();
                            }}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={submitting}
                            onClick={() => {
                                const cleanFormCopy = removeExtraneousFieldsForm(localForm);

                                if (checkFormValid(cleanFormCopy)) {
                                    setSubmitting(true);
                                    cleanFormCopy['backupEncoder'] === 'None' &&
                                        delete cleanFormCopy['backupEncoder'];

                                    let dispatchAction = addEventAsync({
                                        ...cleanFormCopy,
                                        'createdAt': new Date().valueOf(),
                                        'creator': user,
                                    });
                                    if (currentDetails.eventName && currentDetails.EventId) {
                                        dispatchAction = editEventAsync({
                                            ...cleanFormCopy,
                                        });
                                    }
                                    dispatch(dispatchAction)
                                        .then((e) => {
                                            if (e.meta.requestStatus === 'rejected') {
                                                setAlertText(
                                                    `Something went wrong saving your event.`
                                                );
                                                return false;
                                            } else {
                                                setAlertText(
                                                    `Event ${cleanFormCopy.eventName} saved successfully`
                                                );
                                            }
                                            dispatch(getEventsAsync()).then(() => {
                                                return true;
                                            });
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                            setAlertText(`Something went wrong saving your event.`);
                                            setSubmit(true);
                                        })
                                        .then(() => resetForm());
                                } else {
                                    setAlertText(`Something went wrong saving your event.`);
                                    setSubmit(true);
                                }
                            }}>
                            {submitting ? <Spinner /> : 'Save'}
                        </Button>
                    </SpaceBetween>
                }>
                <SpaceBetween direction="vertical" size="l">
                    <MetadataForm {...propPack} />
                    {localForm.inputType === SBSMedia.InputTypes.ELEMENTAL_LINK ? (
                        <ElementalLinkForm {...propPack} encoders={encoders} />
                    ) : localForm.inputType === SBSMedia.InputTypes.ELEMENTAL_LIVE ? (
                        <ElementalLiveForm {...propPack} encoders={encoders} />
                    ) : localForm.inputType === SBSMedia.InputTypes.SRT_INGEST ? (
                        <SrtIngestForm {...propPack} />
                    ) : localForm.inputType === SBSMedia.InputTypes.FILE ? (
                        <FileSourceForm {...propPack} />
                    ) : localForm.inputType === SBSMedia.InputTypes.RTMP ? (
                        <RtmpIngestForm {...propPack} />
                    ) : (
                        <></>
                    )}

                    <Grid gridDefinition={GRID_DEFINTION_COLSPAN_6}>
                        <FormField label="Closed Captions">
                            <Toggle
                                checked={localForm.closedCaptions}
                                onChange={({ detail }) => {
                                    setLocalForm({
                                        ...localForm,
                                        ['closedCaptions']: detail.checked,
                                    });
                                }}
                            />
                        </FormField>
                        <FormField label="Enable Slate">
                            <Toggle
                                checked={localForm.slateBoolean}
                                onChange={({ detail }) => {
                                    if (detail.checked) {
                                        setLocalForm({
                                            ...localForm,
                                            ['slateBoolean']: detail.checked,
                                            ['slateLocation']: {
                                                Opening: defaultSlates.Opening,
                                                Intermission: defaultSlates.Intermission,
                                                Closing: defaultSlates.Closing,
                                            },
                                        });
                                    } else {
                                        delete localForm['slateLocation'];
                                        setLocalForm({
                                            ...localForm,
                                            ['slateBoolean']: detail.checked,
                                        });
                                    }
                                }}
                            />
                        </FormField>
                    </Grid>
                    {localForm.slateBoolean &&
                        (
                            Object.keys(SBSMedia.SlateTypes) as Array<
                                keyof typeof SBSMedia.SlateTypes
                            >
                        ).map((e) => {
                            return (
                                <FormField
                                    label={SBSMedia.SlateTypes[e] + ' Slate'}
                                    key={e}
                                    errorText={
                                        e === 'OPENING' &&
                                        setErrorText(didSubmit, localForm, {
                                            attribute: 'slate',
                                        })
                                    }>
                                    <Input
                                        onChange={({ detail }) => {
                                            if (
                                                e === 'OPENING' &&
                                                detail.value.includes('s3ssl://')
                                            ) {
                                                setLocalForm({
                                                    ...localForm,
                                                    slateLocation: {
                                                        ...localForm.slateLocation,
                                                        [SBSMedia.SlateTypes[e]]: detail.value,
                                                    },
                                                });
                                            } else if (e == 'INTERMISSION' || e == 'CLOSING') {
                                                setLocalForm({
                                                    ...localForm,
                                                    slateLocation: {
                                                        ...localForm.slateLocation,
                                                        [SBSMedia.SlateTypes[e]]:
                                                            detail.value || ' ',
                                                    },
                                                });
                                            }
                                        }}
                                        value={
                                            localForm.slateLocation?.[SBSMedia.SlateTypes[e]] ===
                                            undefined
                                                ? ''
                                                : localForm.slateLocation?.[
                                                      SBSMedia.SlateTypes[e]
                                                  ] || defaultSlates[SBSMedia.SlateTypes[e]]
                                        }
                                    />
                                </FormField>
                            );
                        })}

                    <FormField
                        label="Destinations"
                        description="Required"
                        errorText={setErrorText(didSubmit, localForm, {
                            attribute: 'destinations',
                        })}>
                        <Multiselect
                            selectedOptions={
                                localForm['destinationInformation'] ||
                                (localForm['destinations'] &&
                                    Object.keys(localForm['destinations']).map((d) => {
                                        return {
                                            'label': d,
                                            'value': d,
                                            iconSvg: switchIcon(d, true),
                                        };
                                    }))
                            }
                            onChange={({ detail }) => {
                                const info = {};
                                detail.selectedOptions.map((e) => {
                                    info[e.value] = {};

                                    Object.keys(DESTINATION_PROPERTIES[e.value].properties).forEach(
                                        (property) => {
                                            info[e.value][property] =
                                                localForm.destinations &&
                                                Object.hasOwn(localForm.destinations, e.value)
                                                    ? localForm.destinations[e.value][property]
                                                    : property == 'primaryUrl' &&
                                                      e.value == 'Record'
                                                    ? s3BucketOutput
                                                    : '';
                                        }
                                    );
                                });

                                setLocalForm({
                                    ...localForm,
                                    destinationInformation: detail.selectedOptions,
                                    destinations: info,
                                });
                            }}
                            options={DESTINATION_OPTIONS}
                            placeholder="Choose destinations"
                        />
                    </FormField>

                    {localForm.destinations &&
                        Object.keys(localForm.destinations).map((destination) => {
                            const curProfileSelected =
                                destination != 'CloudFront'
                                    ? (profiles.filter((e) => {
                                          return (
                                              e.ProfileId ==
                                              localForm.destinations[destination].profile.value
                                          );
                                      })[0] as SBSMedia.Profile)
                                    : ({} as SBSMedia.Profile);

                            return (
                                <SpaceBetween direction="vertical" size="l" key={destination}>
                                    <hr />
                                    <h3>{destination}</h3>
                                    {destination === 'OutputSRT' ? (
                                        <>
                                            <DestinationOutputSRTAdditionalFields
                                                destination={destination}
                                                didSubmit={didSubmit}
                                                localForm={localForm}
                                                setLocalForm={setLocalForm}
                                            />
                                        </>
                                    ) : (
                                        <DestinationPropertiesForm
                                            destination={destination}
                                            didSubmit={didSubmit}
                                            localForm={localForm}
                                            setLocalForm={setLocalForm}
                                        />
                                    )}

                                    <DestinationProfileForm
                                        didSubmit={didSubmit}
                                        localForm={localForm}
                                        setLocalForm={setLocalForm}
                                        profiles={profiles}
                                        destination={destination}
                                    />

                                    {destination != 'CloudFront' ? (
                                        <ExpandableSection
                                            headerText={'Override ' + destination + 'Profile'}>
                                            <EditProfileInline
                                                localProfileForm={undefined}
                                                setLocalProfileForm={undefined}
                                                localEventForm={localForm}
                                                destination={destination}
                                                setLocalEventForm={setLocalForm}
                                                currentDetails={curProfileSelected}
                                                overrides={
                                                    localForm?.destinations?.[destination]?.profile
                                                        ?.override
                                                }
                                            />
                                        </ExpandableSection>
                                    ) : (
                                        <></>
                                    )}
                                </SpaceBetween>
                            );
                        })}
                </SpaceBetween>
            </Form>
        </Modal>
    );
};

export default AddEventModule;
