// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { useEffect } from 'react';

import './metricDashboard.scss';
import {
    Grid,
    Container,
    SpaceBetween,
    FormField,
    Multiselect,
    Checkbox,
    ColumnLayout,
    Button,
    Header,
    Popover,
} from '@cloudscape-design/components';

import MetricCards from '../MetricCard/MetricCard';

import { useLocalStorage } from 'utils/localStorage';

import { getEncoderMetricData } from 'features/MetricsManagement/MetricsManagementSlice';
import { useAppDispatch } from 'app/hooks';
import { useAppSelector } from 'app/hooks';
import { selectEncoders } from 'features/EncoderManagement/EncoderManagementSlice';
import { selectEvents } from 'features/EventManagement/EventManagementSlice';
import { selectMetrics } from 'features/MetricsManagement/MetricsManagementSlice';
import { ENCODER_METRICS_METADATA } from 'utils/constants';

const MetricDashboard: React.FC = () => {
    const [selectedFilters, setSelectedFilters] = useLocalStorage('Dashboard-Filters', {
        regions: [],
        encoders: [],
        events: [],
        charts: [],
        cardPositions: [],
    });

    const [dashboardPositions, setDashboardPositions] = useLocalStorage('Dashboard-Positions');

    const encoders = useAppSelector(selectEncoders);
    const events = useAppSelector(selectEvents);
    const metrics = useAppSelector(selectMetrics);

    const dispatch = useAppDispatch();
    useEffect(() => {
        selectedFilters.charts.forEach((chart) => {
            selectedFilters.encoders.forEach((encoder) => {
                const currentEncoderId = encoder.value;
                const currentEncoder = encoders.find(
                    (encoder) => encoder.EncoderId === currentEncoderId
                );
                if (
                    currentEncoder !== undefined && // dont call for data on a non existing encoder
                    currentEncoder.ConnectionState !== 'disconnected' && // dont call for data on a disconnected encoder
                    currentEncoder.input_id !== undefined // dont call for data on a non media live encoder (if it doesnt have an input idea used by media live)
                ) {
                    const encoderDataAlreadyExists = metrics.some(
                        (existingChart) =>
                            existingChart.metricName === chart &&
                            existingChart.encoders.some(
                                (existingEncoder) =>
                                    existingEncoder.EncoderId === currentEncoder.EncoderId
                            )
                    );
                    if (!encoderDataAlreadyExists) {
                        dispatch(
                            getEncoderMetricData({
                                metricName: chart,
                                period: 3600,
                                dimensions: {
                                    'DeviceType': currentEncoder.enc_type.toUpperCase(),
                                    'InputDeviceId': currentEncoder.input_id,
                                },
                                EncoderId: currentEncoder.EncoderId,
                            })
                        );
                    }
                }
            });
        });
    }, [selectedFilters, encoders]);

    const onEncoderSelectionUpdate = (updatedSelectedOptions) => {
        const updatedEvents = events.filter((event) => {
            let containsSelectedEncoder = false;
            updatedSelectedOptions.forEach((selectedEncoder) => {
                if (
                    event.primaryEncoder == selectedEncoder.value ||
                    event.backupEncoder == selectedEncoder.value
                ) {
                    containsSelectedEncoder = true;
                }
            });
            return containsSelectedEncoder;
        });
        setSelectedFilters({
            ...selectedFilters,
            encoders: updatedSelectedOptions,
            events: updatedEvents.map((event) => {
                return {
                    label: event.eventName,
                    value: event.EventId,
                };
            }),
        });
    };

    const onEventSelectionUpdate = (updatedSelectedOptions) => {
        const selectedEventIDs = updatedSelectedOptions.map((selectedEvent) => {
            return selectedEvent.value;
        });
        const selectedEncoderIDsFromEvents = new Set();
        events.forEach((event) => {
            if (selectedEventIDs.includes(event.EventId)) {
                selectedEncoderIDsFromEvents.add(event.primaryEncoder);
                selectedEncoderIDsFromEvents.add(event.backupEncoder);
            }
        });
        const selectedEncodersFromEvents = encoders.filter((encoder) => {
            return selectedEncoderIDsFromEvents.has(encoder.EncoderId);
        });

        setSelectedFilters({
            ...selectedFilters,
            events: updatedSelectedOptions,
            encoders: selectedEncodersFromEvents.map((encoder) => {
                return {
                    label: encoder ? encoder.name : '',
                    value: encoder.EncoderId,
                    description:
                        encoder.ConnectionState[0].toLocaleUpperCase() +
                        encoder.ConnectionState.slice(1) +
                        ' | ' +
                        encoder.region,
                };
            }),
        });
    };

    return (
        <>
            <Container>
                <Header
                    actions={
                        <Button
                            onClick={() => {
                                setSelectedFilters({
                                    regions: [],
                                    encoders: [],
                                    events: [],
                                    charts: [],
                                });
                                setDashboardPositions([]);
                            }}>
                            Clear Selection
                        </Button>
                    }>
                    Encoder Selection
                </Header>
                <SpaceBetween direction="vertical" size="l">
                    <Grid
                        gridDefinition={[
                            { colspan: { default: 6, xs: 4 } },
                            { colspan: { default: 6, xs: 4 } },
                            { colspan: { default: 6, xs: 4 } },
                        ]}>
                        <FormField label="Region">
                            <Multiselect
                                selectedOptions={selectedFilters.regions}
                                placeholder="Select regions"
                                onChange={({ detail }) => {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        regions: detail.selectedOptions,
                                    });
                                }}
                                options={[
                                    { label: 'North America - West', value: 'northAmericaWest' },
                                    { label: 'North America - East', value: 'northAmericaEast' },
                                ]}
                            />
                        </FormField>
                        <FormField label="Encoders">
                            <Multiselect
                                selectedOptions={selectedFilters.encoders}
                                placeholder="Select encoders"
                                onChange={({ detail }) => {
                                    onEncoderSelectionUpdate(detail.selectedOptions);
                                }}
                                options={encoders.map((encoder) => {
                                    return {
                                        label: encoder.name,
                                        value: encoder.EncoderId,
                                        disabled:
                                            encoder.ConnectionState === 'disconnected' ||
                                            encoder.input_id === undefined,
                                        description:
                                            encoder.ConnectionState[0].toLocaleUpperCase() +
                                            encoder.ConnectionState.slice(1) +
                                            ' | ' +
                                            encoder.region,
                                    };
                                })}
                            />
                        </FormField>
                        <FormField label="Events">
                            <Multiselect
                                selectedOptions={selectedFilters.events}
                                placeholder="Select events"
                                onChange={({ detail }) => {
                                    onEventSelectionUpdate(detail.selectedOptions);
                                }}
                                options={events.map((event) => {
                                    return {
                                        label: event.eventName,
                                        value: event.EventId,
                                    };
                                })}
                            />
                        </FormField>
                    </Grid>
                </SpaceBetween>
                <br />
                <FormField label="Visible Charts"></FormField>
                <SpaceBetween direction="horizontal" size="m">
                    <ColumnLayout columns={3}>
                        {ENCODER_METRICS_METADATA.map((chart) => {
                            return (
                                <SpaceBetween
                                    key={chart.name}
                                    direction="horizontal"
                                    size="xs"
                                    alignItems="start">
                                    <Checkbox
                                        onChange={({ detail }) => {
                                            setSelectedFilters({
                                                ...selectedFilters,
                                                ['charts']: detail.checked
                                                    ? [...selectedFilters.charts, chart.name]
                                                    : [
                                                          ...selectedFilters.charts,
                                                          chart.name,
                                                      ].filter((c) => c !== chart.name),
                                            });
                                        }}
                                        checked={selectedFilters.charts.includes(chart.name)}>
                                        {chart.displayName}
                                    </Checkbox>
                                    <Popover
                                        dismissButton={false}
                                        position="right"
                                        triggerType="custom"
                                        content={chart.info}>
                                        <Button iconName="status-info" variant="inline-icon" />
                                    </Popover>
                                </SpaceBetween>
                            );
                        })}
                    </ColumnLayout>
                </SpaceBetween>
            </Container>
            <br />
            <MetricCards
                visibleCharts={selectedFilters.charts}
                visibleEncoders={selectedFilters.encoders.map((encoder) => {
                    return encoder.value;
                })}
            />
        </>
    );
};

export default MetricDashboard;
