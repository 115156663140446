// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction } from 'react';

import './fileUploadModal.scss';
import { Box, Button, FileUpload, Modal, SpaceBetween } from '@cloudscape-design/components';
import { read, utils } from 'xlsx';
import { useAppDispatch } from 'app/hooks';
import { getEncoders, submitEncoder } from 'features/EncoderManagement/EncoderManagementSlice';
import { SBSMedia } from '_types';

interface FileUploadModalProps {
    setVisible: Dispatch<SetStateAction<boolean>>;
    visible: boolean;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({ setVisible, visible }) => {
    const [value, setValue] = React.useState([]);

    const dispatch = useAppDispatch();

    return (
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setVisible(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                const reader = new FileReader();

                                reader.onload = (e) => {
                                    const data = e.target.result;
                                    const workbook = read(data, { type: 'array' });

                                    const sheetName = workbook.SheetNames[0];
                                    const worksheet = workbook.Sheets[sheetName];
                                    const json = utils.sheet_to_json(worksheet);

                                    json.forEach((encoderItem) => {
                                        const tempItem = encoderItem as SBSMedia.EncoderProps;
                                        dispatch(
                                            submitEncoder({
                                                ...tempItem,
                                            })
                                        )
                                            .then(() => {
                                                dispatch(getEncoders());
                                            })
                                            .then(() => setVisible(!visible));
                                    });
                                };

                                reader.readAsArrayBuffer(value[0]);
                            }}>
                            Ok
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Upload Encoders">
            <SpaceBetween size="xl" direction="vertical">
                <Button variant="primary" href={'./EncoderUploadTemplate.xlsx'}>
                    Download Template
                </Button>
                <FileUpload
                    onChange={({ detail }) => setValue(detail.value)}
                    value={value}
                    i18nStrings={{
                        uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
                        dropzoneText: (e) => (e ? 'Drop files to upload' : 'Drop file to upload'),
                        removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                        limitShowFewer: 'Show fewer files',
                        limitShowMore: 'Show more files',
                        errorIconAriaLabel: 'Error',
                    }}
                    showFileLastModified
                    showFileSize
                    showFileThumbnail
                    tokenLimit={3}
                    constraintText="For best results, please use the downloadable template to upload encoders"
                />
            </SpaceBetween>
        </Modal>
    );
};
export default FileUploadModal;
