// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { ButtonDropdownProps, StatusIndicatorProps } from '@cloudscape-design/components';
import { SBSMedia } from '_types';
import { useState } from 'react';

export const VISIBLE_CONTENT_OPTIONS = [
    {
        label: 'Event properties',
        options: [
            {
                id: 'eventName',
                label: 'Name',
            },
            { id: 'eventState', label: 'Status' },
            {
                id: 'primary',
                label: 'Primary Encoder',
            },
            {
                id: 'backup',
                label: 'Backup Encoder',
            },
        ],
    },
];

export const SLATE_OPTIONS = (e: SBSMedia.Event): ButtonDropdownProps.ItemOrGroup[] => [
    {
        text: 'Opening Slate',
        id: 'Opening',
        disabled: !e.slateLocation?.Opening || e.inputState === 'Opening',
    },
    { text: 'Live', id: 'Live', disabled: e.inputState === 'Live' },
    {
        text: 'Intermission Slate',
        id: 'Intermission',
        disabled: !e.slateLocation?.Intermission || e.inputState === 'Intermission',
    },
    {
        text: 'Closing Slate',
        id: 'Closing',
        disabled: !e.slateLocation?.Closing || e.inputState === 'Closing',
    },
];

export function renderStateName(inputState: string) {
    // If theres an input state and the input state is not equal to
    // live then display the input state and append it with the word
    // slate, otherwise display the input state.
    // If there is no input state, then display two dashes

    if (!inputState) {
        return '--';
    } else if (inputState !== 'Live') {
        return inputState + ' Slate';
    } else if (inputState === 'Live') {
        return inputState;
    } else {
        return '--';
    }
}

export const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Events' },
    { value: 30, label: '30 Events' },
    { value: 50, label: '50 Events' },
];

export const DEFAULT_PREFERENCES = {
    pageSize: 50,
    custom: { defaultView: 'Card view' },
};

export const COLUMN_DISPLAY = [
    { id: 'variable', visible: true },
    { id: 'value', visible: true },
    { id: 'type', visible: true },
    { id: 'description', visible: true },
];

export const save = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value));

export const remove = (key: string) => localStorage.removeItem(key);

export const load = (key: string) => {
    const value = localStorage.getItem(key);
    try {
        return value && JSON.parse(value);
    } catch (e) {
        console.warn(
            `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`
        );
        return undefined;
    }
};

export function useLocalStorage(key: string, defaultValue?) {
    const [value, setValue] = useState(() => load(key) ?? defaultValue);

    function handleValueChange(newValue) {
        setValue(newValue);
        save(key, newValue);
    }

    return [value, handleValueChange] as const;
}

export function updateStatusIndicatorType(eventState: string): StatusIndicatorProps.Type {
    switch (eventState) {
        case SBSMedia.EventStateOptions.SCHEDULED:
            return 'pending';
        case SBSMedia.EventStateOptions.READY:
            return 'success';
        case SBSMedia.EventStateOptions.CONFIGURED:
            return 'success';
        case SBSMedia.EventStateOptions.STARTING:
            return 'loading';
        case SBSMedia.EventStateOptions.STOPPING:
            return 'loading';
        case SBSMedia.EventStateOptions.PENDING:
            return 'loading';
        case SBSMedia.EventStateOptions.COMPLETED:
            return 'stopped';
        case SBSMedia.EventStateOptions.OFFLINE:
            return 'error';
        case SBSMedia.EventStateOptions.REMOVED:
            return 'error';
        case SBSMedia.EventStateOptions.EXCEPTION:
            return 'warning';
        default:
            return 'warning';
    }
}

export function updateStatusIndicatorColor(eventState: string): StatusIndicatorProps.Color {
    switch (eventState) {
        case SBSMedia.EventStateOptions.SCHEDULED:
            return 'green';
        case SBSMedia.EventStateOptions.READY:
            return 'blue';
        case SBSMedia.EventStateOptions.CONFIGURED:
            return 'green';
        case SBSMedia.EventStateOptions.STARTING:
            return 'blue';
        case SBSMedia.EventStateOptions.STOPPING:
            return 'blue';
        case SBSMedia.EventStateOptions.PENDING:
            return 'blue';
        case SBSMedia.EventStateOptions.COMPLETED:
            return null;
        case SBSMedia.EventStateOptions.OFFLINE:
            return 'grey';
        case SBSMedia.EventStateOptions.REMOVED:
            return null;
        case SBSMedia.EventStateOptions.EXCEPTION:
            return null;
        default:
            return null;
    }
}
