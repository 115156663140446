// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { SBSMedia } from '_types';
import {
    deleteEncoder,
    fetchEncoderData,
    fetchProfileData,
    postEncoder,
    updateEncoder,
    getThumbnail,
} from './EncoderManagementAPI';

export interface EncoderManagementState {
    key: string;
    status: string;
    encoders: SBSMedia.Encoder[];
    profiles: SBSMedia.Profile[];
    thumbnails: object;
}

const initialState: EncoderManagementState = {
    key: 'replaceMe',
    status: 'idle',
    encoders: [],
    profiles: [],
    thumbnails: {},
};

/*===============================
             Thunks
===============================*/

export const getEncoders = createAsyncThunk('EncoderManagement/getEncoders', async () => {
    const response = await fetchEncoderData();
    return response.encoders;
});

export const getProfiles = createAsyncThunk('EncoderManagement/profiles', async () => {
    const response = await fetchProfileData();
    return response.profiles;
});

export const updateEncoderAsync = createAsyncThunk(
    'EncoderManagement/updateEncoder',
    async (params: SBSMedia.EncoderProps) => {
        const response = await updateEncoder(params);
        return { response };
    }
);

export const submitEncoder = createAsyncThunk(
    'EncoderManagement/postEncoder',
    async (params: SBSMedia.EncoderProps) => {
        const response = await postEncoder(params);
        return { response };
    }
);

export const deleteEncoderAsync = createAsyncThunk(
    'EncoderManagement/deleteEncoder',
    async (params: object) => {
        const response = await deleteEncoder(params);

        return { response };
    }
);

export const getEncoderThumbnail = createAsyncThunk(
    'EncoderManagement/getThumbnail',
    async (encoderId: string) => {
        const response = await getThumbnail(encoderId);
        return { id: encoderId, data: response };
    }
);

/*===============================
              Slice
===============================*/

export const EncoderManagementSlice = createSlice({
    name: 'EncoderManagement',
    initialState,
    reducers: {
        updateState: (state, action: PayloadAction<string>) => {
            state.key = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getEncoders.fulfilled, (state, action) => {
                state.status = 'idle';
                state.encoders = action.payload;
            })

            .addCase(getProfiles.fulfilled, (state, action) => {
                state.status = 'idle';
                state.profiles = action.payload;
            })
            .addCase(getEncoderThumbnail.fulfilled, (state, action) => {
                state.thumbnails[action.payload.id] = action.payload.data;
            });
    },
});

/*===============================
            Actions
===============================*/

export const { updateState } = EncoderManagementSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectEncoders = (state: RootState) => state.EncoderManagement.encoders;
export const selectProfiles = (state: RootState) => state.EncoderManagement.profiles;
export const selectThumbnails = (state: RootState) => state.EncoderManagement.thumbnails;

export default EncoderManagementSlice.reducer;
