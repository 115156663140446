// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction, useState } from 'react';

import {
    Box,
    Button,
    Modal,
    SpaceBetween,
    Spinner,
   
} from '@cloudscape-design/components';

import { useAppDispatch } from 'app/hooks';

import { changeEventSlateAsync, getEventsAsync } from 'features/EventManagement/EventManagementSlice';

import { SBSMedia } from '_types';
import { Snackbar } from '@mui/material';

interface ModuleProps {
    transitionMetadata: {visible: boolean, slateName: string, event: SBSMedia.Event};
    setTransitionMetadata: Dispatch<SetStateAction<{visible: boolean, slateName: string, event: SBSMedia.Event}>>,
}

const SlateTransitionModal: React.FC<ModuleProps> = ({
    transitionMetadata, 
    setTransitionMetadata,
    
}) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false)

    return (
        <Modal
            onDismiss={(e) => {
                if (e.detail.reason !== 'overlay') {
                    setTransitionMetadata({visible: false, slateName: '', event: undefined});
                    
                }
            }}
            visible={transitionMetadata.visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => {
                                setTransitionMetadata({visible: false, slateName: '', event: undefined});
                            }}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setLoading(true)
                                return dispatch(
                                    changeEventSlateAsync({
                                        EventId: transitionMetadata.event.EventId,
                                        slateName: transitionMetadata.slateName,
                                    })
                                ).then((e)=>{
                                    console.log(e)
                                }).finally(() => {
                                    dispatch(getEventsAsync()).then(()=>{
                                        setLoading(false)
                                        setTransitionMetadata({visible: false, slateName: '', event: undefined});
                                    });
                                    
                                });
                                
                            }}>
                            {loading ? <Spinner /> : "Confirm"}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`State Transition: ` + transitionMetadata.event.eventName}>
            Are you sure you want to change input state from {transitionMetadata.event.inputState} to {transitionMetadata.slateName}?
            
        </Modal>
    );
};

export default SlateTransitionModal;
