// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
import { FormField, Grid, Input, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { setErrorText } from '../../helperFunctions';

import { sanitizeInput } from 'utils/common';
import { CustomSubFormProps } from '../../AddEvent';
import { GRID_DEFINTION_COLSPAN_6 } from 'utils/constants';
import { PassphraseForm } from '../PassphraseForm';

export const BackupFieldsSrtIngestForm: React.FC<CustomSubFormProps> = ({
    didSubmit,
    localForm,
    setLocalForm,
}) => {
    return (
        <SpaceBetween direction="vertical" size="m">
            <Grid gridDefinition={GRID_DEFINTION_COLSPAN_6}>
                {localForm.srtMode === 'Listener' ? (
                    <FormField
                        label="Backup Allowlist CIDR Block"
                        description="Required"
                        errorText={setErrorText(didSubmit, localForm, {
                            attribute: 'BackupAllowlistCidr',
                        })}>
                        <Input
                            onChange={({ detail }) => {
                                setLocalForm({
                                    ...localForm,
                                    BackupAllowlistCidr: sanitizeInput(detail.value),
                                });
                            }}
                            value={localForm.BackupAllowlistCidr || ''}
                            placeholder="Ex: 0.0.0.0/0"
                        />
                    </FormField>
                ) : (
                    <FormField
                        label="Backup Source IP"
                        description="Required"
                        errorText={setErrorText(didSubmit, localForm, {
                            attribute: 'backupIp',
                        })}>
                        <Input
                            onChange={({ detail }) => {
                                setLocalForm({
                                    ...localForm,
                                    backupIp: sanitizeInput(detail.value),
                                });
                            }}
                            value={localForm.backupIp || ''}
                        />
                    </FormField>
                )}

                <FormField
                    label="Backup Port"
                    description="Required"
                    errorText={setErrorText(didSubmit, localForm, {
                        attribute: 'backupSRTPort',
                    })}>
                    <Input
                        type="number"
                        onChange={({ detail }) => {
                            setLocalForm({
                                ...localForm,
                                backupSRTPort: sanitizeInput(detail.value),
                            });
                        }}
                        value={
                            (localForm.backupSRTPort && localForm.backupSRTPort.toString()) || ''
                        }
                    />
                </FormField>
            </Grid>
            <FormField
                label="Backup Passphrase"
                description="Optional and interchangeable. Leave the field empty for no encryption.">
                <PassphraseForm
                    localForm={localForm}
                    shouldGenerateRandom={true}
                    setLocalForm={setLocalForm}
                    localPassphrasePath={localForm.passphraseBackup}
                    passphraseType={'BackupInputSRT'}
                />
            </FormField>
        </SpaceBetween>
    );
};
