// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';

import { switchIcon } from './common';

const REGIONS: Array<string> = [
    'us-east-2',
    'us-east-1',
    'us-west-1',
    'us-west-2',
    'af-south-1',
    'ap-east-1',
    'ap-south-2',
    'ap-southeast-3',
    'ap-southeast-4',
    'ap-south-1',
    'ap-northeast-3',
    'ap-northeast-2',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ca-central-1',
    'eu-central-1',
    'eu-west-1',
    'eu-west-2',
    'eu-south-1',
    'eu-west-3',
    'eu-south-2',
    'eu-north-1',
    'eu-central-2',
    'me-south-1',
    'me-central-1',
    'sa-east-1',
];
const SRT_REGIONS: Array<string> = [
    'ap-northeast-1',
    'ap-northeast-2',
    'ap-northeast-3',
    'ap-south-1',
    'ap-southeast-1',
    'ap-southeast-2',
    'ca-central-1',
    'eu-central-1',
    'eu-north-1',
    'eu-west-1',
    'eu-west-2',
    'eu-west-3',
    'sa-east-1',
    'us-east-1',
    'us-east-2',
    'us-west-2',
];

const PROFILE_ATTRIBUTES_EDITABLE = [
    { 'attribute': 'name', 'type': 'string', 'name': 'Name', 'override': false },
    {
        'attribute': 'Width',
        'type': 'number',
        'unit': 'pixels',
        'example': 'e.g. 1920',
        'name': 'Width',
        'override': true,
    },
    {
        'attribute': 'Height',
        'type': 'number',
        'unit': 'pixels',
        'example': 'e.g. 1080',
        'name': 'Height',
        'override': true,
    },
    {
        'attribute': 'VideoBitrate',
        'type': 'number',
        'unit': 'Mbps',
        'name': 'Video Bitrate',
        'override': true,
    },
    {
        'attribute': 'videoFrameRate',
        'type': 'number',
        'unit': 'fps',
        'options': ['24', '25', '29.97', '30', '59.94', '60'],
        'name': 'Video Frame Rate',
        'override': true,
    },
    {
        'attribute': 'AudioBitrate',
        'type': 'number',
        'unit': 'bps',
        'name': 'Audio Bitrate',
        'options': [
            '40000',
            '48000',
            '56000',
            '64000',
            '96000',
            '112000',
            '128000',
            '16000',
            '192000',
            '224000',
        ],
        'override': true,
    },

    {
        'attribute': 'keyFrameInterval',
        'type': 'number',
        'unit': 'seconds',
        'name': 'Keyframe Interval',
        'override': true,
    },
];

const ENCODER_METRICS_METADATA = [
    {
        displayName: 'Using SDI',
        name: 'UsingSDI',
        info: 'Indicates if SDI is the currently selected input for an AWS Elemental Link device.',
        unit: 'Boolean',
    },
    {
        displayName: 'Using HDMI',
        name: 'UsingHDMI',
        info: 'Indicates if HDMI is the currently selected input for an AWS Elemental Link device.',
        unit: 'Boolean',
    },
    {
        displayName: 'Input locked',
        name: 'InputLocked',
        info: 'Indicates if an AWS Elemental Link device has successfully locked on to the input signal.',
        unit: 'Boolean',
    },
    {
        displayName: 'Encoder running',
        name: 'EncoderRunning',
        info: 'The encoder is successfully processing the input signal from an AWS Elemental Link device',
        unit: 'Booelan',
    },
    {
        displayName: 'Linked to stream endpoint',
        name: 'LinkedToStreamEndpoint',
        info: 'An AWS Elemental Link device is connected to the streaming endpoint in AWS.',
        unit: 'Boolean',
    },
    {
        displayName: 'Streaming',
        name: 'Streaming',
        info: 'An AWS Elemental Link device is successfully streaming the input signal to MediaLive.',
        unit: 'Boolean',
    },
    {
        displayName: 'Temperature',
        name: 'Temperature',
        info: 'The temperature in degrees Celsius of an AWS Elemental Link device.',
        unit: '° C',
    },
    {
        displayName: 'Configured bitrate',
        name: 'ConfiguredBitrate',
        info: 'The configured Maximum bitrate on an AWS Elemental Link device.',
        unit: 'Bits per second',
    },
    {
        displayName: 'Encoder bitrate',
        name: 'EncoderBitrate',
        info: 'The actively encoded bitrate on an AWS Elemental Link device',
        unit: 'Bits per second',
    },
    {
        displayName: 'Configured bitrate available',
        name: 'ConfiguredBitrateAvailable',
        info: 'On an AWS Elemental Link device, the portion of Configured bitrate that the device can satisfy based on network conditions.',
        unit: 'Percentage',
    },
    {
        displayName: 'Total packets',
        name: 'TotalPackets',
        info: 'On an AWS Elemental Link device, the total number of packets that were successfully delivered to the AWS streaming endpoint.',
        unit: 'Count',
    },
    {
        displayName: 'Recovered packets',
        name: 'RecoveredPackets',
        info: 'On an AWS Elemental Link device, the number of packets that were lost during transit, but recovered by error correction.',
        unit: 'Count',
    },
    {
        displayName: 'Not recovered packets',
        name: 'NotRecoveredPackets',
        info: 'On an AWS Elemental Link device, the number of packets that were lost during transit and were not recovered by error correction.',
        unit: 'Count',
    },
    {
        displayName: 'Error seconds',
        name: 'ErrorSeconds',
        info: 'On an AWS Elemental Link device, the number of seconds in which one or more packets were dropped and not recovered.',
        unit: 'Count',
    },
];

const DESTINATION_OPTIONS = [
    {
        label: 'Facebook',
        value: 'Facebook',
        iconSvg: switchIcon('Facebook', true),
    },

    {
        label: 'YouTube',
        value: 'YouTube',
        iconSvg: switchIcon('YouTube', true),
    },
    {
        label: 'LinkedIn',
        value: 'LinkedIn',
        iconSvg: switchIcon('LinkedIn', true),
    },
    {
        label: 'Workplace',
        value: 'Workplace',

        iconSvg: switchIcon('Workplace', true),
    },

    {
        label: 'Twitch',
        value: 'Twitch',
        iconSvg: switchIcon('Twitch', true),
    },
    {
        label: 'Instagram',
        value: 'Instagram',
        iconSvg: switchIcon('Instagram', true),
    },

    {
        label: 'CloudFront',
        value: 'CloudFront',
        iconSvg: switchIcon('CloudFront', true),
    },
    {
        label: 'Output SRT',
        value: 'OutputSRT',
        iconSvg: switchIcon('OutputSRT', true),
    },
    {
        label: 'Custom RTMP',
        value: 'CustomRTMP',
        iconSvg: switchIcon('CustomRTMP', true),
    },
    {
        label: 'Record',
        value: 'Record',
        iconSvg: switchIcon('Record', true),
    },
];

const GRID_DEFINTION_COLSPAN_6 = [
    { colspan: 6 },
    { colspan: 6 },
    { colspan: 6 },
    { colspan: 6 },
    { colspan: 6 },
];

export {
    GRID_DEFINTION_COLSPAN_6,
    ENCODER_METRICS_METADATA,
    DESTINATION_OPTIONS,
    PROFILE_ATTRIBUTES_EDITABLE,
    REGIONS,
    SRT_REGIONS,
};
