// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';
import { ColumnLayout, StatusIndicator, Box, Popover } from '@cloudscape-design/components';
import { DestinationImage } from '_types';
import {
    FaAmazon,
    FaFacebook,
    FaYoutube,
    FaTwitch,
    FaInstagram,
    FaLinkedin,
    FaRegFile,
} from 'react-icons/fa';

import { LiaBroadcastTowerSolid } from 'react-icons/lia';
import { AiOutlineCloudServer } from 'react-icons/ai';

import { SiWorkplace } from 'react-icons/si';

export function toProperCase(query) {
    return query.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

// from 1234 to "2023-11-03"
export function convertEpochToDate(epoch) {
    const updatedValue = new Date(new Date(epoch).valueOf());

    const temp =
        updatedValue.getFullYear() +
        '-' +
        ('0' + (updatedValue.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + updatedValue.getDate()).slice(-2);

    return temp;
}

// from "2023-11-03" to 123345
export function convertDateToEpoch(date) {
    const updatedValue = new Date(new Date(date.replace(/-/g, '/')));

    return updatedValue.valueOf();
}

// from 12356 to Friday January 16, 2034
export function convertStringEpochToDate(epoch) {
    if (epoch == 'DNE') {
        return null;
    }
    const updatedValue = new Date(parseInt(epoch));

    return new Intl.DateTimeFormat('en-us', {
        weekday: 'long',
        month: 'long',
        day: '2-digit',
        year: 'numeric',
        hourCycle: 'h23',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'longGeneric',
    }).format(updatedValue);
}

//from 12345 to  Friday January 16, 2034, time only
export function convertStringEpochToDate3(epoch) {
    if (epoch == 'DNE') {
        return null;
    }
    const updatedValue = new Date(parseInt(epoch));

    return new Intl.DateTimeFormat('en-us', {
        hourCycle: 'h23',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'longGeneric',
    }).format(updatedValue);
}

export function convertStringEpochToDate2(epoch) {
    if (epoch == 'DNE') {
        return null;
    }
    const updatedValue = new Date(parseInt(epoch));

    return new Intl.DateTimeFormat('en-us', {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
        hourCycle: 'h23',
    }).format(updatedValue);
}

export function convertStringTimeToEpoch(curDate, string, timezone) {
    const newDateWithTime = convertEpochToDate(curDate);
    const datestring = newDateWithTime.replace(/-/g, '/') + ' ' + string;
    const date = new Date(datestring);
    timezone =
        (timezone && timezone.split(' ')[0]) || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const temp = new Date(date.toLocaleString('en', { timeZone: timezone }));
    var diff = temp.getTime() - date.getTime();

    return new Date(date.getTime() - diff).valueOf();
}

export function isStartStopDisabled(eventState) {
    return (
        eventState == undefined ||
        eventState === 'STARTING' ||
        eventState === 'OFFLINE' ||
        eventState === 'STOPPING' ||
        // eventState === 'COMPLETED' ||
        eventState === 'PENDING'
    );
}

export function areInteractionsDisabled(eventState) {
    return (
        eventState == undefined ||
        eventState === 'LIVE' ||
        eventState === 'STARTING' ||
        eventState === 'STOPPING' ||
        eventState === 'PENDING'
    );
}

export function isDeleteDisabled(eventState) {
    return (
        eventState == undefined ||
        eventState === 'LIVE' ||
        eventState === 'STARTING' ||
        eventState === 'STOPPING'
    );
}

export const timeDistance = (date1, date2) => {
    let distance = Math.abs(date1 - date2);
    const hours = Math.floor(distance / 3600000);
    distance -= hours * 3600000;
    const minutes = Math.floor(distance / 60000);

    return `${hours}:${('0' + minutes).slice(-2)}`;
};

export const getHours = (date1) => {
    const example = new Intl.DateTimeFormat('en-us', {
        hour: '2-digit',
        minute: '2-digit',
    }).format(date1);

    return example;
};

export const ValueWithLabel = ({ label, children }) => (
    <div key={label + children}>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);
export function displayPairs(e, encoders, isTable, thumbnails) {
    const displayedPairs = [];

    if (e.inputType !== undefined) {
        displayedPairs.push(
            <ValueWithLabel label={!isTable && 'Input Type'}>
                {e.inputType + (e.inputType === 'SRT Ingest' ? ' - ' + e.srtMode : '')}
            </ValueWithLabel>
        );

        if (e.inputType == 'Encoders' || e.inputType.includes('Elemental')) {
            const primaryEncoderRef = encoders.filter((enc) => {
                return enc.EncoderId == e.primaryEncoder;
            })[0];

            displayedPairs.push(
                <ValueWithLabel label="Primary">
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="large"
                        triggerType="custom"
                        content={
                            thumbnails[primaryEncoderRef?.EncoderId] ? (
                                <img
                                    src={`data:image/jpeg;base64,${
                                        thumbnails[primaryEncoderRef?.EncoderId]
                                    }`}
                                    alt="Image"
                                />
                            ) : (
                                <div>Loading...</div>
                            )
                        }>
                        <StatusIndicator
                            type={
                                primaryEncoderRef &&
                                primaryEncoderRef.ConnectionState == 'connected'
                                    ? 'success'
                                    : 'error'
                            }>
                            {primaryEncoderRef && primaryEncoderRef.name ? (
                                <span style={{ wordBreak: 'normal' }}>
                                    {primaryEncoderRef.name}
                                </span>
                            ) : (
                                'Encoder Deleted'
                            )}
                        </StatusIndicator>
                    </Popover>
                </ValueWithLabel>
            );
            const backupEncoderRef = encoders.filter((enc) => {
                return enc.EncoderId == e.backupEncoder;
            })[0];

            if (e.backupEncoder !== undefined) {
                displayedPairs.push(
                    <ValueWithLabel label="Backup">
                        <Popover
                            dismissButton={false}
                            position="top"
                            size="large"
                            triggerType="custom"
                            content={
                                thumbnails[backupEncoderRef?.EncoderId] ? (
                                    <img
                                        src={`data:image/jpeg;base64,${
                                            thumbnails[backupEncoderRef?.EncoderId]
                                        }`}
                                        alt="Image"
                                    />
                                ) : (
                                    <div>Loading...</div>
                                )
                            }>
                            <StatusIndicator
                                type={
                                    backupEncoderRef &&
                                    backupEncoderRef.ConnectionState == 'connected'
                                        ? 'success'
                                        : 'error'
                                }>
                                {backupEncoderRef && backupEncoderRef.name ? (
                                    <span style={{ wordBreak: 'normal' }}>
                                        {backupEncoderRef.name}
                                    </span>
                                ) : (
                                    'Encoder Deleted'
                                )}
                            </StatusIndicator>
                        </Popover>
                    </ValueWithLabel>
                );
            } else {
                displayedPairs.push(<ValueWithLabel label="Backup">None</ValueWithLabel>);
            }
        } else if (e.inputType == 'SRT Ingest') {
            displayedPairs.push(<ValueWithLabel label="Latency">{e.latency} ms</ValueWithLabel>);

            if (e.srtIngestData) {
                displayedPairs.push(
                    <ValueWithLabel label="Ingest Endpoint">
                        {' '}
                        <Popover
                            dismissButton={false}
                            position="top"
                            content={
                                <ColumnLayout columns={2} borders="horizontal">
                                    <div>
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                            }}>
                                            Primary Endpoint
                                        </div>
                                        <div>
                                            {e?.srtIngestData?.primary?.endpoint
                                                ? e?.srtIngestData?.primary?.endpoint
                                                : '-' || 'None'}
                                            {e.passphrase ? '?passphrase=' + e.passphrase : null}
                                        </div>

                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                            }}>
                                            Backup Endpoint
                                        </div>
                                        <div>
                                            {e?.srtIngestData?.backup?.endpoint
                                                ? e?.srtIngestData?.backup?.endpoint
                                                : '-'}
                                            {e.passphraseBackup
                                                ? '?passphrase=' + e.passphraseBackup
                                                : null}
                                        </div>
                                    </div>
                                </ColumnLayout>
                            }>
                            Click for info
                        </Popover>
                    </ValueWithLabel>
                );
            } else {
                displayedPairs.push(
                    <ValueWithLabel label="Backup Enabled">
                        <StatusIndicator type={e.backupSRT ? 'success' : 'error'}>
                            {e.backupSRT ? 'true' : 'false'}
                        </StatusIndicator>
                    </ValueWithLabel>
                );
            }
        } else if (e.inputType == 'File') {
            displayedPairs.push(
                <ValueWithLabel label="File Location">{e.fileInput}</ValueWithLabel>
            );
        } else if (e.inputType === 'RTMP') {
            displayedPairs.push(
                <ValueWithLabel label="Allowlist CIDR">{e.rtmpCIDR}</ValueWithLabel>
            );

            if (e.eventState === 'LIVE') {
                let primaryUrl = e.rtmpIngestEndpoint?.[0]?.Url;
                let urlArray = primaryUrl.split('/');
                primaryUrl = primaryUrl
                    .split('/')
                    .slice(0, urlArray.length - 1)
                    .join('/');

                let backupUrl = e.rtmpIngestEndpoint?.[1]?.Url;

                urlArray = backupUrl?.split('/');
                backupUrl = backupUrl
                    ?.split('/')
                    .slice(0, urlArray.length - 1)
                    .join('/');

                displayedPairs.push(
                    <ValueWithLabel label="Ingest Endpoint">
                        {' '}
                        <Popover
                            dismissButton={false}
                            position="top"
                            content={
                                <ColumnLayout columns={2} borders="horizontal">
                                    <div>
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                            }}>
                                            Primary URL
                                        </div>
                                        <div>{primaryUrl || 'None'}</div>

                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                            }}>
                                            Backup URL
                                        </div>
                                        <div>{backupUrl || 'None'}</div>
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                            }}>
                                            Stream Key
                                        </div>
                                        <div>{e.rtmpStreamkey || 'None'}</div>
                                    </div>
                                </ColumnLayout>
                            }>
                            Click for info
                        </Popover>
                    </ValueWithLabel>
                );
            }
        }

        return (
            <ColumnLayout columns={3} minColumnWidth={1} disableGutters key={displayedPairs}>
                {displayedPairs}
            </ColumnLayout>
        );
    } else {
        ('none');
    }
}

export function sanitizeInput(input) {
    // Removes characters that may be used in SQL injection attacks
    const sanitizedInput = input.replace(/['";\\]/g, '');

    return sanitizedInput;
}

export function switchIcon(name, requireWrapper) {
    let icon;

    switch (name) {
        case DestinationImage.FACEBOOK:
            icon = (
                <FaFacebook
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                    type="svg"
                />
            );
            break;
        case DestinationImage.CUSTOMRTMP:
            icon = (
                <LiaBroadcastTowerSolid
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                />
            );
            break;
        case DestinationImage.LINKEDIN:
            icon = (
                <FaLinkedin
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                />
            );
            break;
        case DestinationImage.YOUTUBE:
            icon = (
                <FaYoutube
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                />
            );
            break;
        case DestinationImage.CLOUDFRONT:
            icon = (
                <FaAmazon
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                />
            );
            break;
        case DestinationImage.RECORD:
            icon = (
                <AiOutlineCloudServer
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                />
            );
            break;
        case DestinationImage.TWITCH:
            icon = (
                <FaTwitch
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                />
            );
            break;
        case DestinationImage.WORKPLACE:
            icon = (
                <SiWorkplace
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                />
            );
            break;
        case DestinationImage.INSTAGRAM:
            icon = (
                <FaInstagram
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                />
            );
            break;
        case DestinationImage.OUTPUTSRT:
            icon = (
                <FaRegFile
                    color="black"
                    size={requireWrapper ? '' : '1.4em'}
                    style={{ padding: requireWrapper ? '' : '.2em' }}
                />
            );
            break;
    }
    if (requireWrapper) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false" key="0">
                <g> {icon}</g>
            </svg>
        );
    } else {
        return icon;
    }
}
