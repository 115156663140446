import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { generateRandomPassphrase, updatePassphrase } from '../helperFunctions';
import { Button, Grid, Input, Popover, StatusIndicator } from '@cloudscape-design/components';
import { GRID_DEFINTION_COLSPAN_6 } from 'utils/constants';
import { SBSMedia } from '_types';

interface PassphraseFormProps {
    localForm: SBSMedia.Event;
    setLocalForm: Dispatch<SetStateAction<SBSMedia.Event>>;
    localPassphrasePath: any;
    passphraseType: string;
    shouldGenerateRandom: boolean;
}
export const PassphraseForm: React.FC<PassphraseFormProps> = ({
    localForm,
    setLocalForm,
    localPassphrasePath,
    passphraseType,
    shouldGenerateRandom,
}) => {
    useEffect(() => {
        if (shouldGenerateRandom && !localPassphrasePath) {
            const passphrase = generateRandomPassphrase(12);
            updatePassphrase(passphraseType, localForm, setLocalForm, passphrase);
        }
    }, []);
    return (
        <Grid gridDefinition={GRID_DEFINTION_COLSPAN_6}>
            <Input
                type="text"
                onChange={({ detail }) => {
                    updatePassphrase(passphraseType, localForm, setLocalForm, detail.value);
                }}
                value={localPassphrasePath || ''}
            />
            <Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={<StatusIndicator type="success">Copied</StatusIndicator>}>
                <Button
                    variant="inline-link"
                    iconName="copy"
                    ariaLabel="Copy"
                    onClick={() => navigator.clipboard.writeText(localPassphrasePath)}
                />
            </Popover>
        </Grid>
    );
};
