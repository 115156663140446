// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { useEffect, useState } from 'react';

import {
    Button,
    Container,
    ContentLayout,
    ExpandableSection,
    SpaceBetween,
} from '@cloudscape-design/components';
import DashboardHeader from 'components/Common/DashboardHeader/DashboardHeader';
import ProfileCards from 'components/Settings/ProfileCards';
import { ValueWithLabel } from 'utils/common';

import EditProfile from 'components/Settings/EditProfile/EditProfile';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    getVersionAsync,
    selectapiVersion,
    selectS3Closing,
    selectS3Input,
    selectS3Intermission,
    selectS3Opening,
    selectS3Output,
} from 'features/SettingsManagement/SettingsManagementSlice';
import { SBSMedia } from '_types';
import { DefaultBucket } from 'components/Settings/DefaultBucket/DefaultBucket';
import { getProfiles } from 'features/EncoderManagement/EncoderManagementSlice';

const Settings: React.FC = () => {
    const [profileVisible, setProfileVisible] = useState(false);

    const [newOutputBucket, setNewOutputBucket] = useState<string>();
    const [newInputBucket, setNewInputBucket] = useState<string>();
    const [newOpeningBucket, setNewOpeningBucket] = useState<string>();
    const [newIntermissionBucket, setNewIntermissionBucket] = useState<string>();
    const [newClosingBucket, setNewClosingBucket] = useState<string>();
    const dispatch = useAppDispatch();
    const s3BucketOutput = useAppSelector(selectS3Output);
    const s3BucketInput = useAppSelector(selectS3Input);
    const s3BucketOpening = useAppSelector(selectS3Opening);
    const s3BucketIntermission = useAppSelector(selectS3Intermission);
    const s3BucketClosing = useAppSelector(selectS3Closing);
    const apiVersion = useAppSelector(selectapiVersion);
    dispatch(getVersionAsync());
    useEffect(() => {
        dispatch(getProfiles());
    }, []);

    return (
        <ContentLayout disableOverlap header={<DashboardHeader title={'Settings'} />}>
            <br />

            <ExpandableSection defaultExpanded headerText="Profiles">
                <Container>
                    <SpaceBetween direction="vertical" size="l">
                        <Button
                            onClick={() => {
                                setProfileVisible(true);
                            }}>
                            Add New Profile
                        </Button>
                        <ProfileCards />

                        <EditProfile
                            isNew={true}
                            setVisible={setProfileVisible}
                            visible={profileVisible}
                            currentDetails={{ name: '' } as SBSMedia.Profile}
                        />
                    </SpaceBetween>
                </Container>
            </ExpandableSection>

            <ExpandableSection defaultExpanded headerText="File Management">
                <p>
                    {[
                        {
                            type: 'Input',
                            newBucket: newInputBucket,
                            s3Bucket: s3BucketInput,
                            setNewBucket: setNewInputBucket,
                        },
                        {
                            type: 'Output',
                            newBucket: newOutputBucket,
                            s3Bucket: s3BucketOutput,
                            setNewBucket: setNewOutputBucket,
                        },
                        {
                            type: 'Opening',
                            newBucket: newOpeningBucket,
                            s3Bucket: s3BucketOpening,
                            setNewBucket: setNewOpeningBucket,
                        },
                        {
                            type: 'Intermission',
                            newBucket: newIntermissionBucket,
                            s3Bucket: s3BucketIntermission,
                            setNewBucket: setNewIntermissionBucket,
                        },
                        {
                            type: 'Closing',
                            newBucket: newClosingBucket,
                            s3Bucket: s3BucketClosing,
                            setNewBucket: setNewClosingBucket,
                        },
                    ].map((e) => {
                        return (
                            <>
                                <DefaultBucket
                                    type={e.type}
                                    newBucket={e.newBucket}
                                    s3Bucket={e.s3Bucket}
                                    setNewBucket={e.setNewBucket}
                                />
                                <br />
                            </>
                        );
                    })}
                </p>
            </ExpandableSection>
            <ExpandableSection defaultExpanded headerText="Application Information">
                <ValueWithLabel label="Backend API Version">{apiVersion}</ValueWithLabel>
                <ValueWithLabel label="Frontend Version">v1.0.22 (July 29, 2024)</ValueWithLabel>
            </ExpandableSection>
        </ContentLayout>
    );
};

export default Settings;
