// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { useState } from 'react';

import './encoderDashboard.scss';

import EncoderCard from 'components/Encoders/EncoderCard';
import AddEncoder from '../AddEncoder';
import { SBSMedia } from '_types';

const EncoderDashboard: React.FC<EncoderDashboardProps> = ({ setUploadVisible }) => {
    const [visible, setVisible] = useState(false);
    const [encoderInfo, setEncoderInfo] = useState({});
    return (
        <div id="encoderDashboard">
            <EncoderCard
                setVisible={setVisible}
                setEncoderInfo={setEncoderInfo}
                setUploadVisible={setUploadVisible}
            />
            <AddEncoder
                setVisible={setVisible}
                encoderInfo={encoderInfo as SBSMedia.EncoderProps}
                visible={visible}
                isEdit={Object.keys(encoderInfo).length !== 0}
            />
        </div>
    );
};

export default EncoderDashboard;

interface EncoderDashboardProps {
    // setAddVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setUploadVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
