// // © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// // This AWS Content is provided subject to the terms of the AWS Customer Agreement
// // available at http://aws.amazon.com/agreement or other written agreement between
// // Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Button, Modal, SpaceBetween } from '@cloudscape-design/components';

import { SBSMedia } from '_types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectEvents } from 'features/EventManagement/EventManagementSlice';

import {
    findIsDisabled,
    findText,
    renderStepItems,
    setHeaderName,
    setOnClick,
} from './helperFunctions';
import { getProfiles } from 'features/EncoderManagement/EncoderManagementSlice';

interface ModuleProps {
    setVisible: Dispatch<SetStateAction<boolean>>;
    visible: boolean;
    currentDetails: SBSMedia.Profile;
    setAlertText: Dispatch<SetStateAction<string | null>>;
}

const DeleteProfile: React.FC<ModuleProps> = ({
    visible,
    setVisible,
    currentDetails,
    setAlertText,
}) => {
    const [localProfileForm, setLocalProfileForm] = useState<SBSMedia.Profile>(
        {} as SBSMedia.Profile
    );
    const [attachedEvents, setAttachedEvents] = useState<SBSMedia.ProfileEvent[]>(null);

    const [selectedItems, setSelectedItems] = useState<SBSMedia.ProfileEvent[]>([]);
    const [deleteError, setDeleteError] = useState<boolean>(false);

    const [step, setStep] = useState(0);
    const events = useAppSelector(selectEvents);

    const dispatch = useAppDispatch();
    useEffect(() => {
        setVisible(true);
        setLocalProfileForm(currentDetails);
        setEventMap(currentDetails);
    }, [currentDetails]);

    function setEventMap(profileObject: SBSMedia.Profile) {
        const res = events
            .filter((row) => {
                return profileObject?.ReferenceEvents?.indexOf(row.EventId) > -1;
            })
            .map((e) => {
                return {
                    eventName: e.eventName,
                    EventId: e.EventId,
                    eventState: e.eventState,
                    disabled: !(
                        e.eventState === SBSMedia.EventStateOptions.CONFIGURED ||
                        e.eventState === SBSMedia.EventStateOptions.EXCEPTION ||
                        e.eventState === SBSMedia.EventStateOptions.COMPLETED
                    ),
                };
            });
        setAttachedEvents(res);
        if (profileObject?.ReferenceEvents?.length === 0) {
            setStep(4);
        }
    }
    function reset() {
        setVisible(false);
        setLocalProfileForm(currentDetails);
        setSelectedItems([]);
        setStep(0);
        dispatch(getProfiles());
    }

    return (
        <Modal
            onDismiss={(e) => {
                if (e.detail.reason !== 'overlay') {
                    reset();
                }
            }}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={(e) => {
                                reset();
                            }}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={(e) => {
                                setOnClick(
                                    step,
                                    selectedItems,
                                    setDeleteError,
                                    localProfileForm,
                                    deleteError,
                                    setStep,
                                    dispatch,
                                    reset,
                                    setAlertText
                                );
                            }}
                            disabled={findIsDisabled(step, selectedItems)}>
                            {findText(step)}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={setHeaderName(localProfileForm, step)}>
            <br />
            {renderStepItems(
                step,
                localProfileForm,
                setSelectedItems,
                selectedItems,
                attachedEvents
            )}
        </Modal>
    );
};

export default DeleteProfile;
