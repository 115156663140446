// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
import { FormField, Grid, Input } from '@cloudscape-design/components';
import React from 'react';
import { displayDestinationAttribute, setErrorText } from '../../helperFunctions';
import { DestinationForm } from '../../AddEvent';
import { DESTINATION_PROPERTIES } from '../../AddEventConstants';
import { sanitizeInput } from 'utils/common';
import { GRID_DEFINTION_COLSPAN_6 } from 'utils/constants';

export const DestinationPropertiesForm: React.FC<DestinationForm> = ({
    didSubmit,
    localForm,
    setLocalForm,
    destination,
}) => {
    return (
        <Grid gridDefinition={GRID_DEFINTION_COLSPAN_6}>
            {Object.keys(DESTINATION_PROPERTIES[destination].properties).map((property) => {
                if (property != 'profile' && property != 'profiles') {
                    const readableProperty =
                        DESTINATION_PROPERTIES[destination].properties[property];

                    return (
                        displayDestinationAttribute(property, localForm) && (
                            <FormField
                                errorText={setErrorText(didSubmit, localForm, {
                                    attribute: 'destinations',
                                    attribute2: destination,
                                    attribute3: property,
                                })}
                                key={property}
                                description={readableProperty}>
                                <Input
                                    onChange={({ detail }) => {
                                        if (
                                            (destination === 'Record' &&
                                                property === 'primaryUrl' &&
                                                detail.value.includes('s3ssl://')) ||
                                            destination !== 'Record'
                                        ) {
                                            const testing = {
                                                ...localForm,
                                                ['destinations']: {
                                                    ...localForm.destinations,
                                                    [destination]: {
                                                        ...localForm.destinations[destination],
                                                        [property]: sanitizeInput(detail.value),
                                                    },
                                                },
                                            };

                                            setLocalForm(testing);
                                        }
                                    }}
                                    value={
                                        destination === 'Record' && property === 'primaryUrl'
                                            ? 's3ssl://' +
                                              localForm.destinations[destination][
                                                  'primaryUrl'
                                              ]?.split('s3ssl://')[1]
                                            : localForm.destinations[destination][property]
                                    }
                                />
                            </FormField>
                        )
                    );
                }
            })}
        </Grid>
    );
};
