// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import {
    deleteEvent,
    fetchEventData,
    postEvent,
    editEvent,
    startEvent,
    stopEvent,
    createEvent,
    slateSelection,
    slateNotification,
} from './EventManagementApi';
import { SBSMedia } from '_types';
import { FlashbarProps } from '@cloudscape-design/components';

export interface EventManagementState {
    key: string;
    status: string;
    events: SBSMedia.Event[];
    alerts: FlashbarProps.MessageDefinition[]
}``

const initialState: EventManagementState = {
    key: 'replaceMe',
    status: 'idle',
    events: [],
    alerts: []
};

/*===============================
             Thunks
===============================*/

export const getEventsAsync = createAsyncThunk('EventManagement/getEvent', async () => {
    const response = await fetchEventData();
    return response;

    //return await fetchEventData();
});

export const addEventAsync = createAsyncThunk(
    'EventManagement/addEvent',
    async (params: SBSMedia.EventProps) => {
        const response = await postEvent({ ...params });

        return { response };
    }
);

export const editEventAsync = createAsyncThunk(
    'EventManagement/editEvent',
    async (params: SBSMedia.EventProps) => {
        const response = await editEvent({ ...params });

        return { response };
    }
);

export const deleteEventsAsync = createAsyncThunk(
    'EventManagement/deleteEvent',
    async (params: SBSMedia.EventDeletionProps) => {
        const response = await deleteEvent(params);
        return { response };
    }
);

export const startEventAsync = createAsyncThunk(
    'EventManagement/startStopEvent',
    async (params: object) => {
        const response = await startEvent(params);
        return { response };
    }
);
export const stopEventAsync = createAsyncThunk(
    'EventManagement/startStopEvent',
    async (params: object) => {
        const response = await stopEvent(params);
        return { response };
    }
);

export const createEventAsync = createAsyncThunk(
    'EventManagement/startStopEvent',
    async (params: object) => {
        const response = await createEvent(params);
        return { response };
    }
);

export const changeEventSlateAsync = createAsyncThunk(
    'EventManagement/slateSelection',
    async (params: object) => {
        const response = await slateSelection(params);
        return { response };
    }
);

export const clearSlateNotification = createAsyncThunk(
    'EventManagement/slateNotification',
    async (id: string) => {
        const response = await slateNotification(id);
        return { response };
    }
);

/*===============================
              Slice
===============================*/

export const EventManagementSlice = createSlice({
    name: 'EventManagement',
    initialState,
    reducers: {
        updateState: (state, action: PayloadAction<string>) => {
            state.key = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEventsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.events = action.payload.events;

                if (action.payload?.alerts?.length > 0) { 
                    state.alerts = action.payload.alerts.map((alert)=>{
                        return {
                            type: 'warning',
                            dismissible: true,
                            dissmissLabel: "Dismiss Message",
                            content: (alert.eventName + ": " + alert.message),
                            id: alert.EventId
                        }
                    })
                } else {
                    state.alerts = []
                }
                
            })
            .addCase(editEventAsync.rejected, (state, action) => {
                state.status = 'failed';
            });
    },
});

/*===============================
            Actions
===============================*/

export const { updateState } = EventManagementSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectEvents = (state: RootState) => state.EventManagement.events;
export const selectAlerts = (state: RootState) => state.EventManagement.alerts;

export default EventManagementSlice.reducer;
