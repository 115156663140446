// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';
import Board from '@cloudscape-design/board-components/board';
import BoardItem from '@cloudscape-design/board-components/board-item';
import LineChart from '@cloudscape-design/components/line-chart';
import Header from '@cloudscape-design/components/header';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';

import { SBSMedia } from '_types';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { selectMetrics } from 'features/MetricsManagement/MetricsManagementSlice';
import { selectEncoders } from 'features/EncoderManagement/EncoderManagementSlice';
import { useLocalStorage } from 'utils/localStorage';
import { ENCODER_METRICS_METADATA } from 'utils/constants';

const MetricCards: React.FC<MetricCardProps> = (props) => {
    const dispatch = useAppDispatch();
    const metricsData = useAppSelector(selectMetrics);
    const encoders = useAppSelector(selectEncoders);
    const [dashboardPositions, setDashboardPositions] = useLocalStorage('Dashboard-Positions', []);

    function getReadableEncoderNameById(targetEncoderId) {
        for (let i = 0; i < encoders.length; i++) {
            if (encoders[i].EncoderId === targetEncoderId) {
                return encoders[i].name;
            }
        }
        return 'Unkown Encoder';
    }

    function getMetricDataByName(targetMetricName) {
        for (let i = 0; i < ENCODER_METRICS_METADATA.length; i++) {
            if (ENCODER_METRICS_METADATA[i].name === targetMetricName) {
                return ENCODER_METRICS_METADATA[i];
            }
        }
    }

    const totalExistingBoardIds = dashboardPositions.map((position) => position.id);
    const remainingMetricsToRender = [...metricsData];

    let previousMetricBoards: SBSMedia.BoardItem[] = [];
    let newMetricBoards: SBSMedia.BoardItem[] = [];

    if (remainingMetricsToRender.length > 0) {
        previousMetricBoards = dashboardPositions.map((existingItem, index) => {
            const existingData = remainingMetricsToRender.find(
                (metric) => metric.metricName === existingItem.metricName
            );
            const existingDataIndex = remainingMetricsToRender.findIndex(
                (metric) => metric.metricName === existingItem.metricName
            );
            remainingMetricsToRender.splice(existingDataIndex, 1);
            if (existingData !== undefined) {
                return {
                    id: index + '',
                    rowSpan: existingItem.rowSpan,
                    columnSpan: existingItem.columnSpan,
                    data: existingData,
                };
            }
        });

        newMetricBoards = remainingMetricsToRender.map((metric: SBSMedia.Metric, index) => {
            return {
                id: totalExistingBoardIds.length + index + '',
                rowSpan: 4,
                columnSpan: 2,
                data: metric,
            };
        });
    }

    const combinedMetricBoards = [
        ...previousMetricBoards.filter((item) => item !== undefined),
        ...newMetricBoards.filter((item) => item !== undefined),
    ];

    // remove any recently updated removed encoders or charts from the boards
    const metricBoards = [];
    combinedMetricBoards.forEach((metricBoard) => {
        if (props.visibleCharts.includes(metricBoard.data.metricName)) {
            metricBoards.push({
                ...metricBoard,
                data: {
                    ...metricBoard.data,
                    encoders: metricBoard.data.encoders.filter((encoder) => {
                        return props.visibleEncoders.includes(encoder.EncoderId);
                    }),
                },
            });
        }
    });

    return (
        <Board
            renderItem={(item: SBSMedia.BoardItem) => {
                let totalDatapoints = [];
                item.data.encoders.forEach((encoder) => {
                    totalDatapoints = [...totalDatapoints, ...encoder.datapoints];
                });
                if (totalDatapoints.length <= 0) {
                    return (
                        <BoardItem
                            header={<Header>{item.data.metricName} </Header>}
                            i18nStrings={{
                                dragHandleAriaLabel: 'Drag handle',
                                dragHandleAriaDescription:
                                    'Use Space or Enter to activate drag, arrow keys to move, Space or Enter to submit, or Escape to discard.',
                                resizeHandleAriaLabel: 'Resize handle',
                                resizeHandleAriaDescription:
                                    'Use Space or Enter to activate resize, arrow keys to move, Space or Enter to submit, or Escape to discard.',
                            }}>
                            <Box textAlign="center">
                                <b>No data available</b>
                                <Box variant="p">There is no data available</Box>
                            </Box>
                        </BoardItem>
                    );
                }

                const yMin = totalDatapoints.reduce((min, obj) => {
                    return obj.y < min ? obj.y : min;
                }, Infinity);
                const yMax = totalDatapoints.reduce((max, obj) => {
                    return obj.y > max ? obj.y : max;
                }, -Infinity);

                const yBuffer = yMax - yMin;
                const xMin =
                    totalDatapoints.length > 0 && 'x' in totalDatapoints[0]
                        ? totalDatapoints[0]['x']
                        : 0;
                const xMax =
                    totalDatapoints.length > 0 && 'x' in totalDatapoints[0]
                        ? totalDatapoints[totalDatapoints.length - 1]['x']
                        : 0;
                const metricMetaData = getMetricDataByName(item.data.metricName);
                return (
                    <BoardItem
                        header={<Header>{metricMetaData.displayName}</Header>}
                        i18nStrings={{
                            dragHandleAriaLabel: 'Drag handle',
                            dragHandleAriaDescription:
                                'Use Space or Enter to activate drag, arrow keys to move, Space or Enter to submit, or Escape to discard.',
                            resizeHandleAriaLabel: 'Resize handle',
                            resizeHandleAriaDescription:
                                'Use Space or Enter to activate resize, arrow keys to move, Space or Enter to submit, or Escape to discard.',
                        }}>
                        <LineChart
                            series={item.data.encoders.map((encoder) => {
                                return {
                                    title: getReadableEncoderNameById(encoder.EncoderId),
                                    type: 'line',
                                    data: encoder.datapoints,
                                    valueFormatter: function o(e) {
                                        return (
                                            (Math.abs(e) >= 1e9
                                                ? (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
                                                : Math.abs(e) >= 1e6
                                                ? (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
                                                : Math.abs(e) >= 1e3
                                                ? (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
                                                : e.toFixed(2)) +
                                            ' ' +
                                            metricMetaData.unit
                                        );
                                    },
                                };
                            })}
                            xDomain={[xMin, xMax]}
                            yDomain={[yMin - yBuffer, yMax + yBuffer]}
                            i18nStrings={{
                                xTickFormatter: (e) =>
                                    e
                                        .toLocaleDateString('en-US', {
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: !1,
                                        })
                                        .split(',')
                                        .join('\n'),
                                yTickFormatter: function o(e) {
                                    return Math.abs(e) >= 1e9
                                        ? (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
                                        : Math.abs(e) >= 1e6
                                        ? (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
                                        : Math.abs(e) >= 1e3
                                        ? (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
                                        : e.toFixed(2);
                                },
                            }}
                            ariaLabel="Single data series line chart"
                            height={300}
                            hideFilter
                            hideLegend
                            xScaleType="time"
                            xTitle="Time (UTC)"
                            yTitle={metricMetaData.unit}
                            empty={
                                <Box textAlign="center">
                                    <b>No data available</b>
                                    <Box variant="p">There is no data available</Box>
                                </Box>
                            }
                            noMatch={
                                <Box textAlign="center">
                                    <b>No matching data</b>
                                    <Box variant="p">There is no matching data to display</Box>
                                    <Button>Clear filter</Button>
                                </Box>
                            }
                        />
                    </BoardItem>
                );
            }}
            onItemsChange={(event: any) => {
                setDashboardPositions(
                    event.detail.items.map((boardItem) => {
                        return {
                            rowSpan: boardItem.rowSpan,
                            columnSpan: boardItem.columnSpan,
                            metricName: boardItem.data.metricName,
                        };
                    })
                );
            }}
            items={metricBoards}
            i18nStrings={(() => {
                function createAnnouncement(operationAnnouncement, conflicts, disturbed) {
                    const conflictsAnnouncement =
                        conflicts.length > 0
                            ? `Conflicts with ${conflicts.map((c) => c.data.title).join(', ')}.`
                            : '';
                    const disturbedAnnouncement =
                        disturbed.length > 0 ? `Disturbed ${disturbed.length} items.` : '';
                    return [operationAnnouncement, conflictsAnnouncement, disturbedAnnouncement]
                        .filter(Boolean)
                        .join(' ');
                }
                return {
                    liveAnnouncementDndStarted: (operationType) =>
                        operationType === 'resize' ? 'Resizing' : 'Dragging',
                    liveAnnouncementDndItemReordered: (operation) => {
                        const columns = `column ${operation.placement.x + 1}`;
                        const rows = `row ${operation.placement.y + 1}`;
                        return createAnnouncement(
                            `Item moved to ${
                                operation.direction === 'horizontal' ? columns : rows
                            }.`,
                            operation.conflicts,
                            operation.disturbed
                        );
                    },
                    liveAnnouncementDndItemResized: (operation) => {
                        const columnsConstraint = operation.isMinimalColumnsReached
                            ? ' (minimal)'
                            : '';
                        const rowsConstraint = operation.isMinimalRowsReached ? ' (minimal)' : '';
                        const sizeAnnouncement =
                            operation.direction === 'horizontal'
                                ? `columns ${operation.placement.width}${columnsConstraint}`
                                : `rows ${operation.placement.height}${rowsConstraint}`;
                        return createAnnouncement(
                            `Item resized to ${sizeAnnouncement}.`,
                            operation.conflicts,
                            operation.disturbed
                        );
                    },
                    liveAnnouncementDndItemInserted: (operation) => {
                        const columns = `column ${operation.placement.x + 1}`;
                        const rows = `row ${operation.placement.y + 1}`;
                        return createAnnouncement(
                            `Item inserted to ${columns}, ${rows}.`,
                            operation.conflicts,
                            operation.disturbed
                        );
                    },
                    liveAnnouncementDndCommitted: (operationType) => `${operationType} committed`,
                    liveAnnouncementDndDiscarded: (operationType) => `${operationType} discarded`,
                    liveAnnouncementItemRemoved: (op: any) =>
                        createAnnouncement(`Removed item ${op.item.data.title}.`, [], op.disturbed),
                    navigationAriaLabel: 'Board navigation',
                    navigationAriaDescription: 'Click on non-empty item to move focus over',
                    navigationItemAriaLabel: (item: any) => (item ? item.data.title : 'Empty'),
                };
            })()}
            empty={'No encoders or charts to show.'}
        />
    );
};

export default MetricCards;

interface MetricCardProps {
    visibleCharts: string[];
    visibleEncoders: string[];
}
