// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { signRequest } from 'utils/sig4';
import axios from 'axios';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
export async function putProfile(param) {
    const path = 'profiles';
    const config = {
        headers: {},
        body: {
            ...param,
            ProfileId: param.ProfileId || uuidv4(),
        },
        response: false,
    };

    return API.put('SBS', path, config)
        .then((res) => {
            console.log('param put response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function deleteProfile(param: string) {
    const path = 'profiles/' + param;
    const config = {
        headers: {},

        response: false,
    };

    return API.del('SBS', path, config)
        .then((res) => {
            console.log('param put response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function getVersion() {
    // might be needed for explicitly signing requests
    // const signed = await signRequest(
    //     process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT + 'version',
    //     'GET',
    //     'cognito-idp',
    //     'us-east-1'
    // );
    const path = 'version';
    const config = {
        headers: {},
        response: false,
        // queryStringParameters: {
        //     'id': user.id,
        // },
    };

    console.log(config);

    return API.get('SBS', path, config)
        .then((res) => {
            console.log('version response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });

    // return response.data;
}
