// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import './editProfileInline.scss';

import { Autosuggest, FormField, Grid, Input } from '@cloudscape-design/components';
import { SBSMedia } from '_types';
import { toProperCase } from 'utils/common';
import { PROFILE_ATTRIBUTES_EDITABLE } from 'utils/constants';
import { onBlurSubmitValues, onChangeSubmitValue } from './helperFunctions';

interface ModuleProps {
    currentDetails: SBSMedia.Profile;
    localEventForm: SBSMedia.Event;
    localProfileForm: SBSMedia.Profile;
    setLocalProfileForm: Dispatch<SetStateAction<SBSMedia.Profile>>;
    setLocalEventForm: Dispatch<SetStateAction<SBSMedia.Event>>;
    destination: string;
    overrides?: any;
}

const EditProfileInline: React.FC<ModuleProps> = ({
    currentDetails,
    localEventForm,
    localProfileForm,
    setLocalProfileForm,
    setLocalEventForm,
    destination,
    overrides,
}) => {
    const [localProfileFormInline, setLocalProfileFormInline] = useState<SBSMedia.Profile>(
        {} as SBSMedia.Profile
    );

    // Current Details : Actual Profile with Default Settings
    // LocalProfileForm : undefined - ignored when adding event otherwise set as the current details
    // LocalEventForm : Event Form  - ignored when on Settings Page, otherwise is the full event
    // localProfileFormInline : Component Based form of local attributes of profile

    useEffect(() => {
        // Only run on Settings Page
        if (currentDetails && setLocalProfileForm) {
            setLocalProfileForm(currentDetails);
            setLocalProfileFormInline(currentDetails);
        }
    }, []);

    return (
        <Grid
            gridDefinition={[
                { colspan: 4 },
                { colspan: 4 },
                { colspan: 4 },
                { colspan: 4 },
                { colspan: 4 },
                { colspan: 4 },
                { colspan: 4 },
                { colspan: 4 },
                { colspan: 4 },
            ]}>
            {PROFILE_ATTRIBUTES_EDITABLE.filter((e) => {
                if (destination) {
                    return e.override == true;
                } else {
                    return true;
                }
            }).map((e) => {
                return (
                    <FormField
                        label={toProperCase(e.name)}
                        description={
                            e.type == 'number' ? (
                                'in ' + e.unit + ' ' + (e.example ? e.example : '')
                            ) : (
                                <br />
                            )
                        }
                        constraintText={
                            currentDetails?.[e.attribute]
                                ? 'Default Value: ' + currentDetails[e.attribute]
                                : ''
                        }
                        key={e.name}>
                        {e && e.options ? (
                            <Autosuggest
                                enteredTextLabel={(value) => `Use ${value}`}
                                onChange={({ detail }) => {
                                    onChangeSubmitValue(
                                        localProfileForm,
                                        setLocalProfileForm,
                                        e,
                                        detail,
                                        setLocalProfileFormInline,
                                        localProfileFormInline
                                    );
                                }}
                                options={e.options.map((op) => {
                                    return { value: op };
                                })}
                                onBlur={() => {
                                    // Only run when editing/adding an event
                                    onBlurSubmitValues(
                                        localEventForm,
                                        setLocalEventForm,
                                        destination,
                                        localProfileFormInline
                                    );
                                }}
                                placeholder={
                                    currentDetails && currentDetails[e.attribute]
                                        ? currentDetails[e.attribute].toString()
                                        : ''
                                }
                                value={
                                    localProfileForm?.[e.attribute]?.toString() ||
                                    localProfileFormInline[e.attribute]?.toString() ||
                                    overrides?.[e.attribute] ||
                                    currentDetails?.[e.attribute]?.toString() ||
                                    ''
                                }
                            />
                        ) : (
                            <Input
                                onChange={({ detail }) => {
                                    onChangeSubmitValue(
                                        localProfileForm,
                                        setLocalProfileForm,
                                        e,
                                        detail,
                                        setLocalProfileFormInline,
                                        localProfileFormInline
                                    );
                                }}
                                onBlur={() => {
                                    onBlurSubmitValues(
                                        localEventForm,
                                        setLocalEventForm,
                                        destination,
                                        localProfileFormInline
                                    );
                                }}
                                inputMode={e.attribute === 'name' ? 'text' : 'numeric'}
                                type={e.attribute === 'name' ? 'text' : 'number'}
                                value={
                                    localProfileForm?.[e.attribute]?.toString() ||
                                    localProfileFormInline?.[e.attribute]?.toString() ||
                                    overrides?.[e.attribute] ||
                                    currentDetails?.[e.attribute]?.toString() ||
                                    ''
                                }
                            />
                        )}
                    </FormField>
                );
            })}
        </Grid>
    );
};

export default EditProfileInline;
