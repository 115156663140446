// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
import { FormField, Input, Select, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { setErrorText } from '../helperFunctions';

import { sanitizeInput } from 'utils/common';
import { CustomSubFormProps } from '../AddEvent';
import { SBSMedia } from '_types';

export const MetadataForm: React.FC<CustomSubFormProps> = ({
    didSubmit,
    localForm,
    setLocalForm,
}) => {
    return (
        <SpaceBetween direction="vertical" size="m">
            <FormField
                label={'Event Name'}
                description="Required"
                errorText={setErrorText(didSubmit, localForm, { attribute: 'eventName' })}>
                <Input
                    onChange={({ detail }) =>
                        setLocalForm({
                            ...localForm,
                            ['eventName']: sanitizeInput(detail.value),
                        })
                    }
                    value={localForm['eventName']}
                />
            </FormField>
            <FormField label="Description">
                <Input
                    onChange={({ detail }) =>
                        setLocalForm({
                            ...localForm,
                            ['description']: sanitizeInput(detail.value),
                        })
                    }
                    value={localForm['description']}
                />
            </FormField>
            <FormField
                label="Task Number"
                errorText={
                    localForm['taskNumber'] &&
                    !localForm['taskNumber'].startsWith('T') &&
                    'Please prepend the task with a T'
                }>
                <span style={{ 'alignItems': 'center' }}>
                    <SpaceBetween direction="horizontal" size="xs" alignItems="center">
                        <Input
                            onChange={({ detail }) =>
                                setLocalForm({
                                    ...localForm,
                                    ['taskNumber']: sanitizeInput(detail.value),
                                })
                            }
                            value={localForm['taskNumber'] || ''}
                            placeholder="Ex: T123456789"
                        />
                    </SpaceBetween>
                </span>
            </FormField>
            <FormField
                label="Input Type"
                description="Required"
                errorText={setErrorText(didSubmit, localForm, { attribute: 'inputType' })}>
                <Select
                    onChange={({ detail }) =>
                        setLocalForm({
                            ...localForm,
                            inputType: detail.selectedOption.value,
                        })
                    }
                    selectedOption={{
                        value: localForm.inputType,
                        label: localForm.inputType,
                    }}
                    options={[
                        {
                            value: SBSMedia.InputTypes.ELEMENTAL_LINK,
                            label: SBSMedia.InputTypesLabel.ELEMENTAL_LINK,
                        },
                        // {
                        //     value: SBSMedia.InputTypes.ELEMENTAL_LIVE,
                        //     label: SBSMedia.InputTypesLabel.ELEMENTAL_LIVE,
                        //     disabled: true,
                        // },
                        { value: SBSMedia.InputTypes.FILE, label: SBSMedia.InputTypesLabel.FILE },
                        {
                            value: SBSMedia.InputTypes.RTMP,
                            label: SBSMedia.InputTypesLabel.RTMP,
                        },
                        {
                            value: SBSMedia.InputTypes.SRT_INGEST,
                            label: SBSMedia.InputTypesLabel.SRT_INGEST,
                        },
                    ]}
                />
            </FormField>
        </SpaceBetween>
    );
};
