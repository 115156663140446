// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { SBSMedia } from '_types';
import { getEncoderMetric } from './MetricsManagementAPI';

export interface MetricsManagementState {
    key: string;
    status: string;
    encoderMetrics: SBSMedia.Metric[];
}

const initialState: MetricsManagementState = {
    key: 'replaceMe',
    status: 'idle',
    encoderMetrics: [],
};

/*===============================
             Thunks
===============================*/

export const getEncoderMetricData = createAsyncThunk(
    'MetricsManagement/encoderMetric',
    async (params: SBSMedia.EncoderMetricProps) => {
        const response = await getEncoderMetric(params);
        return { response, params };
    }
);

/*===============================
              Slice
===============================*/

export const MetricsManagementSlice = createSlice({
    name: 'MetricsManagement',
    initialState,
    reducers: {
        updateState: (state, action: PayloadAction<string>) => {
            state.key = action.payload;
        },
        setNewMetrics: (state, action: PayloadAction<[]>) => {
            state.encoderMetrics = action.payload as any;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEncoderMetricData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEncoderMetricData.fulfilled, (state, action) => {
                state.status = 'idle';
                const currentMetrics = [...state.encoderMetrics];
                // console.log(action.payload.response);
                const newEncoderData: SBSMedia.EncoderData = {
                    EncoderId: action.payload.response.EncoderId,
                    datapoints: action.payload.response.Datapoints.map((datapoint) => {
                        return { x: new Date(datapoint.Timestamp * 1000), y: datapoint.Average };
                    }).sort((a, b) => a.x - b.x),
                };
                // console.log(newEncoderData);
                const currentMetricName = action.payload.response.Label;
                let existingMetric = null;
                let existingIndex = null;
                currentMetrics.forEach((metric, index) => {
                    if (metric.metricName === currentMetricName) {
                        existingMetric = metric;
                        existingIndex = index;
                    }
                });
                if (existingMetric !== null) {
                    existingMetric.encoders = [...existingMetric.encoders, newEncoderData];
                    currentMetrics[existingIndex] = existingMetric;
                } else {
                    const newMetric: SBSMedia.Metric = {
                        metricName: currentMetricName,
                        encoders: [newEncoderData],
                    };
                    currentMetrics.push(newMetric);
                }
                // currentMetrics.push(action.payload.response);
                // console.log(currentMetrics);
                state.encoderMetrics = currentMetrics;
            })
            .addCase(getEncoderMetricData.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

/*===============================
            Actions
===============================*/

export const { updateState, setNewMetrics } = MetricsManagementSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectMetrics = (state: RootState) => state.MetricsManagement.encoderMetrics;

export default MetricsManagementSlice.reducer;
