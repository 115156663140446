// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { Dispatch, SetStateAction } from 'react';
import { Button, ButtonDropdown, Header, Icon, SpaceBetween } from '@cloudscape-design/components';
import { useAppDispatch } from 'app/hooks';
import {
    getEventsAsync,
    stopEventAsync,
    startEventAsync,
    changeEventSlateAsync,
} from 'features/EventManagement/EventManagementSlice';
import { isStartStopDisabled, areInteractionsDisabled } from 'utils/common';
import { SBSMedia } from '_types';
import { SLATE_OPTIONS } from './TableCommon';

export const CUSTOM_HEADER = (
    events: SBSMedia.Event[],
    setItemDelete: Dispatch<SetStateAction<SBSMedia.Event>>,
    setDeleteVisible: Dispatch<SetStateAction<boolean>>,
    setViewType: Dispatch<SetStateAction<string>>,
    viewType: string,
    tableSelectedEvent: readonly SBSMedia.Event[],
    setTableSelectedEvent: Dispatch<SetStateAction<SBSMedia.Event[]>>,
    setCurrentDetails: Dispatch<SetStateAction<SBSMedia.Event>>,
    setModalVisible: Dispatch<SetStateAction<boolean>>,
    systemPendingEvents: string[],
    setSystemPendingEvents: Dispatch<SetStateAction<string[]>>,
    setTransitionMetadata: Dispatch<SetStateAction<{visible: boolean, slateName: string, event: SBSMedia.Event}>>,
) => {
    const dispatch = useAppDispatch();

    return (
        <Header
            variant="awsui-h1-sticky"
            counter={`(${events.length})`}
            actions={
                <SpaceBetween size="xs" direction="horizontal">
                    <ButtonDropdown
                        onItemClick={(event) => {
                            setViewType(event.detail.id);
                        }}
                        items={[
                            {
                                text: 'Card view',
                                id: 'Card view',
                                disabled: viewType === 'Card view',
                            },
                            {
                                text: 'Table view',
                                id: 'Table view',
                                disabled: viewType === 'Table view',
                            },
                        ]}>
                        {viewType}
                    </ButtonDropdown>

                    {viewType === 'Table view' ? (
                        <>
                            <ButtonDropdown
                                disabled={
                                    tableSelectedEvent === undefined ||
                                    tableSelectedEvent.length < 1 ||
                                    areInteractionsDisabled(tableSelectedEvent[0].eventState) ||
                                    systemPendingEvents.includes(tableSelectedEvent[0].EventId)
                                }
                                items={[
                                    { text: 'Edit', id: 'edit' },
                                    { text: 'Delete', id: 'delete' },
                                    { text: 'Clone', id: 'clone' },
                                ]}
                                onItemClick={({ detail }) => {
                                    switch (detail.id) {
                                        case 'edit':
                                            setCurrentDetails(tableSelectedEvent[0]);
                                            setModalVisible(true);
                                            setTableSelectedEvent([]);
                                            break;
                                        case 'delete':
                                            if (
                                                tableSelectedEvent != undefined &&
                                                tableSelectedEvent.length > 0
                                            ) {
                                                setItemDelete(tableSelectedEvent[0]);
                                                setDeleteVisible(true);
                                                setTableSelectedEvent([]);
                                            }
                                            break;
                                        case 'clone':
                                            if (
                                                tableSelectedEvent != undefined &&
                                                tableSelectedEvent.length > 0
                                            ) {
                                                setCurrentDetails({
                                                    ...tableSelectedEvent[0],
                                                    eventName:
                                                        tableSelectedEvent[0].eventName +
                                                        ' Duplicate',
                                                    destinations: {},
                                                    startTime: 0,
                                                    endTime: 0,
                                                    EventId: undefined,
                                                });
                                                setModalVisible(true);
                                                setTableSelectedEvent([]);
                                            }
                                            break;
                                    }
                                }}>
                                Actions
                            </ButtonDropdown>

                            {tableSelectedEvent != undefined &&
                            tableSelectedEvent.length > 0 &&
                            tableSelectedEvent[0].eventState === 'LIVE' ? (
                                <div style={{ filter: 'hue-rotate(150deg)' }}>
                                    <Button
                                        fullWidth
                                        disabled={
                                            isStartStopDisabled(tableSelectedEvent[0].eventState) ||
                                            systemPendingEvents.includes(
                                                tableSelectedEvent[0].EventId
                                            )
                                        }
                                        onClick={() => {
                                            setSystemPendingEvents([
                                                ...systemPendingEvents,
                                                tableSelectedEvent[0].EventId,
                                            ]);
                                            dispatch(
                                                stopEventAsync({
                                                    'EventId': tableSelectedEvent[0].EventId,
                                                })
                                            ).then(() => {
                                                dispatch(getEventsAsync()).then(() => {
                                                    setSystemPendingEvents([
                                                        ...systemPendingEvents.filter(
                                                            (id) =>
                                                                id !== tableSelectedEvent[0].EventId
                                                        ),
                                                    ]);
                                                });
                                            });
                                        }}
                                        iconSvg={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 16 16"
                                                focusable="false"
                                                key="0">
                                                <g>
                                                    <rect x="2" y="2" width="12" height="12" />
                                                </g>
                                            </svg>
                                        }>
                                        {'End Stream'}
                                    </Button>
                                </div>
                            ) : tableSelectedEvent != undefined &&
                              tableSelectedEvent.length > 0 &&
                              tableSelectedEvent[0].destinations ? (
                                <Button
                                    fullWidth
                                    disabled={
                                        isStartStopDisabled(tableSelectedEvent[0].eventState) ||
                                        systemPendingEvents.includes(tableSelectedEvent[0].EventId)
                                    }
                                    onClick={() => {
                                        setSystemPendingEvents([
                                            ...systemPendingEvents,
                                            tableSelectedEvent[0].EventId,
                                        ]);
                                        dispatch(
                                            startEventAsync({
                                                'EventId': tableSelectedEvent[0].EventId,
                                            })
                                        ).then(() => {
                                            dispatch(getEventsAsync());
                                        });
                                    }}
                                    iconSvg={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            focusable="false"
                                            key="0">
                                            <g>
                                                <polygon points="4,1 4,14 15,8 " />
                                            </g>
                                        </svg>
                                    }>
                                    {'Start Stream'}
                                </Button>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                    {tableSelectedEvent?.[0] &&
                        tableSelectedEvent[0].eventState == 'LIVE' &&
                        tableSelectedEvent[0].slateBoolean && (
                            <ButtonDropdown
                                items={SLATE_OPTIONS(tableSelectedEvent[0])}
                                onItemClick={(item) => {
                                    setTransitionMetadata({visible: true, slateName: item.detail.id, event: tableSelectedEvent?.[0]})
                                }}>
                                Live Feed Input
                            </ButtonDropdown>
                        )}
                    <Button
                        data-testid="header-btn-create"
                        variant="primary"
                        onClick={() => {
                            setModalVisible(true);
                            setCurrentDetails({} as SBSMedia.Event);
                        }}>
                        <Icon name="add-plus" /> Create event
                    </Button>
                </SpaceBetween>
            }>
            {'Events'}
        </Header>
    );
};
