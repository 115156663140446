// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { CSSProperties, useState } from 'react';
import { Auth } from 'aws-amplify';

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await Auth.signIn(username, password);
            // Upon successful sign in, redirect the user to the main page
            window.location.replace('/dashboard');
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An error occurred during login.');
            }
        }
    };

    const formStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        margin: '0 auto',
    };

    const inputStyle: CSSProperties = {
        margin: '10px 0',
        padding: '10px',
        fontSize: '16px',
    };

    const buttonStyle: CSSProperties = {
        padding: '10px',
        margin: '10px 0',
        fontSize: '16px',
        cursor: 'pointer',
    };

    return (
        <div>
            <form onSubmit={handleSubmit} style={formStyle}>
                <h2>Login</h2>
                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    aria-label="Username"
                    tabIndex={0}
                    style={inputStyle}
                />
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    aria-label="Password"
                    tabIndex={0}
                    style={inputStyle}
                />
                <button type="submit" tabIndex={0} style={buttonStyle}>
                    Login
                </button>
            </form>
            {error && <p>{error}</p>}
        </div>
    );
};

export default LoginPage;
