// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { PropertyFilterProps } from '@cloudscape-design/components';

export const ENCODER_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
    {
        propertyLabel: 'Name',
        key: 'name',
        groupValuesLabel: 'Name values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Region',
        key: 'region',
        groupValuesLabel: 'Region method values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Connection State',
        key: 'ConnectionState',
        groupValuesLabel: 'Connection State values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Encoder Type',
        key: 'enc_type',
        groupValuesLabel: 'Encoder Type values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'ARN',
        key: 'arn',
        groupValuesLabel: 'ARN values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'IP',
        key: 'ip',
        groupValuesLabel: 'IP values',
        operators: [':', '!:', '=', '!='],
    },
];
