// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { putProfile, getVersion, deleteProfile } from './SettingsManagementAPI';
import { SBSMedia } from '_types';

export interface SettingsManagementState {
    status: string;
    apiVersion: string;
    s3BucketOutput: string;
    s3BucketInput: string;
    s3BucketOpening: string;
    s3BucketIntermission: string;
    s3BucketClosing: string;
}

const initialState: SettingsManagementState = {
    status: 'idle',
    s3BucketOutput: 's3ssl://amazonaws.com/[output_bucket_name]/',
    s3BucketInput: 's3ssl://amazonaws.com/[input_bucket_name]/',
    s3BucketOpening: 's3ssl://amazonaws.com/[opening_slate_bucket_name].mp4',
    s3BucketIntermission: 's3ssl://amazonaws.com/[intermission_slate_bucket_name].mp4',
    s3BucketClosing: 's3ssl://amazonaws.com/[closing_slate_bucket_name].mp4',
    apiVersion: '',
};

/*===============================
             Thunks
===============================*/

export const postProfileAsync = createAsyncThunk(
    'SettingsManagement/putProfile',
    async (params: SBSMedia.Profile): Promise<object> => {
        const response = await putProfile(params);
        return { response, params };
    }
);

export const deleteProfilesAsync = createAsyncThunk(
    'SettingsManagement/deleteProfile',
    async (profileId: string): Promise<object> => {
        const response = await deleteProfile(profileId);
        return { response, profileId };
    }
);

export const getVersionAsync = createAsyncThunk(
    'SettingsManagement/getVersion',
    async (): Promise<string> => {
        const response = await getVersion();
        return response;
    }
);

/*===============================
              Slice
===============================*/

export const SettingsManagementSlice = createSlice({
    name: 'SettingsManagement',
    initialState,
    reducers: {
        updateS3Bucket: (state, action: PayloadAction<{ string; type }>) => {
            switch (action.payload?.type) {
                case 'Output':
                    state.s3BucketOutput = action.payload.string;
                    break;
                case 'Input':
                    state.s3BucketInput = action.payload.string;
                    break;
                case 'Opening':
                    state.s3BucketOpening = action.payload.string;
                    break;
                case 'Closing':
                    state.s3BucketClosing = action.payload.string;
                    break;
                case 'Intermission':
                    state.s3BucketIntermission = action.payload.string;
                    break;
                default:
                    break;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getVersionAsync.fulfilled, (state, action) => {
            state.apiVersion = action.payload;
        });
    },
});

/*===============================
            Actions
===============================*/

export const { updateS3Bucket } = SettingsManagementSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectS3Output = (state: RootState) => state.SettingsManagement.s3BucketOutput;
export const selectS3Input = (state: RootState) => state.SettingsManagement.s3BucketInput;
export const selectS3Opening = (state: RootState) => state.SettingsManagement.s3BucketOpening;
export const selectS3Intermission = (state: RootState) =>
    state.SettingsManagement.s3BucketIntermission;
export const selectS3Closing = (state: RootState) => state.SettingsManagement.s3BucketClosing;
export const selectapiVersion = (state: RootState) => state.SettingsManagement.apiVersion;

export default SettingsManagementSlice.reducer;
