// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { SBSMedia } from '_types';
import { API } from 'aws-amplify';

export async function fetchEventData() {
    const path = 'events';
    const config = {
        headers: {},
        response: false,
    };

    return API.get('SBS', path, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function postEvent(localForm: SBSMedia.EventProps) {
    delete localForm.destinationInformation;
    const path = 'events';
    const config = {
        headers: {},
        body: {
            ...localForm,
        },
        response: false,
    };

    return API.post('SBS', path, config)
        .then((res) => {
            console.log('events post response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function editEvent(localForm: SBSMedia.EventProps) {
    delete localForm.destinationInformation;
    const path = 'events/' + localForm.EventId;
    const config = {
        headers: {},
        body: {
            ...localForm,
        },
        response: false,
    };

    return API.put('SBS', path, config)
        .then((res) => {
            console.log('events put response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function deleteEvent(localForm: SBSMedia.EventDeletionProps) {
    console.log(localForm);
    const path = 'events/' + localForm.EventId;
    const config = {
        headers: {},
        data: { 'reasonForDeletion': localForm.reasonForDeletion },
        response: false,
    };

    return API.del('SBS', path, config)
        .then((res) => {
            console.log('events del response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function createEvent(localForm: any) {
    const path = 'events/' + localForm.EventId + '/create';
    const config = {
        headers: {},
        body: {},
        response: false,
    };

    return API.put('SBS', path, config)
        .then((res) => {
            console.log('events put response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function startEvent(localForm: any) {
    const path = 'events/' + localForm.EventId + '/start';
    const config = {
        headers: {},
        body: {},
        response: false,
    };

    return API.put('SBS', path, config)
        .then((res) => {
            console.log('events put response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function stopEvent(localForm: any) {
    const path = 'events/' + localForm.EventId + '/stop';
    const config = {
        headers: {},
        body: {},
        response: false,
    };

    return API.put('SBS', path, config)
        .then((res) => {
            console.log('events put response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function slateSelection(localForm: any) {
    const path = 'events/' + localForm.EventId + '/input/' + localForm.slateName;
    const config = {
        headers: {},
        body: {},
        response: false,
    };

    return API.put('SBS', path, config)
        .then((res) => {
            console.log('slate put response: ', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

export async function slateNotification(id: string) {
    const path = 'events/' + id + '/clear/' ;
    const config = {
        headers: {},
        body: {},
        response: false,
    };

    return API.put('SBS', path, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject();
        });
}

