// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
import { FormField, Input, SpaceBetween, Toggle } from '@cloudscape-design/components';
import React from 'react';
import { setErrorText } from '../../helperFunctions';

import { sanitizeInput } from 'utils/common';
import { CustomSubFormProps } from '../../AddEvent';

export const RtmpIngestForm: React.FC<CustomSubFormProps> = ({
    didSubmit,
    localForm,
    setLocalForm,
}) => {
    return (
        <SpaceBetween direction="vertical" size="m">
            <FormField
                label="Allowlist CIDR"
                description="Required"
                errorText={setErrorText(didSubmit, localForm, {
                    attribute: 'rtmpCIDR',
                })}>
                <Input
                    placeholder="0.0.0.0/0"
                    onChange={({ detail }) => {
                        setLocalForm({
                            ...localForm,
                            rtmpCIDR: sanitizeInput(detail.value),
                        });
                    }}
                    value={localForm.rtmpCIDR}
                />
            </FormField>
            <FormField
                label="Streamkey"
                description="Required"
                errorText={setErrorText(didSubmit, localForm, {
                    attribute: 'rtmpStreamkey',
                })}>
                <Input
                    placeholder="abcd-1234"
                    onChange={({ detail }) => {
                        setLocalForm({
                            ...localForm,
                            rtmpStreamkey: sanitizeInput(detail.value),
                        });
                    }}
                    value={localForm.rtmpStreamkey}
                />
            </FormField>
            <FormField label="Backup Enabled">
                <Toggle
                    onChange={({ detail }) =>
                        setLocalForm({ ...localForm, rtmpBackup: detail.checked })
                    }
                    checked={localForm.rtmpBackup}
                />
            </FormField>
        </SpaceBetween>
    );
};
