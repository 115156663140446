// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { PropertyFilterProps } from '@cloudscape-design/components';

export const EVENT_FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
    {
        propertyLabel: 'Name',
        key: 'eventName',
        groupValuesLabel: 'Name values',
        operators: [':', '!:', '=', '!='],
    },
    // {
    //     propertyLabel: 'Start Datetime',
    //     key: 'readableStartTime',
    //     groupValuesLabel: 'Date method values',
    //     operators: [':', '!:', '=', '!='],
    // },
    {
        propertyLabel: 'Creator',
        key: 'creator',
        groupValuesLabel: 'Creator values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Created At',
        key: 'readableCreatedTime',
        groupValuesLabel: 'Created at values',
        operators: [':', '!:', '=', '!=', '<=', '>='],
    },
    {
        propertyLabel: 'Destinations',
        key: 'destinationList',
        groupValuesLabel: 'Destination list values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Primary Encoder',
        key: 'primaryEncoderName',
        groupValuesLabel: 'Primary values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Backup Encoder',
        key: 'backupEncoderName',
        groupValuesLabel: 'Backup values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Primary Encoder Status',
        key: 'primaryEncoderStatus',
        groupValuesLabel: 'Primary values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Backup Encoder Status',
        key: 'backupEncoderStatus',
        groupValuesLabel: 'Backup values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Status',
        key: 'eventState',
        groupValuesLabel: 'Event status values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Input Type',
        key: 'inputType',
        groupValuesLabel: 'Input type values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Task ID',
        key: 'taskNumber',
        groupValuesLabel: 'Task ID type values',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Event ID',
        key: 'EventId',
        groupValuesLabel: 'Event ID type values',
        operators: [':', '!:', '=', '!='],
    },
];
