// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

export const DESTINATION_PROPERTIES = {
    Facebook: {
        name: 'facebook',
        properties: {
            primaryStreamKey: 'Primary Stream Key',
            backupStreamKey: 'Backup Stream Key',
            primaryUrl: 'Server URL',
            profile: 'Profile',
        },
    },
    YouTube: {
        name: 'youtube',
        properties: {
            primaryStreamKey: 'Stream Key',
            primaryUrl: 'Primary Server URL',
            backupUrl: 'Backup Server URL',
            profile: 'Profile',
        },
    },
    Workplace: {
        name: 'workplace',
        properties: {
            primaryStreamKey: 'Primary Stream Key',
            backupStreamKey: 'Backup Stream Key',
            primaryUrl: 'Server URL',
            profile: 'Profile',
        },
    },
    Twitch: {
        name: 'twitch',
        properties: {
            primaryStreamKey: 'Stream Key',
            primaryUrl: 'Server URL',
            profile: 'Profile',
        },
    },
    Instagram: {
        name: 'instagram',
        properties: {
            primaryStreamKey: 'Stream Key',
            primaryUrl: 'Server URL',
            profile: 'Profile',
        },
    },
    OutputSRT: {
        name: 'outputSrt',
        properties: {
            srtMode: 'SRT Mode',
            primaryPort: 'Primary SRT Port',
            latency: 'Latency (ms)',
            primaryAddress: 'Primary SRT Address',
            primaryAllowlist: 'Primary Allowlist CIDR',
            passphrase: 'Passphrase',
            profile: 'Profile',
            backupAllowList: 'Backup Allowlist CIDR',
            backupIp: 'Backup IP',
            backupPort: 'Backup SRT Port',
            backupPassphrase: 'Backup Passphrase',
        },
    },
    CustomRTMP: {
        name: 'customRTMP',
        properties: {
            profile: 'Profile',
            primaryStreamKey: 'Primary Stream Key',
            backupStreamKey: 'Backup Stream Key',
            primaryUrl: 'Primary Server URL',
            backupUrl: 'Backup Server URL',
        },
    },
    CloudFront: {
        name: 'cloudFront',
        properties: {
            profiles: 'Profile',
        },
    },
    LinkedIn: {
        name: 'linkedin',
        properties: {
            primaryStreamKey: 'Stream Key',
            primaryUrl: 'Stream URL',
            profile: 'Profile',
        },
    },
    Record: {
        name: 'record',
        properties: {
            profile: 'Profile',
            primaryUrl: 'URL',
        },
        default: {
            primaryUrl: '',
            profile: '720p',
        },
    },
};

export const OUTPUT_SRT_CASE = {
    OutputSRT: {
        name: 'outputSrt',
        properties: {
            srtMode: 'SRT Mode',
            primaryPort: 'Primary SRT Port',
            latency: 'Latency (ms)',
            primaryAddress: 'Primary SRT Address',
            primaryAllowlist: 'Primary Allowlist CIDR',
            passphrase: 'Passphrase',
            profile: 'Profile',
            backupAllowList: 'Backup Allowlist CIDR',
            backupIp: 'Backup IP',
            backupPort: 'Backup SRT Port',
            backupPassphrase: 'Backup Passphrase',
        },
    },
};

export const ElementalLinkProperties = ['primaryEncoder', 'backupEncoder'];
export const SRTIngestDefaultProperties = [
    'srtRegion',
    'srtMode',
    'latency',
    'passphrase',
    'primarySRTPort',
];
export const SRTIngestCallerProperties = ['primaryIP'];
export const SRTIngestListenerProperties = ['PrimaryAllowlistCIDR'];
export const SRTIngestBackupProperties = [
    'backupIP',
    'BackupAllowlistsCIDR',
    'backupSRTPort',
    'passphraseBackup',
];
export const SRTIngestTotalProperties = [
    ...SRTIngestBackupProperties,
    ...SRTIngestCallerProperties,
    ...SRTIngestDefaultProperties,
    ...SRTIngestListenerProperties,
];

export const FileProperties = ['fileInput'];
export const RTMPProperties = ['rtmpCIDR', 'rtmpBackup', 'rtmpStreamkey'];
