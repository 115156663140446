// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
import { FormField, Select, SelectProps, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { setErrorText } from '../../helperFunctions';

import { CustomSubFormProps } from '../../AddEvent';
import { SBSMedia } from '_types';

export const ElementalLinkForm: React.FC<CustomSubFormProps> = ({
    didSubmit,
    localForm,
    setLocalForm,
    encoders,
}) => {
    return (
        <SpaceBetween direction="vertical" size="m">
            <FormField
                label="Primary Encoder"
                description="Required"
                errorText={
                    localForm.inputType == SBSMedia.InputTypes.ELEMENTAL_LINK &&
                    setErrorText(didSubmit, localForm, {
                        attribute: 'primaryEncoder',
                    })
                }>
                <Select
                    selectedOption={{
                        label: localForm.primaryEncoder
                            ? encoders.filter((e) => {
                                  return e.EncoderId == localForm.primaryEncoder;
                              })[0]?.name
                            : '',
                        value: localForm.primaryEncoder
                            ? encoders.filter((e) => {
                                  return e.EncoderId == localForm.primaryEncoder;
                              })[0]?.EncoderId
                            : '',
                    }}
                    onChange={({ detail }) =>
                        setLocalForm({
                            ...localForm,
                            ['primaryEncoder']: detail.selectedOption.value || '',
                        })
                    }
                    options={
                        encoders
                            .filter((e) => {
                                return e.enc_type != 'live';
                            })
                            .map((encoder) => {
                                return {
                                    label: encoder.name,
                                    value: encoder.EncoderId,
                                    tags: [encoder.region, encoder.ConnectionState],
                                    disabled: localForm.backupEncoder == encoder.EncoderId,
                                } as SelectProps.Option;
                            }) as SelectProps.Options
                    }
                />
            </FormField>
            <FormField label="Backup Encoder">
                <Select
                    selectedOption={{
                        label: localForm.backupEncoder
                            ? encoders.filter((e) => {
                                  return e.EncoderId == localForm.backupEncoder;
                              })[0]?.name
                            : '',
                        value: localForm.backupEncoder
                            ? encoders.filter((e) => {
                                  return e.EncoderId == localForm.backupEncoder;
                              })[0]?.EncoderId
                            : '',
                    }}
                    onChange={({ detail }) => {
                        setLocalForm({
                            ...localForm,
                            ['backupEncoder']: detail.selectedOption.value || '',
                        });
                    }}
                    options={(
                        encoders
                            ?.filter((e) => {
                                return e.enc_type != 'live';
                            })
                            .map((encoder) => {
                                let primaryRegion;
                                if (localForm.primaryEncoder) {
                                    encoders.forEach((encoder) => {
                                        if (encoder.EncoderId === localForm.primaryEncoder) {
                                            primaryRegion = encoder.region;
                                        }
                                    });
                                }
                                return {
                                    label: encoder.name,
                                    value: encoder.EncoderId,
                                    tags: [encoder.region, encoder.ConnectionState],
                                    disabled:
                                        localForm.primaryEncoder == encoder.EncoderId ||
                                        encoder.enc_type == 'live' ||
                                        (primaryRegion !== undefined &&
                                            encoder.region !== primaryRegion),
                                } as SelectProps.Option;
                            }) as SelectProps.Options
                    ).concat({
                        label: 'None',
                        value: 'None',
                    })}
                />
            </FormField>
        </SpaceBetween>
    );
};
