// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import EventManagementSlice from 'features/EventManagement/EventManagementSlice';
import EncoderManagementSlice from 'features/EncoderManagement/EncoderManagementSlice';

import MetricsManagementSlice from 'features/MetricsManagement/MetricsManagementSlice';
import SettingsManagementSlice from 'features/SettingsManagement/SettingsManagementSlice';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['encoders', 'events', 'EncoderManagement', 'EventManagement', 'MetricsManagement'],
    timeout: 2000,
};

const reducers = combineReducers({
    EventManagement: EventManagementSlice,
    EncoderManagement: EncoderManagementSlice,
    MetricsManagement: MetricsManagementSlice,
    SettingsManagement: SettingsManagementSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
