// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React, { useState } from 'react';

import './profileCards.scss';
import { Alert, Box, Button, Cards } from '@cloudscape-design/components';
import { useAppSelector } from 'app/hooks';
import { selectProfiles } from 'features/EncoderManagement/EncoderManagementSlice';
import EditProfile from '../EditProfile/EditProfile';

import { SBSMedia } from '_types';
import { PROFILE_ATTRIBUTES_EDITABLE } from 'utils/constants';
import DeleteProfile from '../DeleteProfile';
import { Snackbar } from '@mui/material';

const ProfileCards: React.FC = () => {
    const profiles = useAppSelector(selectProfiles);
    const [currentDetails, setCurrentDetails] = useState<SBSMedia.Profile>();
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string | null>(null);
    const [isNew, setIsNew] = useState<boolean>(false);
    return (
        <>
            <Cards
                ariaLabels={{
                    itemSelectionLabel: (e, n) => `select ${n.name}`,
                    selectionGroupLabel: 'Item selection',
                }}
                cardDefinition={{
                    header: (item) => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'nowrap',
                            }}
                            lang="en">
                            <span
                                style={{
                                    hyphens: 'auto',
                                    width: '63%',
                                }}>
                                {item.name}
                            </span>
                            <div>
                                <Button
                                    iconName="edit"
                                    variant="icon"
                                    onClick={() => {
                                        setCurrentDetails(item);

                                        setVisible(true);
                                        setIsNew(false);
                                    }}
                                />
                                <Button
                                    iconName="remove"
                                    variant="icon"
                                    onClick={() => {
                                        setCurrentDetails(item);
                                        setVisibleDelete(true);
                                    }}
                                />
                                <Button
                                    iconName="copy"
                                    variant="icon"
                                    onClick={() => {
                                        setCurrentDetails(item);

                                        setVisible(true);
                                        setIsNew(true);
                                    }}
                                />
                            </div>
                        </div>
                    ),
                    sections: PROFILE_ATTRIBUTES_EDITABLE.map((item) => {
                        return (
                            item.attribute !== 'name' && {
                                id: item.attribute,
                                header: item.name,
                                width: 33,
                                content: (e) => (e[item.attribute] || '') + ' ' + (item.unit || ''),
                            }
                        );
                    }) as [],
                }}
                cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
                items={profiles}
                loadingText="Loading resources"
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No profiles</b>
                        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                            No resources to display.
                        </Box>
                    </Box>
                }
            />
            <EditProfile
                setVisible={setVisible}
                visible={visible}
                isNew={isNew}
                currentDetails={isNew ? { ...currentDetails, name: '' } : { ...currentDetails }}
            />
            {visibleDelete && (
                <DeleteProfile
                    setVisible={setVisibleDelete}
                    visible={visibleDelete}
                    currentDetails={currentDetails}
                    setAlertText={setAlertText}
                />
            )}
            <Snackbar
                open={alertText !== null}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={() => {
                    setAlertText(null);
                }}>
                <div>
                    <Alert
                        statusIconAriaLabel="Info"
                        type={
                            alertText === 'Something went wrong deleting your profile.'
                                ? 'error'
                                : 'success'
                        }>
                        {alertText}
                    </Alert>
                </div>
            </Snackbar>
        </>
    );
};

export default ProfileCards;
