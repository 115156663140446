// © 2023 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

// This AWS Content is provided subject to the terms of the AWS Customer Agreement
// available at http://aws.amazon.com/agreement or other written agreement between
// Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.

import React from 'react';
import { ColumnLayout, Popover } from '@cloudscape-design/components';
import { DESTINATION_PROPERTIES, OUTPUT_SRT_CASE } from '../AddEvent/AddEventConstants';
import { switchIcon } from 'utils/common';
import { SBSMedia } from '_types';

interface DestinationPopoverProps {
    e: SBSMedia.Event;
}

const DestinationPopover: React.FC<DestinationPopoverProps> = ({ e }) => {
    return (
        <>
            {e.destinations &&
                Object.keys(e.destinations).map((p) => {
                    if (p != 'Record' && p != 'OutputSRT') {
                        return (
                            <Popover
                                key={p}
                                dismissButton={false}
                                position="top"
                                size="large"
                                triggerType="custom"
                                content={
                                    DESTINATION_PROPERTIES[p] !== undefined ? (
                                        <ColumnLayout columns={1} borders="horizontal">
                                            {Object.keys(DESTINATION_PROPERTIES[p].properties).map(
                                                (attribute) => {
                                                    if (
                                                        e.destinations[p]?.[attribute] !=
                                                            undefined &&
                                                        e.destinations[p]?.[attribute] != '' &&
                                                        attribute != 'profiles'
                                                    ) {
                                                        return (
                                                            <div key={attribute}>
                                                                <div
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                    }}>
                                                                    {
                                                                        DESTINATION_PROPERTIES[p]
                                                                            .properties[attribute]
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {attribute == 'profile'
                                                                        ? e.destinations[p][
                                                                              attribute
                                                                          ].label
                                                                        : e.destinations[p][
                                                                              attribute
                                                                          ].toString()}
                                                                </div>
                                                            </div>
                                                        );
                                                    } else if (attribute === 'profiles') {
                                                        return (
                                                            <div key={attribute}>
                                                                <div
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                    }}>
                                                                    Profiles
                                                                </div>
                                                                <div>
                                                                    <ul>
                                                                        {e.destinations[p][
                                                                            attribute
                                                                        ].map((e) => {
                                                                            return (
                                                                                <li key={e.value}>
                                                                                    {e.label}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                }
                                            )}
                                        </ColumnLayout>
                                    ) : null
                                }>
                                {switchIcon(p, false)}
                            </Popover>
                        );
                    } else if (p === 'OutputSRT') {
                        return (
                            <Popover
                                key={p}
                                dismissButton={false}
                                position="top"
                                size="large"
                                triggerType="custom"
                                content={
                                    OUTPUT_SRT_CASE[p] !== undefined ? (
                                        <ColumnLayout columns={1} borders="horizontal">
                                            {Object.keys(OUTPUT_SRT_CASE[p].properties).map(
                                                (attribute) => {
                                                    if (
                                                        e.destinations[p]?.[attribute] !=
                                                            undefined &&
                                                        e.destinations[p]?.[attribute] != '' &&
                                                        attribute != 'profiles'
                                                    ) {
                                                        return (
                                                            <div key={attribute}>
                                                                <div
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                    }}>
                                                                    {
                                                                        OUTPUT_SRT_CASE[p]
                                                                            .properties[attribute]
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {attribute == 'profile'
                                                                        ? e.destinations[p][
                                                                              attribute
                                                                          ]['label']
                                                                        : e.destinations[p][
                                                                              attribute
                                                                          ].toString()}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                }
                                            )}
                                            {e.srtOutputData && (
                                                <>
                                                    {e.srtOutputData.primary && (
                                                        <div>
                                                            <div
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                SRT Primary Endpoint
                                                            </div>
                                                            <div>
                                                                {
                                                                    e.srtOutputData?.['primary'][
                                                                        'endpoint'
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    )}

                                                    {e.srtOutputData.backup && (
                                                        <div>
                                                            <div
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                SRT Backup Endpoint
                                                            </div>
                                                            <div>
                                                                {
                                                                    e.srtOutputData?.['backup'][
                                                                        'endpoint'
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </ColumnLayout>
                                    ) : null
                                }>
                                {switchIcon(p, false)}
                            </Popover>
                        );
                    }
                })}
        </>
    );
};

export default DestinationPopover;
